import React, { useState, useEffect} from "react"
import {
    Page,
    Layout,
    FooterHelp,
    DisplayText,
    Spinner,
    Card,
} from "@shopify/polaris"
import Axios from "axios"
import URLSearchParams from 'url-search-params';

const generateTraveler = async ({ setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/shopify-files/${id}`,
            method: 'POST'
        });
        if (data) {
            return setPageState({
                orderName: data.orderName,
                sentTo: data.sentTo,
                success: true,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            orderName: null,
            sentTo: null,
            success: false,
            loading: false,
            errors
        });
    }
};

const AdminLinks = (props) => {

    const [pageState, setPageState] = useState({
        orderName: null,
        sentTo: null,
        success: false,
        loading: true,
        errors: []
    });

    useEffect(() => {
        console.log(props.location.search);
        if (props.location.search) {
            const query = new URLSearchParams(props.location.search);
            (generateTraveler)({
                setPageState,
                id: query.get('id')
            });
        }
    },[
        props,
    ]);

    return (
        <Page
            title="Generate Traveler"
            breadcrumbs={[{
                content: 'Settings',
                url: `/app/settings`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Generate Traveler Document
                    </DisplayText>
                </Layout.Section>
                <Layout.Section>
                    <Card title="Document Details" sectioned>
                        {pageState.loading ? (
                            <Spinner accessibilityLabel="Spinner" size="large" color="teal" />
                        ) : (
                            <>
                                {pageState.orderName && pageState.sentTo ? (
                                    <>
                                        <p>The traveler document for order {pageState.orderName} is being generated. The order attributes will also be updated with the new dropbox URL.</p>
                                        <p>A copy will also be sent to {pageState.sentTo} shortly.</p>
                                    </>
                                ) : null}
                            </>
                        )}
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    )
};

export default AdminLinks;

