import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Card,
    Tag,
    FormLayout,
    Select,
    Button,
    Toast,
    ResourcePicker,
    Spinner,
    Banner,
    ResourceList,
    Avatar,
    TextStyle,
    Thumbnail,
    Frame,
} from "@shopify/polaris"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const Headline = styled.h3`
    width: 25%;
    display: inline-block;
    margin-right: 40px;
    vertical-align: middle;
`;

const VariantHeadline = styled.h3`
    font-weight: 600;
    margin-bottom: 10px;
`;

const Variants = styled.div`
    max-width: 75%;
    display: inline-block;
    vertical-align: middle;
`;

const TagWrap = styled.span`
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;

const LoadingWrap = styled.div`
    text-align: center;
    margin: 40px auto;
`;

const getMetafields = async ({ selectedProductId, pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${ selectedProductId }/metafields`,
            method: 'GET'
        });
        if ( data ) {
            await setPageState({
                ...pageState,
                exclusionField: data.find(({ key }) => key === 'exclusions'),
                companionField: data.find(({ key }) => key === 'companion'),
                loading: false,
                loadingMakes: true,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateExclusionMetafield = async ({ selectedProductId, pageState, setPageState, setShowToast }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${ selectedProductId }/metafields/${ pageState.exclusionField.id }`,
            method: 'PUT',
            data: {
                value: pageState.exclusionField.value
            }
        });
        if ( data ) {
            await setShowToast(true);
            return setPageState({
                ...pageState,
                loadingUpdateExclusions: false,
                exclusionFieldChange: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingUpdateExclusions: false,
            errors
        });
    }
};

const updateCompanionMetafield = async ({ selectedProductId, pageState, setPageState, setShowToast }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${ selectedProductId }/metafields/${ pageState.companionField.id }`,
            method: 'PUT',
            data: {
                value: pageState.companionField.value
            }
        });
        if ( data ) {
            await setShowToast(true);
            return setPageState({
                ...pageState,
                loadingUpdateCompanions: false,
                companionFieldChange: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingUpdateCompanions: false,
            errors
        });
    }
};

const getMakes = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes`,
            method: 'GET'
        });
        if ( data ) {
            return setPageState({
                ...pageState,
                makes: data.makes,
                loadingMakes: false,
                loading: false,
                loadingCompanionField: true,
                selectedModel: '',
                selectedYear: '',
                models: [],
                years: [],
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingMakes: false,
            errors
        });
    }
};

const getMakeModels = async ({ pageState, setPageState }) => {
    try {
        const makeId = pageState.makes
            .find(({ name }) => name === pageState.selectedMake).id;
        const { data } = await Axios({
            url: `/api/makes/${ makeId }/models`,
            method: 'GET'
        });
        if ( data ) {
            return setPageState({
                ...pageState,
                models: data,
                loadingModels: false,
                selectedYear: '',
                years: [],
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingModels: false,
            errors
        });
    }
};

const getModelYears = async ({ pageState, setPageState }) => {
    try {
        const modelId = pageState.models
            .find(({ name }) => name === pageState.selectedModel).id;
        const { data } = await Axios({
            url: `/api/models/${ modelId }/years`,
            method: 'GET'
        });
        if ( data ) {
            return setPageState({
                ...pageState,
                years: data,
                loadingYears: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingYears: false,
            errors
        });
    }
};

const getCompanionProducts = async ({ pageState, setPageState }) => {
    try {
        const parsedCompanionField = pageState.companionField
            ? JSON.parse(pageState.companionField.value)
            : null;

        const ids = Object
            .keys(parsedCompanionField)
            .filter(key => parsedCompanionField.hasOwnProperty(key))
            .map((key) => {
                let result = null;
                const findFunction = (item) => item.indexOf('productId--') !== -1;
                const value = parsedCompanionField[key].split(',').find(findFunction);
                if ( value ) {
                    const splitTag = value.split('--');
                    result = splitTag[splitTag.length - 1];
                }
                return result;
            })
            .filter((item) => item !== null);

        const { data } = await Axios({
            url: `/api/products${ ids ? `?ids=${ ids.join(',') }` : '' }`,
            method: 'GET'
        });
        if ( data ) {
            return setPageState({
                ...pageState,
                companionProducts: data,
                loadingCompanionField: false,
                loadingMakes: false,
                loading: false,
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingCompanionField: false,
            loading: false,
            loadingMakes: false,
            errors
        });
    }
};

const fieldBuilder = ({ exclusionField, selectedMake, selectedModel, selectedYear }) => {
    return `${ exclusionField.value },${ selectedMake }--${ selectedModel }--${ selectedYear }`
};

const warningMessage = message => (<Banner status="warning">{ message }</Banner>);

const filterOnlyVariantIds = item => item !== '' && item.indexOf('productId') === -1 && !isNaN(item);

const generateValidVariants = ({ items, variants }) => {
    try {
        const variantIdSplit = items.split(',').filter(filterOnlyVariantIds);
        const variantIdArray = variantIdSplit.map(variantId => parseInt(variantId));
        const variantIds = new Set(variantIdArray);
        return variants.filter(variant => variantIds.has(variant.id));
    } catch (error) {
        //TODO: do something with errors
        console.log("Variant Error");
        return [];
    }
};

const parseJsonMetafield = ({ companionField }) => {
    try {
        return companionField
            ? JSON.parse(companionField.value)
            : null;
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return null;
    }
};

const getProductId = ({ selectedProduct }) => {
    try {
        const productSplit = selectedProduct.id.split('/');
        return productSplit[productSplit.length - 1];
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return null;
    }
};

const generateVariantIds = ({ variants }) => {
    try {
        return variants
            .map((variant) => {
                const variantSplit = variant.id.split('/');
                return variantSplit[variantSplit.length - 1];
            }).join(',');
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return [];
    }
};

const ProductFields = (props) => {

    const [ showToast, setShowToast ] = useState(false);
    const [ pageState, setPageState ] = useState({
        loading: true,
        exclusionField: null,
        companionField: null,
        exclusionFieldChange: false,
        companionFieldChange: false,
        loadingMakes: false,
        loadingModels: false,
        loadingYears: false,
        loadingUpdateExclusions: false,
        loadingUpdateCompanions: false,
        loadingCompanionField: false,
        companionProducts: [],
        selectedMake: '',
        selectedModel: '',
        selectedYear: '',
        selectedCompanionProduct: null,
        makes: [],
        models: [],
        years: [],
        open: false
    });

    const { selectedProduct } = props;

    useEffect(() => {
        const selectedProductId = getProductId({ selectedProduct });
        if ( pageState.loading ) {
            (getMetafields)({ selectedProductId, pageState, setPageState, setShowToast });
        }
        if ( selectedProduct && pageState.loadingMakes ) {
            (getMakes)({ pageState, setPageState, setShowToast });
        }
        if ( pageState.selectedMake && pageState.loadingModels ) {
            (getMakeModels)({ pageState, setPageState, setShowToast });
        }
        if ( pageState.selectedMake && pageState.selectedModel && pageState.loadingYears ) {
            (getModelYears)({ pageState, setPageState, setShowToast });
        }
        if ( pageState.loadingUpdateExclusions ) {
            (updateExclusionMetafield)({ selectedProductId, pageState, setPageState, setShowToast });
        }
        if ( pageState.loadingUpdateCompanions ) {
            (updateCompanionMetafield)({ selectedProductId, pageState, setPageState, setShowToast });
        }
        if ( pageState.companionField && pageState.loadingCompanionField ) {
            (getCompanionProducts)({ pageState, setPageState, setShowToast });
        }
    }, [ selectedProduct, pageState ])

    const removeItem = item => setPageState({
        ...pageState,
        exclusionField: {
            ...pageState.exclusionField,
            value: pageState.exclusionField.value.replace(item, '')
        },
        exclusionFieldChange: true,
        loadingUpdateExclusions: true,
    });

    const addItem = () => setPageState({
        ...pageState,
        exclusionField: {
            ...pageState.exclusionField,
            value: fieldBuilder(pageState)
        },
        exclusionFieldChange: true,
        selectedMake: '',
        selectedModel: '',
        selectedYear: '',
        models: [],
        years: [],
        loadingUpdateExclusions: true,
    });

    const handleMakeChange = (selectedMake) => setPageState({
        ...pageState,
        loadingModels: true,
        selectedMake
    });

    const handleModelChange = (selectedModel) => setPageState({
        ...pageState,
        loadingYears: true,
        selectedModel
    });

    const handleYearChange = (selectedYear) => setPageState({
        ...pageState,
        selectedYear
    });

    const saveExclusions = () => setPageState({
        ...pageState,
        loadingUpdateExclusions: true
    });

    const saveCompanions = () => setPageState({
        ...pageState,
        loadingUpdateCompanions: true
    });

    const openCompanionPicker = () => setPageState({
        ...pageState,
        open: true
    });

    const removeCompanionProduct = key => {
        const parsed = parseJsonMetafield({
            companionField: pageState.companionField
        });
        delete parsed[key];
        return setPageState({
            ...pageState,
            companionField: {
                ...pageState.companionField,
                value: JSON.stringify(parsed)
            },
            companionFieldChange: true,
            loadingCompanionField: true
        });
    };

    const addCompanionProduct = ({ selection }) => {
        const selectedProduct = selection[0] || null;
        const productId = getProductId({ selectedProduct });

        const parsedCompanionField = parseJsonMetafield({
            companionField: pageState.companionField
        });

        delete parsedCompanionField.empty;

        const variantIds = generateVariantIds({
            variants: selectedProduct.variants
        });

        parsedCompanionField[selectedProduct.handle] = `${ variantIds },productId--${ productId }`

        return setPageState({
            ...pageState,
            selectedCompanionProduct: selectedProduct,
            open: false,
            companionField: {
                ...pageState.companionField,
                value: JSON.stringify(parsedCompanionField)
            },
            companionFieldChange: true,
            loadingCompanionField: true
        });
    };

    const cancelCompanion = () => setPageState({
        ...pageState,
        open: false
    });

    const configureExclusionTags = ({ value }) => {
        const values = value.split(',').filter(item => item !== '' && item !== 'empty');
        if ( !values.length ) {
            return warningMessage(`There are no exclusions associated with this product.`)
        }
        return values.map((item, i) => (
            <TagWrap key={ `tag-${ i + 1 }` }>
                <Tag onRemove={ () => removeItem(item) }>
                    { item.replace(/--/g, ' / ') }
                </Tag>
            </TagWrap>
        ))
    };

    const renderItems = ({ item, parsedCompanionField }) => {
        const { id, handle, title, variants } = item;
        const variantStringItems = parsedCompanionField[handle];
        let validVariants = [];

        const image = item && item.images.length ? item.images[0].src : null;

        const media = image ? (
            <Thumbnail
                source={ image }
                size="large"
                alt={ handle }
            />
        ) : (
            <Avatar customer size="large" name={ handle } />
        );

        if ( variantStringItems ) {
            validVariants = generateValidVariants({
                items: variantStringItems,
                variants
            });
        }

        return (
            <ResourceList.Item
                id={ id }
                media={ media }
                accessibilityLabel={ `View details for ${ handle }` }
                shortcutActions={ [ {
                    icon: 'circleCancel',
                    onClick: () => removeCompanionProduct(handle),
                } ] }
            >
                <Headline>
                    <TextStyle variation="strong">{ title }</TextStyle>
                </Headline>
                <Variants>
                    <VariantHeadline>Selected Variants:</VariantHeadline>
                    { validVariants.map((variant, i) => (<p key={ `variant-${ i + 1 }` }>{ variant.title }: { variant.id }</p>)) }
                </Variants>
            </ResourceList.Item>
        );
    };

    const {
        open,
        makes,
        years,
        errors,
        models,
        loading,
        selectedYear,
        selectedMake,
        selectedModel,
        exclusionField,
        companionField,
        companionProducts,
        companionFieldChange,
        exclusionFieldChange,
        loadingCompanionField,
        loadingUpdateExclusions,
    } = pageState;

    const parsedCompanionField = parseJsonMetafield({ companionField });

    return (
        <>
            <ErrorMessages errors={ errors } />
            { exclusionField ? (
                <Card
                    title="Make/Model/Year Exclusions"
                    /*primaryFooterAction={{
                        content: 'Save',
                        disabled: !exclusionFieldChange,
                        onClick: saveExclusions
                    }}*/
                >
                    <Card.Section>
                        { configureExclusionTags(exclusionField) }
                    </Card.Section>
                    <Card.Section>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Select
                                    placeholder="Make"
                                    options={ makes.map(({ id, name }) => ({ label: name, value: name })) }
                                    onChange={ handleMakeChange }
                                    disabled={ !makes.length }
                                    value={ selectedMake }
                                />
                                <Select
                                    placeholder="Model"
                                    options={ models.map(({ id, name }) => ({ label: name, value: name })) }
                                    onChange={ handleModelChange }
                                    disabled={ !models.length }
                                    value={ selectedModel }
                                />
                                <Select
                                    placeholder="Year"
                                    options={ years.map(({ id, year }) => ({ label: year, value: year })) }
                                    onChange={ handleYearChange }
                                    disabled={ !years.length }
                                    value={ selectedYear }
                                />
                                <Button
                                    fullWidth
                                    disabled={ !selectedMake || !selectedModel || !selectedYear || loadingUpdateExclusions }
                                    onClick={ addItem }
                                >
                                    Exclude
                                </Button>
                            </FormLayout.Group>
                        </FormLayout>
                    </Card.Section>
                </Card>
            ) : null }
            { companionField && !loading ? (
                <Card
                    title="Companion Products"
                    actions={ [ {
                        content: 'Add companion',
                        onClick: openCompanionPicker
                    } ] }
                    primaryFooterAction={ {
                        content: 'Save',
                        disabled: !companionFieldChange,
                        onClick: saveCompanions
                    } }
                >
                    <Card.Section>
                        <ResourceList
                            resourceName={ {
                                singular: 'companion product',
                                plural: 'companion products'
                            } }
                            items={ companionProducts }
                            renderItem={ (item) => renderItems({ item, parsedCompanionField }) }
                        />
                        { !loadingCompanionField && !companionProducts.length ? warningMessage(`There are no companion products associated with the selected product`) : null }
                        { loadingCompanionField ? <LoadingWrap><Spinner size="large" color="teal" /></LoadingWrap> : null }
                    </Card.Section>
                </Card>
            ) : (
                <LoadingWrap>
                    <Spinner size="large" color="teal" />
                </LoadingWrap>
            ) }
            <ResourcePicker
                resourceType="Product"
                open={ open }
                onSelection={ addCompanionProduct }
                onCancel={ cancelCompanion }
                allowMultiple={ false }
                showVariants={ true }
            />
            <Frame>
                { showToast ?
                    <Toast
                        content={ `Saved` }
                        onDismiss={ () => setShowToast(!showToast) }
                    />
                    : null }
            </Frame>
        </>
    )
};

export default ProductFields;
