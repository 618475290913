import React, { useState, useEffect} from "react"
import Axios from "axios"
import {
    Tag,
    Icon,
    Card,
    Stack,
    Toast,
    Frame,
    Sheet,
    Button,
    FormLayout,
    Banner,
    Select,
    Spinner,
    Heading,
    TextStyle,
    Scrollable,
    ResourceList,
    ResourcePicker,
} from "@shopify/polaris"

import { MobileCancelMajorMonotone } from "@shopify/polaris-icons"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const Headline = styled.h3`
    width: 25%;
    display: inline-block;
    margin-right: 40px;
    vertical-align: middle;
`;

const VariantHeadline = styled.h3`
    font-weight: 600;
    margin-bottom: 10px;
`;

const Variants = styled.div`
    max-width: 75%;
    display: inline-block;
    vertical-align: middle;
`;

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const TagWrap = styled.span`
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;

const SpinnerWrap = styled.div`
    display: ${({ displaySpinner }) => displaySpinner ? `flex` : `none`};
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.4);
`;

const LoadingWrap = styled.div`
    text-align: center;
    margin: 40px auto;
`;

const getMetafields = async ({ selectedProductId, pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${selectedProductId}/variants/${pageState.selectedVariant}/metafields`,
            method: 'GET'
        });
        if (data) {
            await setPageState({
                ...pageState,
                exclusionField: data.find(({ key }) => key === 'make_model_year'),
                variantExclusionField: data.find(({ key }) => key === 'variant'),
                loadingVariantExclusionField: true,
                loading: false
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateExclusionMetafield = async ({ selectedProductId, pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${selectedProductId}/variants/${pageState.selectedVariant}/metafields/${pageState.exclusionField.id}`,
            method: 'PUT',
            data: {
                value: pageState.exclusionField.value
            }
        });
        if (data) {
            return setPageState({
                ...pageState,
                loadingUpdateExclusions: false,
                showToast: true,
                exclusionFieldChange: false
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingUpdateExclusions: false,
            errors
        });
    }
};

const updateCompanionMetafield = async ({ selectedProductId, pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${selectedProductId}/variants/${pageState.selectedVariant}/metafields/${pageState.variantExclusionField.id}`,
            method: 'PUT',
            data: {
                value: pageState.variantExclusionField.value
            }
        });
        if (data) {
            return setPageState({
                ...pageState,
                loadingUpdateCompanions: false,
                variantExclusionFieldChange: false,
                showToast: true
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingUpdateCompanions: false,
            errors
        });
    }
};

const getMakes = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                makes: data.makes,
                loadingMakes: false,
                loading: false,
                loadingCompanionField: true,
                selectedModel: '',
                selectedYear: '',
                models: [],
                years: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingMakes: false,
            errors
        });
    }
};

const getMakeModels = async ({ pageState, setPageState }) => {
    try {
        const makeId = pageState.makes
            .find(({ name }) => name === pageState.selectedMake).id;
        const { data } = await Axios({
            url: `/api/makes/${makeId}/models`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                models: data,
                loadingModels: false,
                selectedYear: '',
                years: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingModels: false,
            errors
        });
    }
};

const getModelYears = async ({ pageState, setPageState }) => {
    try {
        const modelId = pageState.models
            .find(({ name }) => name === pageState.selectedModel).id;
        const { data } = await Axios({
            url: `/api/models/${modelId}/years`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                years: data,
                loadingYears: false
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingYears: false,
            errors
        });
    }
};

const getCompanionProducts = async ({ pageState, setPageState }) => {
    try {
        const parsedCompanionField = pageState.variantExclusionField
            ? JSON.parse(pageState.variantExclusionField.value)
            : null;

        delete parsedCompanionField.empty;
        const ids = Object
            .keys(parsedCompanionField)
            .filter(key => parsedCompanionField.hasOwnProperty(key))
            .map((key) => {
                let result = null;
                const findFunction = (item) => item.indexOf('productId--') !== -1;
                const value = parsedCompanionField[key].split(',').find(findFunction);
                if (value) {
                    const splitTag = value.split('--');
                    result = splitTag[splitTag.length - 1];
                }
                return result;
            })
            .filter((item) => item !== null);

        const { data } = await Axios({
            url: `/api/products${ids ? `?ids=${ids.join(',')}` : ''}`,
            method: 'GET'
        });

        if (data) {
            return setPageState({
                ...pageState,
                companionProducts: data,
                loadingVariantExclusionField: false,
                loadingMakes: false,
                loading: false,
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            loadingVariantExclusionField: false,
            loadingMakes: false,
            errors
        });
    }
};

const fieldBuilder = ({ exclusionField, selectedMake, selectedModel, selectedYear }) => {
    return `${exclusionField.value},${selectedMake}--${selectedModel}--${selectedYear}`
};

const warningMessage = message => (<Banner status="warning">{message}</Banner>);

const filterOnlyVariantIds = item => item !== '' && item.indexOf('productId') === -1 && !isNaN(item);

const generateValidVariants = ({ items, variants }) => {
    try {
        const variantIdSplit= items.split(',').filter(filterOnlyVariantIds);
        const variantIdArray = variantIdSplit.map(variantId => parseInt(variantId));
        const variantIds = new Set(variantIdArray);
        return variants.filter(variant => variantIds.has(variant.id));
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return [];
    }
};

const parseJsonMetafield = ({ variantExclusionField }) => {
    try {
        return  variantExclusionField
            ? JSON.parse(variantExclusionField.value)
            : null;
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return null;
    }
};

const getId = ({ selected }) => {
    try {
        const productSplit = selected.id.split('/');
        return productSplit[productSplit.length -1];
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return null;
    }
};

const generateVariantIds = ({ variants }) => {
    try {
        return variants
            .map((variant) => {
                const variantSplit = variant.id.split('/');
                return variantSplit[variantSplit.length -1];
            }).join(',');
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return [];
    }
};

const CompanionFields = (props) => {

    const [pageState, setPageState] = useState({
        loading: false,
        exclusionField: null,
        variantExclusionField: null,
        exclusionFieldChange: false,
        variantExclusionFieldChange: false,
        loadingMakes: true,
        loadingModels: false,
        loadingYears: false,
        loadingUpdateExclusions: false,
        loadingUpdateCompanions: false,
        loadingVariantExclusionField: false,
        companionProducts: [],
        showToast: false,
        selectedVariant: null,
        selectedMake: '',
        selectedModel: '',
        selectedYear: '',
        selectedCompanionProduct: null,
        makes: [],
        models: [],
        years: [],
        open: false
    });

    const [openSheet, setOpenSheet] = useState({
        id: null,
        title: null,
        open: false,
        type: null
    });

    const { selectedProduct } = props;
    useEffect(() => {}, [openSheet.open])
    useEffect(() => {
        const selectedProductId = getId({ selected: selectedProduct });
        if (pageState.loading) {
            (getMetafields)({ selectedProductId, pageState, setPageState });
        }
        if (pageState.loadingMakes) {
            (getMakes)({ pageState, setPageState });
        }
        if (pageState.selectedMake && pageState.loadingModels) {
            (getMakeModels)({ pageState, setPageState });
        }
        if (pageState.selectedMake && pageState.selectedModel && pageState.loadingYears) {
            (getModelYears)({ pageState, setPageState });
        }
        if (pageState.loadingUpdateExclusions) {
            (updateExclusionMetafield)({ selectedProductId, pageState, setPageState });
        }
        if (pageState.variantExclusionField && pageState.loadingVariantExclusionField) {
            (getCompanionProducts)({ pageState, setPageState });
        }
        if (pageState.loadingUpdateCompanions) {
            (updateCompanionMetafield)({ selectedProductId, pageState, setPageState });
        }
    }, [selectedProduct, pageState])

    const removeItem = item => setPageState({
        ...pageState,
        exclusionField: {
            ...pageState.exclusionField,
            value: pageState.exclusionField.value.replace(item, '')
        },
        exclusionFieldChange: true
    });

    const addItem = () => setPageState({
        ...pageState,
        exclusionField: {
            ...pageState.exclusionField,
            value: fieldBuilder(pageState)
        },
        exclusionFieldChange: true,
        selectedMake: '',
        selectedModel: '',
        selectedYear: '',
        models: [],
        years: []
    });

    const handleMakeChange = (selectedMake) => setPageState({
        ...pageState,
        loadingModels: true,
        selectedMake
    });

    const handleModelChange = (selectedModel) => setPageState({
        ...pageState,
        loadingYears: true,
        selectedModel
    });

    const handleYearChange = (selectedYear) => setPageState({
        ...pageState,
        selectedYear
    });

    const saveExclusions = () => setPageState({
        ...pageState,
        loadingUpdateExclusions: true
    });

    const saveCompanions = () => setPageState({
        ...pageState,
        loadingUpdateCompanions: true
    });

    const openCompanionPicker = () => setPageState({
        ...pageState,
        open: true
    });

    const removeCompanionProduct = key => {
        const parsed = parseJsonMetafield({
            variantExclusionField: pageState.variantExclusionField
        });
        delete parsed[key];
        return setPageState({
            ...pageState,
            variantExclusionField: {
                ...pageState.variantExclusionField,
                value: JSON.stringify(parsed)
            },
            variantExclusionFieldChange: true,
            loadingVariantExclusionField: true
        });
    };

    const addCompanionProduct = ({ selection }) => {
        const selectedPickerProduct = selection[0] || null;

        const productId = getId({ selected: selectedPickerProduct });

        const parsedCompanionField = parseJsonMetafield({
            variantExclusionField: pageState.variantExclusionField
        });

        delete parsedCompanionField.empty;

        const variantIds = generateVariantIds({
            variants: selectedPickerProduct.variants
        });
        
        parsedCompanionField[selectedPickerProduct.handle] = `${variantIds},productId--${productId}`

        return setPageState({
            ...pageState,
            selectedCompanionProduct: selectedPickerProduct,
            open: false,
            variantExclusionField: {
                ...pageState.variantExclusionField,
                value: JSON.stringify(parsedCompanionField)
            },
            variantExclusionFieldChange: true,
            loadingVariantExclusionField: true
        });
    };

    const cancelCompanion = () => setPageState({
        ...pageState,
        open: false
    });

    const configureExclusionTags = ({ value }) => {
        const values = value.split(',').filter(item => item !== '' && item !== 'empty');
        if (!values.length) {
            return warningMessage(`There are no exclusions associated with this product.`)
        }
        return values.map((item, i) => (
            <TagWrap key={`tag-${i+1}`}>
                <Tag onRemove={() => removeItem(item)}>
                    {item.replace(/--/g, ' / ')}
                </Tag>
            </TagWrap>
        ))
    };

    const renderVariantMetaItems = ({ item, parsedCompanionField }) => {
        const { id, handle, title, variants } = item;
        const variantStringItems = parsedCompanionField[handle];
        let validVariants = [];

        if (variantStringItems) {
            validVariants = generateValidVariants({
                items: variantStringItems,
                variants
            });
        }

        return (
            <ResourceList.Item
                id={id}
                accessibilityLabel={`View details for ${handle}`}
                shortcutActions={[{
                    icon: 'circleCancel',
                    onClick: () => removeCompanionProduct(handle),
                }]}
            >
                <Headline>
                    <TextStyle variation="strong">{title}</TextStyle>
                </Headline>
                <Variants>
                    <VariantHeadline>Selected Variants:</VariantHeadline>
                    {validVariants.map((variant, i) => (<p key={`variant-${i+1}`}><b>{variant.title}</b>: {variant.id}</p>))}
                </Variants>
            </ResourceList.Item>
        );
    };

    const renderItems = (selectedVariant) => {
        const { id, title } = selectedVariant
        const selectedVariantId = getId({ selected: selectedVariant });
        return (
            <ResourceList.Item
                id={id}
                accessibilityLabel={`View details for ${title}`}
                shortcutActions={[
                    {
                        content: 'M/M/Y Exclusions',
                        onClick: () => [setOpenSheet({
                            variantTitle: selectedVariant.title,
                            title: `Make/Month/Year Exclusions`,
                            open: true,
                            type: 'make_model_year'
                        }),setPageState({
                            ...pageState,
                            selectedVariant: selectedVariantId,
                            loading: true
                        })]
                    },
                    {
                        content: 'Variant Exclusions',
                        onClick: () => [setOpenSheet({
                            variantTitle: selectedVariant.title,
                            title: 'Variant Exclusions',
                            open: true,
                            type: 'variant'
                        }),setPageState({
                            ...pageState,
                            selectedVariant: selectedVariantId,
                            loading: true
                        })]
                    }
                ]}
            >
                <h3>
                    <TextStyle variation="strong">{title}</TextStyle>
                </h3>
            </ResourceList.Item>
        );
    };

    const {
        open,
        makes,
        years,
        models,
        errors,
        loading,
        showToast,
        selectedYear,
        selectedMake,
        selectedModel,
        exclusionField,
        variantExclusionField,
        companionProducts,
        variantExclusionFieldChange,
        exclusionFieldChange,
        loadingVariantExclusionField,
        loadingUpdateExclusions,
    } = pageState;

    const parsedCompanionField = parseJsonMetafield({ variantExclusionField });

    const makeModelYearForm = (
        <FormLayout>
            <Select
                placeholder="Make"
                options={makes.map(({ id, name }) => ({ label: name, value: name }))}
                onChange={handleMakeChange}
                disabled={!makes.length}
                value={selectedMake}
            />
            <Select
                placeholder="Model"
                options={models.map(({ id, name }) => ({ label: name, value: name }))}
                onChange={handleModelChange}
                disabled={!models.length}
                value={selectedModel}
            />
            <Select
                placeholder="Year"
                options={years.map(({ id, year }) => ({ label: year, value: year }))}
                onChange={handleYearChange}
                disabled={!years.length}
                value={selectedYear}
            />
            <Button
                fullWidth
                disabled={!selectedMake || !selectedModel || !selectedYear || loadingUpdateExclusions}
                onClick={addItem}
            >
                Exclude
            </Button>
            {exclusionField ? <Heading>Exclusions</Heading> : null}
            {exclusionField ? configureExclusionTags(exclusionField) : null}
        </FormLayout>
    );

    const variantForm = (
        <Card
            title="Excluded variants"
            actions={[{
                content: 'Add exclusions',
                onClick: openCompanionPicker
            }]}
        >
            <Card.Section>
                <ResourceList
                    resourceName={{
                        singular: 'variant exclusion',
                        plural: 'variant exclusions'
                    }}
                    items={companionProducts}
                    renderItem={(item) => renderVariantMetaItems({ item, parsedCompanionField })}
                />
                {!loadingVariantExclusionField && !companionProducts.length ? warningMessage(`There are no variant exclusions associated with the selected product`) : null}
                {loadingVariantExclusionField ? <LoadingWrap><Spinner size="large" color="teal" /></LoadingWrap> : null}
            </Card.Section>
        </Card>
    );

    return (
        <>
            <ErrorMessages errors={errors}/>
            <Card>
                <Card.Section>
                    <ResourceList
                        resourceName={{
                            singular: 'variant',
                            plural: 'variants'
                        }}
                        items={selectedProduct.variants}
                        renderItem={renderItems}
                    />
                </Card.Section>
            </Card>
            <ResourcePicker
                resourceType="Product"
                open={open}
                onSelection={addCompanionProduct}
                onCancel={cancelCompanion}
                allowMultiple={false}
                showVariants={true}
            />
            <Frame>
                <Sheet
                    open={openSheet.open}
                    onClose={() => [setOpenSheet({
                        ...openSheet,
                        open: false,
                        variantTitle: null,
                        type: null,
                        id: null
                    }),setPageState({
                        ...pageState,
                        companionProducts: [],
                        exclusionField: null,
                        exclusionFieldChange: true,
                        selectedMake: '',
                        selectedModel: '',
                        selectedYear: '',
                        models: [],
                        years: []
                    })]}
                >
                    <SheetWrap>
                        <SpinnerWrap displaySpinner={loading || loadingUpdateExclusions}>
                            <Spinner size="large" color="teal" />
                        </SpinnerWrap>
                        <SheetHeader>
                            <Stack distribution="equalSpacing">
                                <Icon source="alert" color="red" backdrop={true}/>
                                <Heading>{openSheet.title}</Heading>
                                <Button
                                    accessibilityLabel="Cancel"
                                    icon={MobileCancelMajorMonotone}
                                    onClick={() => [setOpenSheet({
                                        ...openSheet,
                                        variantTitle: null,
                                        open: false,
                                        type: null
                                    }),setPageState({
                                        ...pageState,
                                        companionProducts: [],
                                        exclusionField: null,
                                        exclusionFieldChange: true,
                                        selectedMake: '',
                                        selectedModel: '',
                                        selectedYear: '',
                                        models: [],
                                        years: []
                                    })]}
                                    plain
                                />
                            </Stack>
                        </SheetHeader>
                        <StyledScrollable>
                            {openSheet.variantTitle ? (
                                <Heading>{openSheet.variantTitle}</Heading>
                            ) : null}
                            <br/>
                            {openSheet.type === 'make_model_year' ? makeModelYearForm : null}
                            {openSheet.type === 'variant' ? variantForm : null}
                        </StyledScrollable>
                        <SheetFooter>
                            <Button
                                onClick={() => [setOpenSheet({
                                    ...openSheet,
                                    variantTitle: null,
                                    open: false,
                                    type: null
                                }),setPageState({
                                    ...pageState,
                                    companionProducts: [],
                                    exclusionField: null,
                                    exclusionFieldChange: true,
                                    selectedMake: '',
                                    selectedModel: '',
                                    selectedYear: '',
                                    models: [],
                                    years: []
                                })]}
                            >
                                Cancel
                            </Button>
                            {openSheet.type === 'make_model_year' ? (
                                <Button
                                    disabled={!exclusionFieldChange}
                                    onClick={saveExclusions}
                                >
                                    Save
                                </Button>
                            ) : null}
                            {openSheet.type === 'variant' ? (
                                <Button
                                    disabled={!variantExclusionFieldChange}
                                    onClick={saveCompanions}
                                >
                                    Save
                                </Button>
                            ) : null}
                        </SheetFooter>
                    </SheetWrap>
                </Sheet>
                {showToast ?
                    <Toast
                        content={`Saved`}
                        onDismiss={() => setPageState({
                            ...pageState,
                            showToast: false
                        })}
                    />
                : null}
            </Frame>
        </>
    )
};

export default CompanionFields;

