import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Card,
    Modal,
    Layout,
    Scrollable,
    Badge,
    Heading,
    Icon,
    Collapsible,
    Stack,
    Frame,
} from "@shopify/polaris"

import styled from "styled-components"

import { DeleteMajorMonotone } from "@shopify/polaris-icons"

import ErrorMessages from "../ErrorMessages"

const getImages = async ({ galleryState, setGalleryState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/images`,
            method: 'GET'
        });
        if (data) {
            return setGalleryState({
                ...galleryState,
                imageGallery: data,
                loading: false
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setGalleryState({
            ...galleryState,
            loading: false,
            errors
        });
    }
};


const imageDelete = async ({ galleryState, setGalleryState, selectedImage, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/images/${selectedImage.id}`,
            method: 'DELETE'
        });
        const { data: imageList } = await Axios({
            url: `/api/fabricators/${id}/images`,
            method: 'GET'
        });
        if (data && imageList) {
            return setGalleryState({
                ...galleryState,
                imageGallery: imageList,
                loadingDelete: false,
                selectedImage: null
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setGalleryState({
            ...galleryState,
            loadingDelete: false,
            errors
        });
    }
};

const Image = styled.img`
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    max-height: 700px;
`;

const Gallery = ({ id }) => {

    const [galleryState, setGalleryState] = useState({
        imageGallery: [],
        selectedImage: null,
        loading: true,
        loadingDelete: false,
        displayModal: false,
        errors: []
    });

    useEffect(() => {
        if (galleryState.loading) {
            (getImages)({ galleryState, setGalleryState, id });
        }
        if (galleryState.loadingDelete) {
            (imageDelete)({
                id,
                galleryState,
                setGalleryState,
                selectedImage: galleryState.selectedImage
            });
        }
    }, [
        id,
        galleryState,
        galleryState.loading,
        galleryState.loadingDelete,
        galleryState.displayModal,
    ]);

    const imageClick = (selectedImage) => setGalleryState({
        ...galleryState,
        displayModal: true,
        selectedImage
    });

    const handleDeleteCancel = () => setGalleryState({
        ...galleryState,
        displayModal: false
    });

    const deleteSelectedImage = () => setGalleryState({
        ...galleryState,
        displayModal: false,
        loadingDelete: true,
        open: true
    });

    const toggleGallery = () => setGalleryState({
        ...galleryState,
        open: !galleryState.open
    });

    const {
        imageGallery,
        loading,
        displayModal,
        open,
        errors
    } = galleryState;

    return (
        <>
            <ErrorMessages errors={errors}/>
            <Card
                sectioned
                title={<Heading>Gallery Images &nbsp;&nbsp;<Badge status="info">{imageGallery.length}</Badge></Heading>}
                actions={[
                    {
                        content: open ? 'Hide Gallery' : 'Show Gallery',
                        onClick: toggleGallery
                    }
                ]}
            >
                {!loading && imageGallery.length ?
                    <Collapsible open={open} id="galleryCollapsible">
                        <StyledScrollable>
                            <Layout>
                                {imageGallery.map(({ id: imageId, url, active }) => (
                                    <Layout.Section key={imageId}  oneThird>
                                        <Image src={url} alt="" />
                                        <span style={{ cursor: 'pointer' }} onClick={() => imageClick({ id: imageId, url }) }>
                                            <Stack
                                                distribution="center"
                                            >
                                                {!active ? (
                                                    <Badge status="attention">Pending</Badge>
                                                ) : (
                                                    <Icon source={DeleteMajorMonotone} />
                                                )}
                                            </Stack>
                                        </span>
                                    </Layout.Section>
                                ))}
                            </Layout>
                        </StyledScrollable>
                    </Collapsible>
                : null}
            </Card>
            {!loading ? (
                <Frame>
                    <Modal
                        open={displayModal}
                        onClose={() => {}}
                        title={`Delete Image`}
                        message={`Are you sure you want to DELETE this image?`}
                        primaryAction={{
                            content: 'Delete',
                            onAction: deleteSelectedImage,
                            destructive: true
                        }}
                        secondaryActions={[{
                            content: 'Cancel',
                            onAction: handleDeleteCancel,
                        }]}
                    >
                    </Modal>
                </Frame>
            ): null }
        </>
    )
}

export default Gallery;

