import React, { useState, useEffect } from "react"
import Axios from "axios";
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Stack,
    Toast,
    Checkbox,
    InlineError,
    DisplayText,
    Frame,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

const createEquipmentApi = async ({ setPageState, pageState, postData }) => {
    try {
        const { data } = await Axios({
            url: `/api/equipment`,
            method: 'POST',
            data: postData
        });
        if (data) {
            return setPageState({
                ...setPageState,
                name: null,
                description: null,
                status: true,
                loading: false,
                success: true,
                errors: []
            });
        }
        return setPageState({
            ...pageState,
            loading: false,
            success: false,
            errors: []
        });
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const EquipmentCreate = (props) => {

    const [pageState, setPageState] = useState({
        name: null,
        description: null,
        status: true,
        loading: false,
        success: false,
        errors: []
    });

    const {
        name,
        description,
        status,
        loading,
        success,
        errors
    } = pageState;

    useEffect(() => {
        if (pageState.loading) {
            (createEquipmentApi)({
                pageState,
                setPageState,
                postData: {
                    name,
                    description,
                    status,
                }
            });
        }
    }, [
        name,
        description,
        status,
        loading,
        pageState
    ]);

    const handleCheckBoxChange = (value) => setPageState({
        ...pageState,
        status: !status
    });

    const handleNameChange = (value) => setPageState({
        ...pageState,
        name: value
    });

    const handleDescriptionChange = (value) => setPageState({
        ...pageState,
        description: value
    });

    const submitForm = () => setPageState({
        ...pageState,
        loading: true
    });

    const toggleToast = () => setPageState({
        ...pageState,
        success: false
    });

    const errorHandler = (field) => {
        if (errors.length) {
            const found = errors.find(({ path }) => path === field);
            if (found) {
                return { error: true, message: found.message };
            }
        }
        return { error: false, message: null };
    };

    const nameError = errorHandler('name');
    const descriptionError = errorHandler('description');

    return (
        <Page
            title="Create"
            breadcrumbs={[{
                content: 'Equipment',
                url: '/app/settings/equipment'
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Create Equipment
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Create',
                            onClick: submitForm,
                            disabled: !name,
                            loading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <Stack vertical>
                                    <Checkbox
                                        checked={status}
                                        label="Enabled"
                                        onChange={handleCheckBoxChange}
                                    />
                                </Stack>
                                <TextField
                                    type="text"
                                    label="Name"
                                    onChange={handleNameChange}
                                    value={name}
                                    error={nameError.error}
                                />
                                {nameError.error ?
                                    <div style={{marginTop: '4px'}}>
                                        <InlineError message={nameError.message} fieldID={'name'} />
                                    </div>
                                : null}
                                <TextField
                                    type="text"
                                    label="Description"
                                    onChange={handleDescriptionChange}
                                    value={description}
                                    error={descriptionError.error}
                                />
                                {descriptionError.error ?
                                    <div>
                                        <InlineError message={descriptionError.message} fieldID={'description'} />
                                    </div>
                                : null}
                            </FormLayout>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            {success ? (<Frame><Toast content="Equipment Created" onDismiss={toggleToast} /></Frame>) : null}
        </Page>
    )
};

export default EquipmentCreate;

