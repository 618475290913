import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import {
  Page,
  Layout,
  CalloutCard,
  FooterHelp,
  Toast,
  Banner,
} from "@shopify/polaris";
import styled from "styled-components";

import ErrorMessages from "../components/ErrorMessages";
import { ReactComponent as Logo } from "../move-logo-green.svg";

const LogoSvg = styled(Logo)`
  width: 200px;
  margin-right: 20px;
  margin: 10px auto;
`;

const DisplayTextLeft = styled.h2`
  display: flex;
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Roboto,
    Segoe UI, Helvetica Neue, sans-serif;
`;

const DashboardBanner = styled.div`
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
`;

const getDashboard = async ({ pageState, setPageState }) => {
  try {
    const { data } = await Axios({
      url: `/api/dashboard`,
      method: "GET",
    });
    if (data) {
      return setPageState({
        ...pageState,
        pendingFabricators: data.pendingFabricators,
        updatingFabricators: data.updatingFabricators,
        loading: false,
        errors: [],
      });
    }
  } catch (error) {
    console.log(error.response);
    const errors =
      error.response && error.response.data ? error.response.data : [];
    return setPageState({
      ...pageState,
      loading: false,
      errors,
    });
  }
};

const BannerWarning = ({ status, onDismiss, message, display }) =>
  display ? (
    <Layout.Section>
      <Banner status={status} onDismiss={onDismiss}>
        <p>{message}</p>
      </Banner>
    </Layout.Section>
  ) : null;

const Dashboard = (props) => {
  const [toasting, setToasting] = useState(false);
  const [toastingMessage, setToastingMessage] = useState(false);
  const [error, setError] = useState(null);
  const [exportFab, setExportFab] = useState(false);
  const [pageState, setPageState] = useState({
    loading: true,
    pendingFabricators: 0,
    updatingFabricators: 0,
    showUpdatingFabricators: true,
    showPendingFabricators: true,
  });

  useEffect(() => {
    if (pageState.loading) {
      getDashboard({ pageState, setPageState });
    }
  }, [props, pageState, pageState.loading]);

  useEffect(() => {
    if (exportFab) {
      exportFabricator();
    }
  }, [exportFab]);

  const exportFabricator = async () => {
    try {
      const response = await Axios({
        url: "/api/fabricators/export",
      });
      if (response && response.status == 200) {
        setError(null);
        setToastingMessage(
          "The exported data will be emailed to marketing@movebumpers.com "
        );
        setToasting(true);
        return setExportFab(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const toggleActive = useCallback(
    () => setToasting((toasting) => !toasting),
    []
  );

  const closePendingFabricators = () =>
    setPageState({
      ...pageState,
      showPendingFabricators: false,
    });

  const closeUpdatingFabricators = () =>
    setPageState({
      ...pageState,
      showUpdatingFabricators: false,
    });

  const {
    loading,
    pendingFabricators,
    showPendingFabricators,
    showUpdatingFabricators,
    updatingFabricators,
    errors,
  } = pageState;

  const pendingFabricatorBanner = BannerWarning({
    status: "warning",
    onDismiss: closePendingFabricators,
    message: `You have ${pendingFabricators} pending fabricator submissions.`,
    display: !loading && pendingFabricators && showPendingFabricators,
  });

  const pendingFabricatorUpdatesBanner = BannerWarning({
    status: "info",
    onDismiss: closeUpdatingFabricators,
    message: `You have ${updatingFabricators} fabricator with account updates.`,
    display: !loading && updatingFabricators && showUpdatingFabricators,
  });

  const handleExport = useCallback(() => setExportFab(true), []);

  const toastMarkup = toasting ? (
    <Toast
      content={toastingMessage}
      onDismiss={toggleActive}
      error={error !== null}
    />
  ) : null;

  return (
    <Page title="Dashboard">
      <Layout>
        <Layout.Section>
          <DashboardBanner>
            <DisplayTextLeft>
              <LogoSvg />
            </DisplayTextLeft>
          </DashboardBanner>
          <br />
          <ErrorMessages errors={errors} />
          <br />
        </Layout.Section>
        <Layout.Section>
          <CalloutCard
            title="Manage Fabricators"
            illustration="/images/welder.jpg"
            primaryAction={{
              content: "Manage Fabricators",
              url: "/app/fabricators",
            }}
          >
            {pendingFabricatorBanner}
            {pendingFabricatorUpdatesBanner}
            <p>Moderate, update, and manage your Fabricators directory.</p>
          </CalloutCard>
        </Layout.Section>
        <Layout.Section>
          <CalloutCard
            title="Makes, Models and Years"
            illustration="/images/makes.png"
            primaryAction={{
              content: "Manage Make/Model/Year",
              url: "/app/settings/makes",
            }}
          >
            <p>
              Manage the make, model and year combinations for bumper products.
            </p>
          </CalloutCard>
        </Layout.Section>
        <Layout.Section>
          <CalloutCard
            title="Configure Shopify products"
            illustration="/images/shopify.png"
            primaryAction={{
              content: "Configure Products",
              url: "/app/settings/product/configure",
            }}
          >
            <p>Configure Shopify products with makes, models and years.</p>
          </CalloutCard>
        </Layout.Section>
        <Layout.Section>
          <CalloutCard
            title="Export "
            illustration="/images/shopify.png"
            primaryAction={{
              content: "Export",
              onAction: () => handleExport(),
            }}
          >
            <p>
              Export fabricator to CSV and email it to marketing@movebumpers.com{" "}
            </p>
          </CalloutCard>
        </Layout.Section>
      </Layout>
      {toastMarkup}
      <FooterHelp>Move Bumpers &copy;</FooterHelp>
    </Page>
  );
};

export default Dashboard;
