import React, { useState, useEffect } from "react"
import Axios from "axios"
import createSlug from "slug"
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Select,
    Toast,
    DisplayText,
    Frame,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

const getCentersAndModels = async ({ setPageState, pageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/centers`,
            method: 'GET'
        });
        const { data: modelData } = await Axios({
            url: `/api/models`,
            method: 'GET'
        });
        if (data && modelData) {
            const { models } = modelData;
            return setPageState({
                ...pageState,
                centers: data,
                models,
                loadingCenters: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            loadingCenters: false,
            errors
        });
    }
};

const createYearApi = async ({ setPageState, pageState }) => {
    try {
        const selectedIds = [];
        if (pageState.selectedPrecisionCenter) {
            const found = pageState.centers
                .find(({ series, center_code }) => `${series} ${center_code}` === pageState.selectedPrecisionCenter);
            if (found) {
                selectedIds.push(found.id);
            }
        }

        if (pageState.selectedClassicCenter) {
            const found = pageState.centers
                .find(({ series, center_code }) => `${series} ${center_code}` === pageState.selectedClassicCenter);
            if (found) {
                selectedIds.push(found.id);
            }
        }

        const model = pageState.models.find(({ name }) => name === pageState.selectedModel)
        const { data } = await Axios({
            url: `/api/years`,
            method: 'POST',
            data: {
                year: pageState.year,
                slug: pageState.slugSubmit,
                active: pageState.active,
                selectedIds,
                modelId: model ? model.id : null
            }
        });

        if (data) {
            return setPageState({
                ...pageState,
                year: null,
                active: true,
                slug: null,
                slugSubmit: null,
                loading: false,
                loadingCenters: false,
                selectedClassicCenter: '',
                selectedPrecisionCenter: '',
                selectedModel: '',
                success: true,
                errors: []
            });
        }
        return setPageState({
            ...pageState,
            loading: false,
            loadingCenters: false,
            success: false,
            errors: []
        });
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            loadingCenters: false,
            errors
        });
    }
};

const YearCreate = (props) => {

    const [pageState, setPageState] = useState({
        year: null,
        slug: null,
        slugSubmit: null,
        active: true,
        loading: false,
        loadingCenters: true,
        success: false,
        models: [],
        selectedClassicCenter: '',
        selectedPrecisionCenter: '',
        selectedModel: '',
        centers: [],
        errors: []
    });

    useEffect(() => {
        if (pageState.loading) {
            (createYearApi)({
                pageState,
                setPageState
            });
        }

        if (pageState.loadingCenters) {
            (getCentersAndModels)({
                pageState,
                setPageState
            })
        }
    }, [pageState]);


    const handleNameChange = year => setPageState({
        ...pageState, year
    });

    const handleActiveChange = (value) => setPageState({
        ...pageState,
        active: value === 'active'
    });

    const handleModelChange = selectedModel => setPageState({
        ...pageState, selectedModel
    });

    const submitForm = () => setPageState({
        ...pageState,
        loading: true
    });

    const toggleToast = () => setPageState({
        ...pageState, success: false
    });

    const precisionChange = selectedPrecisionCenter => setPageState({
        ...pageState, selectedPrecisionCenter
    });

    const classicChange = selectedClassicCenter => setPageState({
        ...pageState, selectedClassicCenter
    });

    const handleSlugChange = slug => setPageState({
        ...pageState, slug, slugSubmit: createSlug(slug)
    });

    const errorHandler = (field) => {
        const found = pageState.errors.find(({ path }) => path === field);
        return found && found.message ? found.message : false;
    };

    const options = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
    ];

    const {
        year,
        slug,
        slugSubmit,
        active,
        loading,
        models,
        success,
        centers,
        loadingCenters,
        selectedModel,
        selectedPrecisionCenter,
        selectedClassicCenter,
        errors
    } = pageState;

    const classics = centers
        .filter(({ series }) => series === 'Classic')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    const precision = centers
        .filter(({ series }) => series === 'Precision')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    const modelOptions = models
        .map(({ name }) => ({ label: name, value: name }));

    const complete = year !== null
        && selectedPrecisionCenter !== ''
        && selectedClassicCenter !== ''
        && !loadingCenters;

    return (
        <Page
            title="Create"
            breadcrumbs={[{
                content: 'Years',
                url: '/app/settings/years'
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Create Year
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Create',
                            onClick: submitForm,
                            disabled: !complete,
                            loading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group>
                                    <Select
                                        label="Model"
                                        options={modelOptions}
                                        onChange={handleModelChange}
                                        value={selectedModel}
                                        placeholder="Select Model"
                                    />
                                    <Select
                                        label="Status"
                                        options={options}
                                        onChange={handleActiveChange}
                                        value={active ? 'active' : 'inactive'}
                                        error={errorHandler('active')}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <TextField
                                        type="text"
                                        label="Year"
                                        onChange={handleNameChange}
                                        value={year}
                                        error={errorHandler('year')}
                                    />
                                    <TextField
                                        type="text"
                                        label="Slug"
                                        onChange={handleSlugChange}
                                        helpText={slugSubmit}
                                        value={slug}
                                        error={errorHandler('slug')}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Classic Center"
                                        options={classics}
                                        onChange={classicChange}
                                        value={selectedClassicCenter}
                                        placeholder="Select"
                                    />
                                    <Select
                                        label="Precision Center"
                                        options={precision}
                                        onChange={precisionChange}
                                        value={selectedPrecisionCenter}
                                        placeholder="Select"
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            {success ? (<Frame><Toast content="Year Created" onDismiss={toggleToast} /></Frame>) : null}
        </Page>
    )
};

export default YearCreate;

