import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Toast,
    InlineError,
    DisplayText,
    Frame,
    ChoiceList,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

const getCenter = async ({ setPageState, pageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/centers/${id}`,
            method: 'GET',
        });
        if (data) {
            return setPageState({
                series: [data.series],
                center_type: [data.center_type],
                center_code: data.center_code,
                loading: false,
                errors: []
            });
        }
        return setPageState({
            ...pageState,
            loading: false,
            errors: []
        });
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateCenter = async ({ setPageState, pageState, id }) => {
    try {
        const { series, center_code, center_type } = pageState;
        const [firstSeries] = series;
        const [firstType] = center_type;

        const { data } = await Axios({
            url: `/api/centers/${id}`,
            method: 'PUT',
            data: {
                series: firstSeries,
                center_type: firstType,
                center_code,
            }
        });

        if (data) {
            return setPageState({
                series: [data.series],
                center_type: [data.center_type],
                center_code: data.center_code,
                loadingUpdate: false,
                success: true,
                errors: []
            });
        }
        return setPageState({
            ...pageState,
            loadingUpdate: false,
            success: false,
            errors: []
        });
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingUpdate: false,
            errors
        });
    }
};

const CentersDetail = (props) => {

    const [pageState, setPageState] = useState({
        series: [],
        center_type: [],
        center_code: '',
        loading: true,
        loadingUpdate: false,
        success: false,
        errors: []
    });

    const {
        series,
        center_code,
        center_type,
        loading,
        loadingUpdate,
        success,
        errors,
    } = pageState;

    useEffect(() => {
        const { match: { params } } = props;
        console.log(params);
        if (pageState.loading) {
            (getCenter)({
                pageState,
                setPageState,
                id: params.centerId
            });
        }

        if(pageState.loadingUpdate){
            (updateCenter)({
                pageState,
                setPageState,
                id: params.centerId
            });
        }
    }, [
        props,
        loading,
        pageState,
    ]);

    const handleCodeChange = (value) => setPageState({
        ...pageState,
        center_code: value,
    });

    const handleSeriesChange = (value) => setPageState({
        ...pageState,
        series: value,
    });

    const handleTypeChange = (value) => setPageState({
        ...pageState,
        center_type: value,
    });

    const submitForm = () => setPageState({
        ...pageState,
        loadingUpdate: true,
    });

    const toggleToast = () => setPageState({
        ...pageState,
        success: false,
    });

    const errorHandler = (field) => {
        if (errors.length) {
            const found = errors.find(({ path }) => path === field);
            if (found) {
                return { error: true, message: found.message };
            }
        }
        return { error: false, message: null };
    };

    const codeError = errorHandler('center_code');

    const seriesChoices = [
        {label: 'Classic', value: 'Classic'},
        {label: 'Precision', value: 'Precision'},
        {label: 'Embark', value: 'Embark'},
    ];

    const typeChoices = [
        {label: 'Front', value: 'front'},
        {label: 'Rear', value: 'rear'},
    ];

    return (
        <Page
            title="Edit"
            breadcrumbs={[{
                content: 'Centers',
                url: '/app/settings/centers'
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Edit Center
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Save',
                            onClick: submitForm,
                            loading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group>
                                    <ChoiceList
                                        title="Series"
                                        choices={seriesChoices}
                                        selected={series}
                                        onChange={handleSeriesChange}
                                        allowMultiple={false}
                                        disabled={loadingUpdate}
                                    />
                                    <ChoiceList
                                        title="Bumper Type"
                                        choices={typeChoices}
                                        selected={center_type}
                                        onChange={handleTypeChange}
                                        allowMultiple={false}
                                        disabled={loadingUpdate}
                                    />
                                </FormLayout.Group>
                                <TextField
                                    type="text"
                                    label="Code"
                                    onChange={handleCodeChange}
                                    value={center_code}
                                    error={codeError.error}
                                    disabled={loadingUpdate}
                                />
                                {codeError.error ?
                                    <div style={{marginTop: '4px'}}>
                                        <InlineError message={codeError.message} fieldID={'center_code'} />
                                    </div>
                                : null}
                            </FormLayout>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            {success ? (<Frame><Toast content="Center Saved" onDismiss={toggleToast} /></Frame>) : null}
        </Page>
    )
};

export default CentersDetail;

