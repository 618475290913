import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Layout,
    Banner,
    Heading,
    Stack,
    Select,
    Frame,
    Sheet,
    Scrollable,
    Button,
    Spinner,
    Icon,
    FormLayout,
} from "@shopify/polaris"

import { MobileCancelMajorMonotone } from "@shopify/polaris-icons"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const SpinnerWrap = styled.div`
    display: ${({ displaySpinner }) => displaySpinner ? `flex` : `none`};
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.4);
`;

const updateFabricator = async ( {
    id,
    status,
    setErrors,
    setLoading,
    statusState,
    declineReasons,
    selectedReason,
    setOpenModel,
    setStatusState,
    componentError,
} ) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}`,
            method: 'PUT',
            data: {
                status: statusState
            }
        });
        if (data) {
            await setStatusState(data.status);
        }
        if(data && data.status === 'inactive'){
            const selected = declineReasons.find(({ name }) => name === selectedReason);
            await Axios({
                url: `/api/fabricators/${id}/decline-reasons/${selected.id}/send`,
                method: 'POST'
            });
        }
        await setErrors([]);
        await setOpenModel(false);
        await setLoading(false);
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];

        await setLoading(false);
        await setOpenModel(false);
        await setStatusState(status);
        await componentError(errors);
    }
};

const Status = ({ id, status, declineReasons, componentError }) => {

    const [statusState, setStatusState] = useState(status);
    const [loading, setLoading] = useState(false);

    const [selectedReason, setSelectedReason] = useState('');
    const [errors, setErrors] = useState([]);
    const [openModel, setOpenModel] = useState(false);

    useEffect(() => {
         if (loading) {
            (updateFabricator)({
                id,
                status,
                statusState,
                setLoading,
                setErrors,
                declineReasons,
                selectedReason,
                setOpenModel,
                setStatusState,
                componentError,
            })
         }
    }, [
        id,
        status,
        loading,
        statusState,
        selectedReason,
        declineReasons,
        componentError
    ]);

    const statusOptions = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
    ];

    const onSelectChange = (value) => {
        setStatusState(value);
        setOpenModel(true);
    };

    const options = declineReasons
        .map(({ name }) => ({ label: name, value: name }));

    const isActive = statusState === 'active';

    return (
        <FormLayout>
            <ErrorMessages errors={errors}/>
            <Select
                options={statusOptions}
                onChange={onSelectChange}
                value={statusState}
            />
            <Frame>
                <Sheet open={openModel} onClose={() => [setOpenModel(false),setStatusState(status)]}>
                    <SheetWrap>
                        <SpinnerWrap displaySpinner={loading}>
                            <Spinner size="large" color="teal" />
                        </SpinnerWrap>
                        <SheetHeader>
                            <Stack distribution="equalSpacing">
                                <Icon source={!isActive ? "alert" : "checkmark"} color="red" backdrop={true}/>
                                <Heading>Set To {!isActive ? `Inactive` : `Active`}</Heading>
                                <Button
                                    accessibilityLabel="Cancel"
                                    icon={MobileCancelMajorMonotone}
                                    onClick={() => [setOpenModel(false),setStatusState(status)]}
                                    plain
                                />
                            </Stack>
                        </SheetHeader>
                        <StyledScrollable>
                            <Layout>
                                <Layout.Section>
                                    <Banner
                                        status={!isActive ? `warning` : `info`}
                                    >
                                        {!isActive ? (
                                            <p>Are you sure you want to set this account to inactive?</p>
                                        ) : (
                                            <p>Are you sure you want to set this account to active?</p>
                                        )}
                                    </Banner>
                                    <br/>
                                    {!isActive ? (
                                        <Select
                                            placeholder="Select A Reason"
                                            label="Decline Reasons"
                                            options={options}
                                            onChange={value => setSelectedReason(value)}
                                            value={selectedReason}
                                        />
                                    ) : null}
                                </Layout.Section>
                            </Layout>
                        </StyledScrollable>
                        <SheetFooter>
                            <Button onClick={() => [setOpenModel(false),setStatusState(status)]}>Cancel</Button>
                            <Button
                                destructive={!isActive}
                                primary={isActive}
                                onClick={() => setLoading(true)}
                                disabled={!isActive && selectedReason === ''}
                            >
                                {isActive ? `Set To Active` : `Set To Inactive`}
                            </Button>
                        </SheetFooter>
                    </SheetWrap>
                </Sheet>
            </Frame>
        </FormLayout>
    )
}

export default Status;

