import React, { useState, useEffect, useCallback } from "react"
import Axios from "axios"
import {
    Card,
    List,
    Caption,
    Spinner,
    Heading,
    DropZone,
    Banner,
    Toast,
} from "@shopify/polaris"

import ErrorMessages from "../ErrorMessages"

const getAwsUrl = async ({
    id,
    file,
    name,
    type,
    setUrl,
    setAwsUrl,
    imageCreate,
    setImageCreate
}) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/images/aws?name=${file.name}&type=${file.type}`,
            method: 'GET'
        });
        if (data) {
            setAwsUrl(data.signedRequest);
            setUrl(data.url);
            setImageCreate({
                ...imageCreate,
                loading: true,
                error: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ? error.response.data
            : [];
        setImageCreate({
            ...imageCreate,
            loading: false,
            errors,
        });
    }
};

const createImage = async ({
    id,
    url,
    file,
    reset,
    awsUrl,
    toggleActive,
    imageCreate,
    setImageCreate
}) => {
    try {
        console.log(awsUrl);
        const response = await Axios({
            url: awsUrl,
            method: 'PUT',
            data: file
        });
        if (response) {
            const { data } = await Axios({
                url: `/api/fabricators/${id}/images`,
                method: 'POST',
                data: { url }
            });
            if (data) {
                setImageCreate({
                    ...imageCreate,
                    loading: false,
                    error: []
                });
                toggleActive();
                reset(true);
            }
        }
    } catch (error) {
        console.log(error);
        const errors = error.response && error.response.data
            ? error.response.data
            : [error.message];
        return setImageCreate({
            ...imageCreate,
            loading: false,
            errors
        });
    }
};
const ImageUpload = ({ id, reset }) => {
    const [imageCreate, setImageCreate] = useState({
        loading: false,
        errors: []
    });
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState(null);
    const [awsUrl, setAwsUrl] = useState(null);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const hasError = rejectedFiles.length > 0;
    const [active, setActive] = useState(false);

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const toastMarkup = active ? (
      <Toast content="Image Added" onDismiss={toggleActive} />
    ) : null;

    useEffect(() => {
        if (file) {
            (getAwsUrl)({
                id,
                file,
                setUrl,
                setAwsUrl,
                imageCreate,
                setImageCreate,
            });
        }
    }, [file]);

    useEffect(() => {
        if (imageCreate.loading && url && file) {
            (createImage)({
                id,
                url,
                file,
                reset,
                awsUrl,
                toggleActive,
                imageCreate,
                setImageCreate,
            });
        }
    }, [imageCreate.loading]);


    

    const handleDrop = useCallback(
        (_droppedFiles, acceptedFiles, rejectedFiles) => {
            setFile(acceptedFiles.length ? acceptedFiles[0] : null);
            setRejectedFiles(rejectedFiles);
        },
        [],
    );

    const fileUpload = !file && <DropZone.FileUpload />;

    const errorMessage = hasError && (
        <Banner
            title="The following images couldn’t be uploaded:"
            status="critical"
        >
            <List type="bullet">
                {rejectedFiles.map((file, index) => (
                    <List.Item key={index}>
                        {`"${file.name}" is not supported. File type must be .gif, .jpg, .png or .svg.`}
                    </List.Item>
                ))}
            </List>
        </Banner>
    );

    return (
        <>
            <Card
                sectioned
                title={<Heading>Upload Image</Heading>}
            >
                {errorMessage}
                <DropZone accept="image/*" type="image" onDrop={handleDrop}>
                    {imageCreate.loading ? (
                        <Spinner accessibilityLabel="Spinner example" size="large" color="teal" />
                    ) : (
                        <DropZone.FileUpload />
                    )}
                </DropZone>
            </Card>
            {toastMarkup}
        </>
    )
}

export default ImageUpload;

