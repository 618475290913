import '@shopify/polaris/styles.css';

import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import URLSearchParams from 'url-search-params';
import { AppProvider } from '@shopify/polaris'
import * as serviceWorker from './serviceWorker'
import RouterLink from './components/RouterLink'

const query = new URLSearchParams(window.location.search);
const shop = `${query.get('shop')}`;
const API_KEY = process.env.NODE_ENV === 'development'
    ? 'b07f84c260e257a9698d537ffbca3e5d'
    : '10cbfb672145200bd1e3b8f02dfd3400';
const isIFrame = window.location !== window.parent.location;
console.log(API_KEY);
ReactDOM.render(
    <AppProvider
        apiKey={API_KEY}
        shopOrigin={shop}
        forceRedirect={true}
        linkComponent={RouterLink}
        debug='true'
    >
        { isIFrame ? <App /> : <div></div> }
    </AppProvider>,
document.getElementById('root'));
serviceWorker.unregister();
