import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import PropTypes from "prop-types";

//  Global
import Dashboard from "./containers/Dashboard";
import Settings from "./containers/Settings";
import PageError from "./containers/PageError";
import Navigation from "./components/Navigation";

//  Service
import ServiceList from "./containers/services/ServiceList";
import ServiceCreate from "./containers/services/ServiceCreate";
import ServiceDetail from "./containers/services/ServiceDetail";

//  Equipment
import EquipmentList from "./containers/equipment/EquipmentList";
import EquipmentCreate from "./containers/equipment/EquipmentCreate";
import EquipmentDetail from "./containers/equipment/EquipmentDetail";

//  Decline Reason
import DeclineReasonList from "./containers/decline_reason/DeclineReasonList";
import DeclineReasonCreate from "./containers/decline_reason/DeclineReasonCreate";
import DeclineReasonDetail from "./containers/decline_reason/DeclineReasonDetail";

//  Fabricators
import FabricatorList from "./containers/fabricators/FabricatorList";
import FabricatorDetail from "./containers/fabricators/FabricatorDetail";

//  Reviews
import ReviewList from "./containers/reviews/ReviewList";
import Reviews from "./containers/Reviews";

//  Reviews
import ServiceRequestList from "./containers/service_requests/ServiceRequestList";

// Makes
import MakesList from "./containers/makes/MakesList";
import MakeDetail from "./containers/makes/MakeDetail";
import MakeCreate from "./containers/makes/MakeCreate";

//  Models
import ModelsList from "./containers/models/ModelsList";
import ModelDetail from "./containers/models/ModelDetail";
import ModelCreate from "./containers/models/ModelCreate";

//  Years
import YearsList from "./containers/years/YearsList";
import YearCreate from "./containers/years/YearCreate";
import YearDetail from "./containers/years/YearDetail";

//  Centers
import CentersList from "./containers/centers/CentersList";
import CentersCreate from "./containers/centers/CentersCreate";
import CentersDetail from "./containers/centers/CentersDetail";

//  Products
import ProductConfigurator from "./containers/products/ProductConfigurator";

//  AdminLinks
import AdminLinks from "./containers/admin-links/AdminLinks";

import { RoutePropagator } from "@shopify/react-shopify-app-route-propagator";

//  App routes and components
const routes = [
  {
    path: "/app",
    RouteComponent: Dashboard,
  },
  {
    path: "/app/settings",
    RouteComponent: Settings,
  },
  {
    path: "/app/settings/admin-links",
    RouteComponent: AdminLinks,
  },
  {
    path: "/app/reviews",
    RouteComponent: Reviews,
  },
  {
    path: "/app/settings/services",
    RouteComponent: ServiceList,
  },
  {
    path: "/app/settings/services/new",
    RouteComponent: ServiceCreate,
  },
  {
    path: "/app/settings/services/:serviceId",
    RouteComponent: ServiceDetail,
  },
  {
    path: "/app/settings/equipment",
    RouteComponent: EquipmentList,
  },
  {
    path: "/app/settings/equipment/new",
    RouteComponent: EquipmentCreate,
  },
  {
    path: "/app/settings/equipment/:equipmentId",
    RouteComponent: EquipmentDetail,
  },
  {
    path: "/app/settings/decline-reasons",
    RouteComponent: DeclineReasonList,
  },
  {
    path: "/app/settings/decline-reasons/new",
    RouteComponent: DeclineReasonCreate,
  },
  {
    path: "/app/settings/decline-reasons/:declineReasonId",
    RouteComponent: DeclineReasonDetail,
  },
  {
    path: "/app/settings/makes",
    RouteComponent: MakesList,
  },
  {
    path: "/app/settings/makes/new",
    RouteComponent: MakeCreate,
  },
  {
    path: "/app/settings/makes/:makeId",
    RouteComponent: MakeDetail,
  },
  {
    path: "/app/settings/models",
    RouteComponent: ModelsList,
  },
  {
    path: "/app/settings/models/new",
    RouteComponent: ModelCreate,
  },
  {
    path: "/app/settings/models/:modelId",
    RouteComponent: ModelDetail,
  },
  {
    path: "/app/settings/years",
    RouteComponent: YearsList,
  },
  {
    path: "/app/settings/years/new",
    RouteComponent: YearCreate,
  },
  {
    path: "/app/settings/years/:yearId",
    RouteComponent: YearDetail,
  },
  {
    path: "/app/fabricators",
    RouteComponent: FabricatorList,
  },
  {
    path: "/app/fabricators/:fabricatorId",
    RouteComponent: FabricatorDetail,
  },
  {
    path: "/app/fabricators/:fabricatorId/reviews",
    RouteComponent: ReviewList,
  },
  {
    path: "/app/fabricators/:fabricatorId/service-requests",
    RouteComponent: ServiceRequestList,
  },
  {
    path: "/app/settings/product/configure",
    RouteComponent: ProductConfigurator,
  },
  {
    path: "/app/settings/centers",
    RouteComponent: CentersList,
  },
  {
    path: "/app/settings/centers/new",
    RouteComponent: CentersCreate,
  },
  {
    path: "/app/settings/centers/:centerId",
    RouteComponent: CentersDetail,
  },
];

class App extends Component {
  static contextTypes = {
    polaris: PropTypes.object,
  };

  render() {
    const { appBridge } = this.context.polaris;
    return (
      <Router>
        <Switch>
          {routes.map(({ path, RouteComponent }, i) => (
            <Route
              key={`route-${i + 1}`}
              exact
              path={path}
              render={(props) => (
                <>
                  <RoutePropagator location={path} app={appBridge} />
                  <Navigation app={appBridge} {...props} />
                  <RouteComponent app={appBridge} {...props} />
                </>
              )}
            />
          ))}
          <Route
            render={(props) => (
              <>
                <Navigation app={appBridge} {...props} />
                <PageError app={appBridge} {...props} />
              </>
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
