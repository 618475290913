import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Button,
    Collapsible,
    Layout,
    ResourceList,
    TextStyle,
    Frame,
    Card,
    Modal,
} from "@shopify/polaris"
import styled from "styled-components"

import YearSheet from "./YearSheet"

import ErrorMessages from "../ErrorMessages"

const PageBanner = styled.h3`
    display: inline-block;
    vertical-align: middle;
`;

const getYears = async ({ pageState, setPageState, modelId }) => {
    try {
        const { data } = await Axios({
            url: `/api/models/${modelId}/years`,
            method: 'GET'
        });
        if (data) {
            setPageState({
                ...pageState,
                years: data,
                selectedYear: null,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const deleteYear = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/years/${pageState.selectedYear.id}`,
            method: 'DELETE'
        });
        if (data) {
            setPageState({
                ...pageState,
                years: pageState.years.filter((year) => year.id !== pageState.selectedYear.id),
                selectedYear: null,
                deleteLoading: false,
                deleteModelOpen: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            deleteLoading: false,
            deleteModelOpen: false,
            errors
        });
    }
};

const ModelYearItems = ({ modelId }) => {

    const [pageState, setPageState] = useState({
        open: false,
        loading: true,
        deleteLoading: false,
        openYearSheet: false,
        years: [],
        selectedYear: null,
        deleteModelOpen: false,
        yearAction: null
    });

    useEffect(() => {
        if (pageState.loading) {
            (getYears)({ pageState, setPageState, modelId })
        }
        if (pageState.deleteLoading) {
            (deleteYear)({ pageState, setPageState })
        }
    }, [
        pageState.openYearSheet,
        pageState.open,
        pageState.deleteLoading,
        modelId,
        pageState,
        pageState.loading
    ]);

    const handleOpen = () => setPageState({
        ...pageState,
        open: !pageState.open
    });

    const handleCloseYearSheet = () => setPageState({
        ...pageState,
        openYearSheet: false,
        loading: true,
        yearAction: null,
        selectedYear: null
    });

    const handleOpenYearSheet = () => setPageState({
        ...pageState,
        openYearSheet: true,
        yearAction: 'create'
    });

    const handleOpenYearSheetUpdate = (selectedId) => setPageState({
        ...pageState,
        openYearSheet: true,
        yearAction: 'update',
        selectedYear: pageState.years.find(({ id }) => id === selectedId)
    });

    const handleDeleteModel = ({ id, year }) => setPageState({
        ...pageState,
        deleteModelOpen: true,
        selectedYear: { id, year }
    });

    const handleDelete = ({ id, year }) => setPageState({
        ...pageState,
        deleteLoading: true
    });

    const handleDeleteCancel = () => setPageState({
        ...pageState,
        deleteModelOpen: false,
        open: true
    });

    const handleDeleteClose = () => setPageState({
        ...pageState,
        deleteModelOpen: false,
        open: true
    });

    const renderItem = ({ id, year }) => {
        return (
            <ResourceList.Item
                id={id}
                accessibilityLabel={`View details for ${year}`}
                shortcutActions={[
                    {
                        content: 'manage',
                        url: `/app/settings/years/${id}`
                    },
                    {
                        content: 'edit',
                        onClick: () => handleOpenYearSheetUpdate(id),
                    },
                    {
                        content: 'delete',
                        onClick: () => handleDeleteModel({ id, year }),
                        destructive: true
                    }
                ]}
            >
                <PageBanner>
                    <TextStyle variation="strong">{year}</TextStyle>
                </PageBanner>
            </ResourceList.Item>
        );
    };

    const {
        open,
        years,
        openYearSheet,
        loading,
        deleteModelOpen,
        deleteLoading,
        selectedYear,
        yearAction,
        errors
    } = pageState;

    return (
        <>
            <ErrorMessages errors={errors}/>
            <Layout>
                <Layout.Section>
                    <Button
                        plain
                        onClick={handleOpen}
                    >
                        Years ({years.length})
                    </Button>
                </Layout.Section>
                <Layout.Section>
                    <Collapsible
                        open={open}
                        id="modelYears"
                    >
                        <Card
                            title={`Years`}
                            actions={[{
                                content: 'Create Year',
                                onClick: handleOpenYearSheet
                            }]}
                        >
                            <Card.Section>
                                <ResourceList
                                    resourceName={{
                                        singular: 'year',
                                        plural: 'years'
                                    }}
                                    items={years}
                                    renderItem={renderItem}
                                    loading={loading || deleteLoading}
                                />
                            </Card.Section>
                        </Card>
                        <br/>
                    </Collapsible>
                </Layout.Section>
            </Layout>
            <Frame>
                <Modal
                    open={deleteModelOpen}
                    onClose={handleDeleteClose}
                    title={`Remove Year`}
                    message={`Are you sure you want to DELETE this vehicle year?`}
                    primaryAction={{
                        content: "Delete",
                        onAction: handleDelete,
                        destructive: true
                    }}
                    secondaryActions={[{
                        content: 'Cancel',
                        onAction: handleDeleteCancel,
                    }]}
                >
                </Modal>
                <YearSheet
                    id={modelId}
                    open={openYearSheet}
                    selectedYear={selectedYear}
                    handleCloseSheet={handleCloseYearSheet}
                    yearAction={yearAction}
                />
            </Frame>
        </>
    )
};

export default ModelYearItems;

