import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { Card, Collapsible, TextStyle } from "@shopify/polaris";

const ReviewModeration = ({
  id,
  rating,
  reload,
  message,
  approved,
  user_name,
  moderated,
  user_email,
  FabricatorId,
  updateComplete,
  setToasting,
  setToastingMessage,
}) => {
  const [openState, setOpenState] = useState(false);
  const [reviewModerated, setReviewModerated] = useState(false);
  const [moderationResult, setModerationResult] = useState("");
  const [deleteReview, setDeleteReview] = useState(false);
  const name = moderated ? `Review by ${user_name}` : "New Posted Review";
  useEffect(() => {
    if (deleteReview) {
      removeReview({ id, FabricatorId });
    }
  }, [deleteReview]);

  useEffect(() => {
    if (reviewModerated) {
      moderateReview({ id: id, result: moderationResult });
    }
  }, [moderationResult]);

  const removeReview = async ({ id, FabricatorId }) => {
    try {
      const response = await Axios({
        url: `/api/fabricators/${FabricatorId}/reviews/${id}`,
        method: "DELETE",
      });
      if (response && response.status === 200) {
        setToastingMessage("Review Deleted permanently");
        setToasting(true);
      }
      reload(true);
      return updateComplete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const moderateReview = async ({ id, result }) => {
    try {
      const response = await Axios({
        url: `/api/reviews/${id}/moderate?result=${result}`,
        method: "PUT",
      });
      if (response && response.status === 200) {
        setToastingMessage("Review updated");
        setToasting(true);
      }
      reload();
      return updateComplete();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeny = useCallback(() => {
    setModerationResult("deny");
    return setReviewModerated(true);
  }, []);
  const handleApprove = useCallback(() => {
    setModerationResult("accept");
    return setReviewModerated(true);
  }, []);
  const handleDelete = useCallback(() => setDeleteReview(true));
  return (
    <Card
      title={name}
      actions={[
        {
          content: `${openState ? "Hide" : "View full"} Review `,
          onClick: () => setOpenState(!openState),
        },
      ]}
      secondaryFooterActions={
        moderated
          ? [
              {
                destructive: true,
                content: "Delete Review",
                onClick: handleDelete,
              },
            ]
          : [
              {
                outline: true,
                content: "Deny Review",
                onClick: handleDeny,
              },
            ]
      }
      primaryFooterAction={
        moderated && approved
          ? {
              content: "Hide Review",
              onClick: handleDeny,
            }
          : {
              content: "Approve Review",
              onClick: handleApprove,
            }
      }
    >
      <Card.Section>
        <Collapsible open={openState} id="moderateReviewCollapsible">
          <h3>
            <TextStyle variation="strong">User Name: {user_name}</TextStyle>
          </h3>
          <br />
          <div>User Email: {user_email}</div>
          <br />
          <p>Review Message: {message}</p>
          <br />
          <p>Rating: {rating}</p>
          <br />
        </Collapsible>
        {approved ? (
          <div style={{ display: "flex" }}>
            <p style={{ paddingRight: "15px" }}>🟢 </p>
            <p>Currently active</p>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <p style={{ paddingRight: "15px" }}>🔴 </p>
            <p>Currently inactive</p>
          </div>
        )}
      </Card.Section>
    </Card>
  );
};

export default ReviewModeration;
