import React from "react"
import {
    Page,
    Layout,
    FooterHelp,
    CalloutCard,
    DisplayText
} from "@shopify/polaris"

import CreateFile from "../components/settings/CreateFile";

const Settings = (props) => {
    return (
        <Page
            title="Settings"
        >
            <DisplayText
                size="large"
                element="h2"
            >
                Settings
            </DisplayText>
            <br/>
            <CreateFile></CreateFile>
            <br/>
            <Layout>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Services"
                        illustration="/images/gear.png"
                        primaryAction={{
                            content: 'List',
                            url: '/app/settings/services',
                            primary: true
                        }}
                        secondaryAction={{
                            content: 'Create',
                            url: '/app/settings/services/new',
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Equipment"
                        illustration="/images/auto-service.svg"
                        primaryAction={{
                            content: 'List',
                            url: '/app/settings/equipment',
                            primary: true
                        }}
                        secondaryAction={{
                            content: 'Create',
                            url: '/app/settings/equipment/new',
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Emails"
                        illustration="/images/email.png"
                        primaryAction={{
                            content: 'List',
                            url: '/app/settings/decline-reasons',
                            primary: true
                        }}
                        secondaryAction={{
                            content: 'Create',
                            url: '/app/settings/decline-reasons/new',
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Makes"
                        illustration="/images/makes.png"
                        primaryAction={{
                            content: 'List',
                            url: '/app/settings/makes',
                            primary: true
                        }}
                        secondaryAction={{
                            content: 'Create',
                            url: '/app/settings/makes/new',
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Models"
                        illustration="/images/truck.png"
                        primaryAction={{
                            content: 'List',
                            url: '/app/settings/models',
                            primary: true
                        }}
                        secondaryAction={{
                            content: 'Create',
                            url: '/app/settings/models/new',
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Years"
                        illustration="/images/calendar.png"
                        primaryAction={{
                            content: 'List',
                            url: '/app/settings/years',
                            primary: true
                        }}
                        secondaryAction={{
                            content: 'Create',
                            url: '/app/settings/years/new',
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Configure Products"
                        illustration="/images/shopify.png"
                        primaryAction={{
                            content: 'Configure',
                            url: '/app/settings/product/configure',
                            primary: true
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird>
                    <CalloutCard
                        title="Configure Centers"
                        illustration="/images/shopify.png"
                        primaryAction={{
                            content: 'Configure',
                            url: '/app/settings/centers',
                            primary: true
                        }}
                    ></CalloutCard>
                </Layout.Section>
                <Layout.Section oneThird></Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    )
};

export default Settings;

