import Axios from "axios";
import React, { useState, useCallback, useEffect } from "react";
import {
  Page,
  Card,
  Toast,
  Frame,
  Layout,
  Heading,
  Pagination,
  ResourceList,
} from "@shopify/polaris";
import ReviewModeration from "../components/reviews/ReviewModeration";

const Reviews = () => {
  const [limit] = useState(3);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [newReviews, setNewReviews] = useState([]);
  const [toasting, setToasting] = useState(false);
  const [loadingErrors, setLoadingErrors] = useState(true);
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [loadingNewReviews, setLoadingNewReviews] = useState(true);
  const [toastingMessage, setToastingMessage] = useState(false);

  useEffect(() => {
    getReviews({ limit: limit, page: page });
  }, [loadingReviews]);

  useEffect(() => {
    getNewReviews();
  }, [loadingNewReviews]);

  const getReviews = async (options) => {
    try {
      const response = await Axios({
        url: "/api/reviews",
        method: "GET",
        params: options,
      });
      if (response && response.data) {
        setReviews(response.data);
        setCount(response.data.length);
      }
      return setLoadingReviews(false);
    } catch (error) {
      setError(error);
    }
  };

  const getNewReviews = async () => {
    try {
      const response = await Axios({
        url: "/api/reviews/new",
        method: "GET",
      });
      if (response && response.data) {
        setNewReviews(response.data);
      }
      return setLoadingNewReviews(false);
    } catch (error) {
      setError(error);
    }
  };
  const handleNext = useCallback(() => {
    setPage(page + 1);
    setLoadingErrors(true);
  }, [page]);
  const handlePrevious = useCallback(() => {
    setPage(page > 1 ? page - 1 : 1);
    setLoadingErrors(true);
  }, [page]);

  const toggleActive = useCallback(
    () => setToasting((toasting) => !toasting),
    []
  );
  const toastMarkup = toasting ? (
    <Toast
      content={toastingMessage}
      onDismiss={toggleActive}
      error={error !== null}
    />
  ) : null;
  const hasNext = count / limit > page;
  const hasPrevious = page > 1;
  return (
    <Frame>
      <Page title="Reviews Moderation">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <div>
                <Heading element="h1">Section Overview</Heading>{" "}
              </div>
              <p style={{ margin: "1.5%" }}>
                This section lists all the reviews and displays any new incoming
                review at the top. Click 'View full Review' to open the dropdown
                menu to see the review details. A review can be approved, denied
                or deleted. Once a review is deleted, it is removed permanently
                from the database. If an incoming review is denied, it wont be
                displayed on the website but will still remain in the app's
                database and can be later approved. Once a review is approved,
                it will be displayed on the website and visible to everyone. An
                approved review can later be hidden from the customers by
                clicking the 'Hide Review' button in the Moderated Reviews
                section
              </p>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: "1%",
                  paddingTop: "2%",
                }}
              >
                <Heading element="h1">New Reviews</Heading>{" "}
              </div>
              {newReviews.length > 0 ? (
                <ResourceList
                  resourceName={{ singular: "review", plural: "reviews" }}
                  items={newReviews}
                  renderItem={(item) => {
                    const {
                      id,
                      rating,
                      message,
                      user_name,
                      user_email,
                      FabricatorId,
                    } = item;

                    return (
                      <ReviewModeration
                        id={id}
                        rating={rating}
                        message={message}
                        user_name={user_name}
                        user_email={user_email}
                        setToasting={setToasting}
                        reload={setLoadingReviews}
                        FabricatorId={FabricatorId}
                        updateComplete={setLoadingNewReviews}
                        setToastingMessage={setToastingMessage}
                      />
                    );
                  }}
                />
              ) : (
                <p style={{ margin: "1.5%", textAlign: "center" }}>
                  No new review has been posted
                </p>
              )}
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <div
                style={{
                  textAlign: "center",
                  paddingBottom: "1%",
                  paddingTop: "2%",
                }}
              >
                <Heading element="h1">All Moderated Reviews</Heading>{" "}
              </div>
              <ResourceList
                resourceName={{ singular: "review", plural: "reviews" }}
                items={reviews}
                renderItem={(item) => {
                  const {
                    id,
                    rating,
                    message,
                    approved,
                    moderated,
                    user_name,
                    user_email,
                    FabricatorId,
                  } = item;
                  return (
                    <ReviewModeration
                      id={id}
                      rating={rating}
                      message={message}
                      approved={approved}
                      moderated={moderated}
                      user_name={user_name}
                      user_email={user_email}
                      setToasting={setToasting}
                      reload={setLoadingReviews}
                      FabricatorId={FabricatorId}
                      updateComplete={setLoadingNewReviews}
                      setToastingMessage={setToastingMessage}
                    />
                  );
                }}
              />
            </Card>
            <br />
            <div style={{ textAlign: "center" }}>
              {/* <Pagination
                label="Reviews"
                hasPrevious={hasPrevious}
                onPrevious={handlePrevious}
                hasNext={hasNext}
                onNext={handleNext}
              /> */}
            </div>
          </Layout.Section>
        </Layout>
        {toastMarkup}
      </Page>
    </Frame>
  );
};

export default Reviews;
