import React from 'react'
import { RoutePropagator } from '@shopify/react-shopify-app-route-propagator'

const ShopifyHistory = (props) => props.app && props.location ? (
    <>
        <RoutePropagator location={props.location} app={props.app} />
    </>
) : null;

export default ShopifyHistory;

