import React, { useState, useEffect} from "react"
import Axios from "axios";
import {
    Page,
    ResourceList,
    Layout,
    TextStyle,
    Card,
    FooterHelp,
    EmptyState,
    Avatar,
    Badge,
    DisplayText,
    Button,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

const getEquipment = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/equipment`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                equipment: data,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const EquipmentList = (props) => {

    const [pageState, setPageState] = useState({
        equipment: [],
        loading: true,
        errors: []
    });

    const {
        equipment,
        loading,
        errors
    } = pageState;

    useEffect(() => {
        if (pageState.loading) {
            (getEquipment)({ pageState, setPageState });
        }
    }, [pageState]);

    const renderItem = ({ id, name, status }) => (
        <ResourceList.Item
            id={id}
            url={`/app/settings/equipment/${id}`}
            media={<Avatar customer name={name} source="/images/auto-service.svg"/>}
        >
            <h3>
                <TextStyle variation="strong">{name}</TextStyle>
            </h3>
            {status ? <Badge status="success">Active</Badge> : null}
        </ResourceList.Item>
    );

    return (
        <Page
            title="Equipment"
            breadcrumbs={[{
                content: 'Settings',
                url: '/app/settings'
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Equipment
                        <Button
                            primary
                            size="slim"
                            url={`/app/settings/equipment/new`}
                        >
                            Create
                        </Button>
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card>
                        <ResourceList
                            resourceName={{
                                singular: 'equipment',
                                plural: 'equipment'
                            }}
                            items={equipment}
                            renderItem={renderItem}
                            loading={loading}
                            showHeader={true}
                        />
                        {!equipment.length && !loading ?
                            <EmptyState
                                heading="Configure Equipment"
                                action={{
                                    content: 'Create Equipment',
                                    url: `/app/settings/equipment/new`
                                }}
                                image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                            >
                                <p>Set up equipment listings for your fabricators to use.</p>
                            </EmptyState>
                        : null}
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    )
};

export default EquipmentList;

