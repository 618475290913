import React, { useState, useEffect, useCallback } from "react";
import { Tabs } from "@shopify/polaris";

import ShopifyHistory from "../components/ShopifyHistory";

const Navigation = (props) => {
  // const [selected, setSelected] = useState(0);
  const [headerState, setHeaderState] = useState({
    selectedd: 0,
  });
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
    if (
      props.location.pathname === "/app/" ||
      props.location.pathname === "/app"
    ) {
      return setHeaderState({
        selected: 0,
      });
    }
    if (
      props.location &&
      props.location.pathname.indexOf("fabricators") !== -1
    ) {
      return setHeaderState({
        selected: 1,
      });
    }
    if (props.location && props.location.pathname.indexOf("settings") !== -1) {
      return setHeaderState({
        selected: 2,
      });
    }
  }, [props]);

  const handleTabChange = (selectedIndex) => {
    console.log(selectedIndex);
    setHeaderState({
      ...headerState,
      selected: selectedIndex,
    });
  };
  // const handleChange = useCallback(
  //   (selectedTabIndex) => setSelected(selectedTabIndex),
  //   []
  // );

  const navigationItems = [
    {
      id: "dashboard",
      content: "Dashboard",
      accessibilityLabel: "dashboard",
      panelID: "dashboard",
      url: "/app",
    },
    {
      id: "fabricators",
      content: "Fabricators",
      panelID: "fabricators",
      url: "/app/fabricators",
    },
    {
      id: "settings",
      content: "Settings",
      panelID: "settings",
      url: "/app/settings",
    },
    {
      id: "reviews",
      content: "Reviews",
      panelID: "reviews",
      url: "/app/reviews",
    },
  ];

  const { selected } = headerState;
  const { app, location } = props;

  return (
    <>
      <ShopifyHistory location={location} app={app} />
      <Tabs
        tabs={navigationItems}
        selected={selected}
        onSelect={handleTabChange}
      />
    </>
  );
};

export default Navigation;
