import React from 'react'
import {
    Page,
    Card,
    Layout,
    TextContainer,
    Banner,
    FooterHelp,
} from '@shopify/polaris'

const PageError = (response) => {
    let error = {
        statusCode: 404,
        message: 'Not Found'
    };
    if (response && response.error && response.error.response) {
        const { error: { response: { data } } } = response;
        error = data;
    }
    return (
        <Page title="Error">
            <Layout>
                <Layout.Section>
                    <Card title={`Error ${error.statusCode || ''}`} sectioned>
                        <TextContainer>
                            <Banner status="critical">
                                <p>{error.message || 'There was a problem'}</p>
                            </Banner>
                        </TextContainer>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    );
}

export default PageError;