import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
    Page,
    ResourceList,
    Layout,
    TextStyle,
    Card,
    FooterHelp,
    Avatar,
    Badge,
    DisplayText,
    FilterType,
    Pagination,
    Button,
} from "@shopify/polaris";

import ErrorMessages from "../../components/ErrorMessages";

const queryBuilder = ({
                          appliedFilters,
                          page,
                          sortValue,
                          searchText,
                          limit,
                      }) => {
    const query = [];
    if ( appliedFilters.length ) {
        appliedFilters.forEach(({ key, value }) => query.push(`${ key }=${ value }`));
    }
    if ( searchText && searchText !== "" ) {
        query.push(`search=${ searchText }`);
    }
    query.push(`page=${ page }`);
    query.push(`sort=${ sortValue }`);
    query.push(`limit=${ limit }`);
    return `?${ query.join("&") }`;
};

const getFabricators = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators${ queryBuilder(pageState) }`,
            method: "GET",
        });
        if ( data ) {
            return setPageState({
                ...pageState,
                fabricators: data.fabricators,
                pages: data.pages,
                loading: false,
                errors: [],
            });
        }
    } catch (error) {
        const errors =
            error.response && error.response.data ? error.response.data : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors,
        });
    }
};

const FabricatorList = (props) => {
    const [ pageState, setPageState ] = useState({
        searchText: "",
        appliedFilters: [],
        sortValue: "DATE_UPDATED_DESC",
        fabricators: [],
        loading: true,
        errors: [],
        page: 1,
        pages: 1,
        limit: 25,
    });

    useEffect(() => {
        if ( pageState.loading ) {
            window.scrollTo(0, 0);
            getFabricators({ pageState, setPageState });
        }
    }, [ pageState.page, pageState.loading, pageState, props ]);

    const handleSearchChange = (searchText) =>
        setPageState({
            ...pageState,
            searchText,
        });

    const handleFiltersChange = (appliedFilters) => {
        let filters = appliedFilters.filter(
            ({ key }) => key !== "images" && key !== "reviews"
        );
        const last = appliedFilters[appliedFilters.length - 1];
        const images = appliedFilters.find(({ key }) => key === "images");
        const reviews = appliedFilters.find(({ key }) => key === "reviews");
        if ( last && last["key"] === "images" ) {
            filters = [ images ];
        }
        if ( last && last["key"] === "reviews" ) {
            filters = [ reviews ];
        }
        return setPageState({
            ...pageState,
            appliedFilters: filters,
            loading: true,
        });
    };

    const handleSortChange = (sortValue) =>
        setPageState({
            ...pageState,
            loading: true,
            sortValue,
        });

    const nextPage = () =>
        setPageState({
            ...pageState,
            loading: true,
            page: page + 1,
        });

    const prevPage = () =>
        setPageState({
            ...pageState,
            loading: true,
            page: page - 1,
        });

    const specificPage = (page) =>
        setPageState({
            ...pageState,
            loading: true,
            page,
        });

    const handleSearch = () => getFabricators({ pageState, setPageState });

    const stateStatus = (state) => {
        let badgeStatus = "";
        switch (state) {
            case "updating":
                badgeStatus = "info";
                break;
            case "pending":
                badgeStatus = "warning";
                break;
            case "approved":
                badgeStatus = "success";
                break;
            case "active":
                badgeStatus = "success";
                break;
            case "inactive":
                badgeStatus = "attention";
                break;
            default:
                break;
        }
        return badgeStatus;
    };

    const renderItem = ({ id, company, state, email, status }) => (
        <ResourceList.Item
            id={ id }
            url={ `/app/fabricators/${ id }` }
            media={ <Avatar customer name={ company } source="/images/welder.jpg" /> }
        >
            <h3>
                <TextStyle variation="strong">{ company }</TextStyle> &nbsp;
                { state ? <Badge status={ stateStatus(state) }>{ state }</Badge> : null }
                { status ? <Badge status={ stateStatus(status) }>{ status }</Badge> : null }
            </h3>
            <p>{ email }</p>
        </ResourceList.Item>
    );

    const filters = [
        {
            key: "status",
            label: "Status",
            operatorText: "is",
            type: FilterType.Select,
            options: [ "active", "inactive" ],
        },
        {
            key: "state",
            label: "State",
            operatorText: "is",
            type: FilterType.Select,
            options: [ "pending", "approved", "updating" ],
        },
        {
            key: "images",
            label: "Images",
            operatorText: "is",
            type: FilterType.Select,
            options: [ "has images" ],
        },
        {
            key: "reviews",
            label: "Reviews",
            operatorText: "is",
            type: FilterType.Select,
            options: [ "has reviews" ],
        },
    ];

    const sortOptions = [
        { label: "Newest updated", value: "DATE_UPDATED_DESC" },
        { label: "Oldest updated", value: "DATE_UPDATED_ASC" },
        { label: "Status ascending", value: "STATUS_ASC" },
        { label: "Status descending", value: "STATUS_DESC" },
        { label: "Company name ascending", value: "COMPANY_ASC" },
        { label: "Company name descending", value: "COMPANY_DESC" },
        { label: "Email ascending", value: "EMAIL_ASC" },
        { label: "Email descending", value: "EMAIL_DESC " },
        { label: "First Name ascending", value: "FIRST_NAME_ASC" },
        { label: "First Name descending", value: "FIRST_NAME_DESC " },
        { label: "Last Name ascending", value: "LAST_NAME_ASC" },
        { label: "Last Name descending", value: "LAST_NAME_DESC " },
    ];

    const {
        fabricators,
        loading,
        appliedFilters,
        searchText,
        sortValue,
        page,
        errors,
        pages,
    } = pageState;
    console.log(pageState.pages);

    console.log(FilterType.Select);
    return (
        <Page title="Fabricators">
            <Layout>
                <Layout.Section>
                    <DisplayText size="large" element="h2">
                        Fabricators
                    </DisplayText>
                    <ErrorMessages errors={ errors } />
                    <br />
                    <Card>
                        <ResourceList
                            resourceName={ {
                                singular: "fabricator",
                                plural: "fabricators",
                            } }
                            items={ fabricators }
                            renderItem={ renderItem }
                            loading={ loading }
                            showHeader={ true }
                            filterControl={
                                <ResourceList.FilterControl
                                    filters={ filters }
                                    appliedFilters={ appliedFilters }
                                    onFiltersChange={ handleFiltersChange }
                                    searchValue={ searchText }
                                    onSearchChange={ handleSearchChange }
                                    additionalAction={ {
                                        content: "Search",
                                        onAction: handleSearch,
                                    } }
                                />
                            }
                            sortValue={ sortValue }
                            sortOptions={ sortOptions }
                            onSortChange={ handleSortChange }
                        />
                    </Card>
                    <br />
                    <div style={ { textAlign: "center" } }>
                        <Pagination
                            hasPrevious={ page !== 1 }
                            onPrevious={ prevPage }
                            hasNext
                            onNext={ nextPage }
                        />
                    </div>
                    <div style={ { textAlign: "center" } }>
                        { new Array(pages).fill({}).map((_, i) => (
                            <Button plain onClick={ () => specificPage(i + 1) }>
                                { i + 1 === page ? <Badge>{ i + 1 }</Badge> : <>{ i + 1 }</> }
                            </Button>
                        )) }
                    </div>
                </Layout.Section>
            </Layout>
            <FooterHelp>Move Bumpers &copy;</FooterHelp>
        </Page>
    );
};

export default FabricatorList;
