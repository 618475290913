import React, { useState, useEffect} from 'react'
import Axios from 'axios';
import {
    Page,
    ResourceList,
    Layout,
    TextStyle,
    Card,
    FooterHelp,
    EmptyState,
    DisplayText,
    Avatar,
    Pagination,
    ButtonGroup,
    Button,
} from '@shopify/polaris'

import ErrorMessages from "../../components/ErrorMessages"

const getMakes = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes`,
            method: 'GET'
        });
        if (data) {
            const {
                makes,
                hasNext,
                hasPrevious
            } = data;
            return setPageState({
                ...pageState,
                makes: makes,
                hasPrevious,
                hasNext,
                loading: false
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const MakesList = (props) => {

    const [pageState, setPageState] = useState({
        makes: [],
        page: 1,
        loading: true,
        hasPrevious: false,
        hasNext: false,
        errors: []
    });

    useEffect(() => {
        if (window) { window.scrollTo(0, 0); }
        if (pageState.loading) {
            (getMakes)({ pageState, setPageState });
        }
    }, [pageState.loading, pageState, props]);

    const nextPage = () => setPageState({
        ...pageState,
        loading: true,
        page: pageState.page + 1
    });

    const prevPage = () => setPageState({
        ...pageState,
        loading: true,
        page: pageState.page - 1
    });

    const renderItem = ({ id, name }) => (
        <ResourceList.Item
            id={id}
            url={`/app/settings/makes/${id}`}
            media={<Avatar customer name={name}/>}
        >
            <h3 style={{"display":"inline-block","verticalAlign":"middle"}}>
                <TextStyle variation="strong">{name}</TextStyle>
            </h3>
        </ResourceList.Item>
    );

    const {
        makes,
        loading,
        hasPrevious,
        hasNext,
        errors
    } = pageState;

    return (
        <Page
            title="Makes"
            breadcrumbs={[{
                content: 'Settings',
                url: `/app/settings`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Makes
                        <ButtonGroup>
                            <Button
                                size="slim"
                                url={`/app/settings/makes/new`}
                                primary
                            >
                                Create
                            </Button>
                        </ButtonGroup>
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card>
                        <ResourceList
                            resourceName={{
                                singular: 'make',
                                plural: 'makes'
                            }}
                            items={makes}
                            renderItem={renderItem}
                            loading={loading}
                            showHeader={true}
                        />
                    </Card>
                    <div style={{ textAlign: 'center' }}>
                        <br/>
                        <Pagination
                            hasPrevious={hasPrevious}
                            onPrevious={prevPage}
                            hasNext={hasNext}
                            onNext={nextPage}
                        />
                    </div>
                    {!makes.length && !loading ?
                        <EmptyState
                            heading="No Makes"
                            image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                        >
                            <p>Mo vehicle makes created.</p>
                        </EmptyState>
                    : null}
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    )
};

export default MakesList;

