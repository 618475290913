import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Card,
    Collapsible,
    Layout,
    Banner,
    Heading,
    Badge,
    Stack,
    Select,
    Frame,
    Sheet,
    Scrollable,
    Button,
    Spinner,
    Icon,
    DescriptionList,
} from "@shopify/polaris"

import { MobileCancelMajorMonotone } from "@shopify/polaris-icons"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const SpinnerWrap = styled.div`
    display: ${({ displaySpinner }) => displaySpinner ? `flex` : `none`};
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.4);
`;

const LogoWrap = styled.div`
    margin: 10px;
    padding: 10px;
`;

const Logo = styled.img`
    width: 100%;
`;

const declineUpdate = async ( {
    id,
    setErrors,
    completeUpdate,
    declineReasons,
    selectedReason,
    setUpdateDeleteState,
} ) => {
    try {
        const selected = declineReasons.find(({ name }) => name === selectedReason);
        await Axios({
            url: `/api/fabricators/${id}/pending-updates/decline/${selected.id}`,
            method: 'DELETE'
        });
        await setErrors([]);
        await setUpdateDeleteState(false);
        completeUpdate();
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        await setUpdateDeleteState(false);
        return setErrors(errors);
    }
};

const approveUpdate = async ( {
    id,
    setErrors,
    completeUpdate,
    setUpdateApproveState,
} ) => {
    try {
        await Axios({
            url: `/api/fabricators/${id}/pending-updates/approve`,
            method: 'POST',
        });
        await setErrors([]);
        await setUpdateApproveState(false);
        completeUpdate();
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        await setUpdateApproveState(false);
        return setErrors(errors);
    }
};

const Updates = ({ id, update, declineReasons, completeUpdate }) => {

    const [updateState] = useState(update);
    const [openState, setOpenState] = useState(false);
    const [updateApprove, setUpdateApproveState] = useState(false);
    const [updateDelete, setUpdateDeleteState] = useState(false);
    const [selectedReason, setSelectedReason] = useState('');
    const [errors, setErrors] = useState([]);
    const [openModel, setOpenModel] = useState(false);

    useEffect(() => {
         if (updateApprove) {
            (approveUpdate)({
                id,
                setErrors,
                completeUpdate,
                setUpdateApproveState
            });
         }
    }, [
        id,
        updateApprove,
        completeUpdate,
        setUpdateApproveState
    ]);

    useEffect(() => {
        if (updateDelete) {
            (declineUpdate)({
                id,
                setErrors,
                updateState,
                completeUpdate,
                setUpdateDeleteState,
                selectedReason,
                declineReasons
            });
        }
   }, [
        id,
        updateState,
        completeUpdate,
        selectedReason,
        declineReasons,
        updateDelete,
    ]);

   const options = declineReasons
        .map(({ name }) => ({ label: name, value: name }));

    const items = Object.keys(updateState)
        .filter(key => update[key] && update[key] !== null && key !== 'logo')
        .map(key => ({
            term: key.replace(/_/g, ' '),
            description: update[key]
        }));

    const logoUpdate = Object.keys(updateState)
        .find(key => key === 'logo' && update[key] !== null);

    return !items.length ? null : (
        <Layout.Section>
            <ErrorMessages errors={errors}/>
            <Card
                title={<Heading>Fabricator Updates <Badge>{items.length}</Badge></Heading>}
                actions={[{
                    content: `${openState ? 'Hide' : 'View'} Updates`,
                    onClick: () => setOpenState(!openState)
                }]}
                secondaryFooterActions={openState ? [{
                    destructive: true,
                    content: 'Delete Updates',
                    onClick: () => setOpenModel(true)
                }] : []}
                primaryFooterAction={openState ? {
                    content: 'Approve Updates',
                    onClick: () => setUpdateApproveState(true)
                } : null}
            >
                <Card.Section>
                    {!openState ? (
                        <Banner status="warning">
                            <p>Fabricator has pending updates</p>
                        </Banner>
                    ) : null}
                    <Collapsible open={openState} id="updateCollapsible">
                        <DescriptionList
                            items={items}
                        />
                        {logoUpdate ? (
                            <LogoWrap>
                                <h3>Logo Update:</h3>
                                <Logo src={updateState.logo}/>
                            </LogoWrap>
                        ) : null}
                    </Collapsible>
                </Card.Section>
            </Card>
            <Frame>
                <Sheet open={openModel} onClose={() => setOpenModel(false)}>
                    <SheetWrap>
                        <SpinnerWrap displaySpinner={updateDelete}>
                            <Spinner size="large" color="teal" />
                        </SpinnerWrap>
                        <SheetHeader>
                            <Stack distribution="equalSpacing">
                                <Icon source="alert" color="red" backdrop={true}/>
                                <Heading>Decline Submission</Heading>
                                <Button
                                    accessibilityLabel="Cancel"
                                    icon={MobileCancelMajorMonotone}
                                    onClick={() => setOpenModel(false)}
                                    plain
                                />
                            </Stack>
                        </SheetHeader>
                        <StyledScrollable>
                            <Layout>
                                <Layout.Section>
                                    <Banner
                                        status="warning"
                                    >
                                        <p>Are you sure you want to delete this submission? This cannot be undone.</p>
                                    </Banner>
                                    <br/>
                                    <Select
                                        placeholder="Select A Reason"
                                        label="Decline Reasons"
                                        options={options}
                                        onChange={value => setSelectedReason(value)}
                                        value={selectedReason}
                                    />
                                </Layout.Section>
                            </Layout>
                        </StyledScrollable>
                        <SheetFooter>
                            <Button onClick={() => setOpenModel(false)}>Cancel</Button>
                            <Button
                                destructive
                                onClick={() => setUpdateDeleteState(true)}
                                disabled={selectedReason === ''}
                            >
                                Decline
                            </Button>
                        </SheetFooter>
                    </SheetWrap>
                </Sheet>
            </Frame>
        </Layout.Section>
    )
}

export default Updates;

