import React, { useState, useEffect } from "react"
import { Redirect } from "@shopify/app-bridge/actions"
import Axios from "axios"
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Toast,
    DisplayText,
    Button,
    ButtonGroup,
    Modal,
    Select,
    Spinner,
    TextStyle,
    Frame,
} from "@shopify/polaris"

import YearVariants from "../../components/years/YearVariants"
import ErrorMessages from "../../components/ErrorMessages";

const getYear = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/years/${id}`,
            method: 'GET'
        });
        const { data: centers } = await Axios({
            url: `/api/centers`,
            method: 'GET'
        });
        if (data) {
            let selectedEmbarkCenter = '';
            let selectedEmbarkCenterRear = '';
            let selectedClassicCenter = '';
            let selectedPrecisionCenter = '';
            let selectedClassicCenterRear = '';
            let selectedPrecisionCenterRear = '';
            if (data.Centers) {
                const classics = data.Centers.filter(({ series, center_type }) => series === 'Classic' && center_type === 'front');
                const precision = data.Centers.filter(({ series, center_type }) => series === 'Precision' && center_type === 'front');
                const embark = data.Centers.filter(({ series, center_type }) => series === 'Embark' && center_type === 'front');

                const classicsRear = data.Centers.filter(({ series, center_type }) => series === 'Classic' && center_type === 'rear');
                const precisionRear = data.Centers.filter(({ series, center_type }) => series === 'Precision' && center_type === 'rear');
                const embarkRear = data.Centers.filter(({ series, center_type }) => series === 'Embark' && center_type === 'rear');

                if (classics.length) {
                    const [first] = classics;
                    selectedClassicCenter = `${first.series} ${first.center_code}`;
                }
                if (precision.length) {
                    const [first] = precision;
                    selectedPrecisionCenter = `${first.series} ${first.center_code}`;
                }
                if (embark.length) {
                    const [first] = embark;
                    selectedEmbarkCenter = `${first.series} ${first.center_code}`;
                }
                if (classicsRear.length) {
                    const [first] = classicsRear;
                    selectedClassicCenterRear = `${first.series} ${first.center_code}`;
                }
                if (precisionRear.length) {
                    const [first] = precisionRear;
                    selectedPrecisionCenterRear = `${first.series} ${first.center_code}`;
                }
                if (embarkRear.length) {
                    const [first] = embarkRear;
                    selectedEmbarkCenterRear = `${first.series} ${first.center_code}`;
                }
            }
            return setPageState({
                ...pageState,
                year: data,
                centers,
                errors: [],
                loading: false,
                yearDisplay: data,
                selectedEmbarkCenter,
                selectedClassicCenter,
                selectedPrecisionCenter,
                selectedEmbarkCenterRear,
                selectedClassicCenterRear,
                selectedPrecisionCenterRear,
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateYear = async ({ pageState, setPageState, id }) => {
    try {
        const selectedIds = [];
        if (pageState.selectedPrecisionCenter) {
            const found = pageState.centers
                .find(({ series, center_code }) => `${series} ${center_code}` === pageState.selectedPrecisionCenter);
            if (found) {
                selectedIds.push(found.id);
            }
        }

        if (pageState.selectedClassicCenter) {
            const found = pageState.centers
                .find(({ series, center_code }) => `${series} ${center_code}` === pageState.selectedClassicCenter);
            if (found) {
                selectedIds.push(found.id);
            }
        }

        if (pageState.selectedPrecisionCenterRear) {
            const found = pageState.centers
                .find(({ series, center_code, center_type }) => `${series} ${center_code}` === pageState.selectedPrecisionCenterRear && center_type === 'rear' && series === 'Precision');
            if (found) {
                selectedIds.push(found.id);
            }
        }

        if (pageState.selectedClassicCenterRear) {
            const found = pageState.centers
                .find(({ series, center_code, center_type }) => `${series} ${center_code}` === pageState.selectedClassicCenterRear && center_type === 'rear' && series === 'Classic');
            if (found) {
                selectedIds.push(found.id);
            }
        }


        if (pageState.selectedEmbarkCenter) {
            const found = pageState.centers
                .find(({ series, center_code, center_type }) => `${series} ${center_code}` === pageState.selectedEmbarkCenter && center_type === 'front' && series === 'Embark');
            if (found) {
                selectedIds.push(found.id);
            }
        }

        if (pageState.selectedEmbarkCenterRear) {
            const found = pageState.centers
                .find(({ series, center_code, center_type }) => `${series} ${center_code}` === pageState.selectedEmbarkCenterRear && center_type === 'rear' && series === 'Embark');
            if (found) {
                selectedIds.push(found.id);
            }
        }

        const { data } = await Axios({
            url: `/api/years/${id}`,
            method: 'PUT',
            data: { ...pageState.year, selectedIds }
        });

        if (data) {
            return setPageState({
                ...pageState,
                year: data,
                yearDisplay: data,
                hasUpdate: false,
                updateLoading: false,
                showToast: true,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            updateLoading: false,
            loading: false,
            errors
        });
    }
};

const deleteTheYear = async ({ pageState, setPageState, id, app }) => {
    try {
        const { data } = await Axios({
            url: `/api/years/${id}`,
            method: 'DELETE'
        });
        if (data) {
            const redirect = Redirect.create(app);
            return redirect.dispatch(Redirect.Action.APP, '/app/settings/years');
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            deleteLoading: false,
            updateLoading: false,
            loading: false,
            errors
        });
    }
};


const YearDetail = (props) => {

    const [pageState, setPageState] = useState({
        year: null,
        selected: [],
        centers: [],
        selectedEmbarkCenter: '',
        selectedClassicCenter: '',
        selectedPrecisionCenter: '',
        selectedEmbarkCenterRear: '',
        selectedClassicCenterRear: '',
        selectedPrecisionCenterRear: '',
        selectedYear: null,
        yearDisplay: null,
        loading: true,
        showToast: false,
        updateLoading: false,
        deleteLoading: false,
        showToastDelete: false,
        hasUpdate: false,
        confirmDelete: false,
        sheetActive: false,
        resourceTitle: "Years",
        resourceSlug: "year",
        errors: []
    });

    useEffect(() => {
        const { match: { params }, app } = props;
        if (pageState.loading && params && params.yearId) {
            (getYear)({ pageState, setPageState, id: params.yearId });
        }
        if (pageState.updateLoading && params && params.yearId) {
            (updateYear)({ pageState, setPageState, id: params.yearId });
        }
        if (pageState.deleteLoading && params && params.yearId) {
            (deleteTheYear)({ pageState, setPageState, id: params.yearId, app });
        }
    }, [
        pageState.loading,
        pageState.updateLoading,
        pageState.deleteLoading,
        pageState.sheetActive,
        pageState.confirmDelete,
        pageState,
        props
    ]);

    const submitForm = () => setPageState({
        ...pageState,
        updateLoading: true
    });

    const handleDelete = () => setPageState({
        ...pageState,
        confirmDelete: true
    });

    const handleDeleteClose = () => setPageState({
        ...pageState,
        confirmDelete: false
    });

    const handleDeleteCancel = () => setPageState({
        ...pageState,
        confirmDelete: false
    });

    const deleteYears = () => setPageState({
        ...pageState,
        deleteLoading: true,
        confirmDelete: false
    });

    const handleNameChange = (value) => setPageState({
        ...pageState,
        hasUpdate: true,
        year: {
            ...pageState.year,
            year: value
        }
    });

    const handleActiveChange = (value) => setPageState({
        ...pageState,
        hasUpdate: true,
        year: {
            ...pageState.year,
            active: value === 'active'
        }
    });

    const embarkChange = (selectedEmbarkCenter) => setPageState({
        ...pageState,
        hasUpdate: true,
        selectedEmbarkCenter
    });

    const embarkChangeRear = (selectedEmbarkCenterRear) => setPageState({
        ...pageState,
        hasUpdate: true,
        selectedEmbarkCenterRear
    });

    const precisionChange = (selectedPrecisionCenter) => setPageState({
        ...pageState,
        hasUpdate: true,
        selectedPrecisionCenter
    });

    const classicChange = (selectedClassicCenter) => setPageState({
        ...pageState,
        hasUpdate: true,
        selectedClassicCenter
    });

    const precisionChangeRear = (selectedPrecisionCenterRear) => setPageState({
        ...pageState,
        hasUpdate: true,
        selectedPrecisionCenterRear
    });

    const classicChangeRear = (selectedClassicCenterRear) => setPageState({
        ...pageState,
        hasUpdate: true,
        selectedClassicCenterRear
    });

    const options = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
    ];

    const {
        year,
        errors,
        centers,
        loading,
        showToast,
        hasUpdate,
        yearDisplay,
        resourceTitle,
        confirmDelete,
        updateLoading,
        showToastDelete,
        selectedEmbarkCenter,
        selectedClassicCenter,
        selectedPrecisionCenter,
        selectedEmbarkCenterRear,
        selectedClassicCenterRear,
        selectedPrecisionCenterRear,
    } = pageState;

    //  classics
    const classics = centers
        .filter(({ series, center_type }) => series === 'Classic' && center_type === 'front')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    const classicsRear = centers
        .filter(({ series, center_type }) => series === 'Classic' && center_type === 'rear')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    //  precision
    const precision = centers
        .filter(({ series, center_type }) => series === 'Precision' && center_type === 'front')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    const precisionRear = centers
        .filter(({ series, center_type }) => series === 'Precision' && center_type === 'rear')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    //  Embark
    const embarkRear = centers
        .filter(({ series, center_type }) => series === 'Embark' && center_type === 'rear')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    const embark = centers
        .filter(({ series, center_type }) => series === 'Embark' && center_type === 'front')
        .map(({ id, series, center_code }) => ({
            label: `${series} ${center_code}`,
            value: `${series} ${center_code}`
        }));

    const subTitle = year && year.Model && year.Model.Make ?
        (
            <TextStyle variation="subdued">
                <Button plain url={`/app/settings/makes/${year.Model.Make.id}`}>{year.Model.Make.name}</Button>
                &nbsp;/&nbsp;
                <Button plain url={`/app/settings/models/${year.Model.id}`}>{year.Model.name}</Button>
            </TextStyle>
        )
    : null;

    return (
        <Page
            title={`Edit`}
            breadcrumbs={[{
                content: resourceTitle,
                url: `/app/settings/years`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        {year ? yearDisplay.year : <Spinner size="small" color="teal" />}
                        <ButtonGroup>
                            <Button
                                size="slim"
                                url={`/app/settings/years/new`}
                                outline
                            >
                                Create
                            </Button>
                            <Button
                                size="slim"
                                onClick={handleDelete}
                                destructive
                            >
                                Delete
                            </Button>
                        </ButtonGroup>
                    </DisplayText>
                    {subTitle}
                    <br/>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Save',
                            onClick: submitForm,
                            disabled: !hasUpdate,
                            loading: updateLoading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        type="text"
                                        label="Year"
                                        onChange={handleNameChange}
                                        value={year ? year.year : null}
                                    />
                                    <Select
                                        label="Status"
                                        options={options}
                                        onChange={handleActiveChange}
                                        value={year ? year.active ? 'active' : 'inactive' : ''}
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Classic Center Front"
                                        options={classics}
                                        onChange={classicChange}
                                        value={selectedClassicCenter}
                                        placeholder="Select"
                                    />
                                    <Select
                                        label="Classic Center Rear"
                                        options={classicsRear}
                                        onChange={classicChangeRear}
                                        value={selectedClassicCenterRear}
                                        placeholder="Select"
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Precision Center Front"
                                        options={precision}
                                        onChange={precisionChange}
                                        value={selectedPrecisionCenter}
                                        placeholder="Select"
                                    />
                                    <Select
                                        label="Precision Center Rear"
                                        options={precisionRear}
                                        onChange={precisionChangeRear}
                                        value={selectedPrecisionCenterRear}
                                        placeholder="Select"
                                    />
                                </FormLayout.Group>
                                <FormLayout.Group>
                                    <Select
                                        label="Embark Center Front"
                                        options={embark}
                                        onChange={embarkChange}
                                        value={selectedEmbarkCenter}
                                        placeholder="Select"
                                    />
                                    <Select
                                        label="Embark Center Rear"
                                        options={embarkRear}
                                        onChange={embarkChangeRear}
                                        value={selectedEmbarkCenterRear}
                                        placeholder="Select"
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                    </Card>
                </Layout.Section>
                {!loading && year ? (
                    <Layout.Section>
                        <YearVariants
                            yearId={year.id}
                        />
                    </Layout.Section>
                ) : null}
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            <Frame>
                {!loading ?
                    <>
                        <Modal
                            open={confirmDelete}
                            onClose={handleDeleteClose}
                            title={`Delete Year`}
                            message={`Are you sure you want to DELETE this vehicle year listing?`}
                            primaryAction={{
                                content: 'Delete',
                                onAction: deleteYears,
                                destructive: true
                            }}
                            secondaryActions={[{
                                content: 'Cancel',
                                onAction: handleDeleteCancel,
                            }]}
                        >
                        </Modal>
                    </>
                : null}
                {!loading && showToast ?
                    <Toast
                        content={`${resourceTitle} Updated`}
                        onDismiss={() => setPageState({ ...pageState, showToast: false })}
                    />
                : null}
                {!loading && showToastDelete ?
                    <Toast
                        content={`${resourceTitle} deleted`}
                        onDismiss={() => setPageState({ ...pageState, showToastDelete: false })}
                    />
                : null}
            </Frame>
        </Page>
    )
};

export default YearDetail;

