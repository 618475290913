import React, { useState, useEffect} from "react"
import Axios from "axios"
import {
    Card,
    ResourcePicker,
    ResourceList,
    Thumbnail,
    TextStyle,
    Avatar,
    Banner,
    Toast,
    Frame,
} from "@shopify/polaris"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const Headline = styled.h3`
    width: 25%;
    display: inline-block;
    margin-right: 40px;
    vertical-align: middle;
`;

const VariantHeadline = styled.h3`
    font-weight: 600;
    margin-bottom: 10px;
`;

const Variants = styled.div`
    max-width: 75%;
    display: inline-block;
    vertical-align: middle;
`;


const getProductId = (product) => {
    try {
        const productSplit = product.id.split('/');
        return parseInt(productSplit[productSplit.length -1], 10);
    } catch (error) {
        //TODO: do something with errors
        console.log('getProductId');
        return null;
    }
};

const getVariantId = (variant) => {
    try {
        const productSplit = variant.id.split('/');
        return parseInt(productSplit[productSplit.length -1], 10);
    } catch (error) {
        //TODO: do something with errors
        console.log('getVariantId');
        return null;
    }
};

const mapSelections = ({ selection, pageState }) => {
    return selection.map((product) => {
        return product.variants.map((variant) => {
            return ({
                product_id: getProductId(product),
                variant_id: getVariantId(variant),
                product_handle: product.handle
            })
        })
    })
    .reduce((accum, item) => accum.concat(item))
    .concat(pageState.variants);
};

const getVariants = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/years/${pageState.yearId}/variants`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                variants: data,
                loadingProducts: true,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            loadingProducts: false,
            errors
        });
    }
};

const getProducts= async ({ pageState, setPageState }) => {
    try {
        let allVariants = [];
        const mappedVariants = pageState.selection.map((product) => {
            return product.variants.map((variant) => {
                return ({
                    product_id: getProductId(product),
                    variant_id: getVariantId(variant),
                    product_handle: product.handle
                })
            })
        });
        if (mappedVariants.length) {
            allVariants = mappedVariants
                .reduce((accum, item) => accum.concat(item))
                .concat(pageState.variants)
                .map(({ product_id }) => product_id);
        } else {
            allVariants = pageState.variants.map(({ product_id }) => product_id);
        }

        const ids = Array.from(new Set(allVariants));
        const { data } = await Axios({
            url: `/api/products${ids.length ? `?ids=${ids.join(',')}` : ''}`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                shopifyProducts: data,
                loadingProducts: false,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingProducts: false,
            loading: false,
            errors
        });
    }
};

const saveShopifyVariantRecords = async ({ pageState, setPageState, setIsToasting }) => {
    try {
        const handlesToDelete = Array.from(new Set(pageState.variantsToDelete.map(({ product_handle }) => product_handle)));
        const selectedVariants = pageState.selection.map((product) => {
            return product.variants.map((variant) => {
                return ({
                    product_id: getProductId(product),
                    variant_id: getVariantId(variant),
                    product_handle: product.handle
                })
            })
        });

        if (handlesToDelete && handlesToDelete.length) {
            await Promise.all(handlesToDelete.map(handle => Axios({
                url: `/api/years/${pageState.yearId}/variants/${handle}`,
                method: 'DELETE',
            })));
        }

        if (selectedVariants && selectedVariants.length) {
            const variants = selectedVariants.reduce((accum, item) => accum.concat(item));
            await Axios({
                url: `/api/years/${pageState.yearId}/variants`,
                method: 'POST',
                data: { variants }
            });
        }

        await setIsToasting(true);
        return setPageState({
            ...pageState,
            loadingProducts: true,
            loadingSaveProduct: false,
            loading: false,
            productChange: false,
            variantsToDelete: [],
            selection: [],
            errors: []
        });
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingSaveProduct: false,
            loading: false,
            errors
        });
    }
};

const warningMessage = message => (<Banner status="warning">{message}</Banner>);

const YearVariants = (props) => {

    const [isToasting, setIsToasting] = useState(false);

    const [pageState, setPageState] = useState({
        variants: [],
        loading: true,
        yearId: props.yearId,
        open: false,
        selection: [],
        selectedProductHandle: null,
        loadingProducts: false,
        loadingSaveProduct: false,
        loadingDeleteProducts: false,
        productChange: false,
        shopifyProducts: [],
        variantsToDelete: []
    });

    useEffect(() => {
        if (pageState.loading) {
            (getVariants)({ pageState, setPageState })
        }
        if (pageState.loadingProducts) {
            (getProducts)({ pageState, setPageState })
        }
        if (pageState.loadingSaveProduct) {
            (saveShopifyVariantRecords)({ pageState, setPageState, setIsToasting })
        }
    }, [props, pageState]);

    const onCancel = () => setPageState({
        ...pageState,
        open: false
    });

    const openVariantPicker = () => setPageState({
        ...pageState,
        open: true
    });

    const saveProducts = () => setPageState({
        ...pageState,
        loadingSaveProduct: true
    });

    const onSelection = ({ selection }) => setPageState({
        ...pageState,
        variants: mapSelections({ selection, pageState }),
        selection: pageState.selection.concat(selection),
        open: false,
        loadingProducts: true,
        productChange: true
    });

    const removeProduct = (handle) => {
        const currentVariantsToDelete = pageState.variants.filter((variant) => variant.product_handle === handle);
        const newVariants = pageState.variants.filter((variant) => variant.product_handle !== handle);
        const variantsToDelete = pageState.variantsToDelete.concat(currentVariantsToDelete);
        const newShopifyProducts = pageState.shopifyProducts.filter((product) => product.handle !== handle);
        const newSelection = pageState.selection.filter((product) => product.handle !== handle);

        return setPageState({
            ...pageState,
            variants: newVariants,
            shopifyProducts: newShopifyProducts,
            selection: newSelection,
            selectedProductHandle: handle,
            variantsToDelete: variantsToDelete,
            productChange: true
        });
    };

    const renderItems = ({ item, pageState }) => {
        const { id, handle, title, variants } = item;
        const validVariantsSet = new Set(pageState.variants.map(({ variant_id }) => parseInt(variant_id, 10)));
        const validVariants = variants.filter(({ id }) => validVariantsSet.has(id));
        const image = item && item.images.length ? item.images[0].src : null;

        const media = image ? (
            <Thumbnail
                source={image}
                size="large"
                alt={handle}
            />
        ) : (
            <Avatar customer size="large" name={handle} />
        );

        return (
            <ResourceList.Item
                id={id}
                media={media}
                accessibilityLabel={`View details for ${handle}`}
                shortcutActions={[{
                    icon: 'circleCancel',
                    onClick: () => removeProduct(handle),
                }]}
            >
                <Headline>
                    <TextStyle variation="strong">{title}</TextStyle>
                </Headline>
                <Variants>
                    <VariantHeadline>Selected Variants:</VariantHeadline>
                    {validVariants.map((variant, i) => (<p key={`variant-${i+1}`}>{variant.title}: {variant.id}</p>))}
                </Variants>
            </ResourceList.Item>
        );
    };

    const {
        open,
        loading,
        shopifyProducts,
        loadingProducts,
        loadingSaveProduct,
        productChange,
        errors
    } = pageState;

    return (
        <>
            <ErrorMessages errors={errors}/>
            <Card
                title="Variants"
                actions={[{
                    content: 'Add variants',
                    onClick: openVariantPicker
                }]}
                primaryFooterAction={{
                    content: 'Save',
                    disabled: !productChange,
                    onClick: saveProducts
                }}
            >
                <Card.Section>
                    <ResourceList
                        resourceName={{
                            singular: 'companion product',
                            plural: 'companion products'
                        }}
                        items={shopifyProducts}
                        renderItem={(item) => renderItems({ item, pageState })}
                        loading={loadingProducts || loadingSaveProduct}
                    />
                    {!loading && !loadingProducts && !shopifyProducts.length ? warningMessage('No variants associated with this year.') : null}
                </Card.Section>
            </Card>
            <ResourcePicker
                resourceType="Product"
                open={open}
                onSelection={onSelection}
                onCancel={onCancel}
                allowMultiple={false}
                showVariants={true}
            />
            {isToasting ?
                <Frame>
                    <Toast
                        content={`Saved`}
                        onDismiss={() => setIsToasting(false)}
                    />
                </Frame>
            : null}
        </>
    )
};

export default YearVariants;

