import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Card,
    ChoiceList,
    TextStyle,
    Scrollable,
    Button,
    Sheet,
    Heading,
    ResourceList,
    Avatar,
    Badge
} from "@shopify/polaris"

import { MobileCancelMajorMonotone } from "@shopify/polaris-icons"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const getServices = async ({ setServiceState, serviceState, id }) => {
    try {
        const { data: serviceData } = await Axios({
            url: `/api/services`,
            method: 'GET'
        });
        const { data: fabricatorServiceData } = await Axios({
            url: `/api/fabricators/${id}/services`,
            method: 'GET'
        });
        if (serviceData && fabricatorServiceData) {
            return setServiceState({
                ...serviceState,
                selected: fabricatorServiceData.map(({ id }) => id),
                services: serviceData.filter(({ status }) => status === true),
                fabricatorServices: fabricatorServiceData.filter(({ status }) => status === true),
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setServiceState({
            ...serviceState,
            loading: false,
            errors
        });
    }
};

const updateServices = async ({ setServiceState, serviceState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/services/update`,
            method: 'PUT',
            data: { selected: serviceState.selected }
        });
        if (data) {
            return setServiceState({
                ...serviceState,
                sheetActive: false,
                fabricatorServices: data,
                updateLoading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setServiceState({
            ...serviceState,
            sheetActive: false,
            updateLoading: false,
            errors
        });
    }
};

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    align-items: center;
    border-bottom: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const Services = ({ id }) => {

    const [serviceState, setServiceState] = useState({
        selected: [],
        services: [],
        fabricatorServices: [],
        updateLoading: false,
        sheetActive: false,
        loading: true,
        errors: []
    });

    useEffect(() => {
        if (serviceState.updateLoading) {
            (updateServices)({ serviceState, setServiceState, id });
        }
        if(serviceState.loading){
            (getServices)({ serviceState, setServiceState, id });
        }
    }, [
        serviceState.sheetActive,
        serviceState.loading,
        serviceState.updateLoading,
        serviceState,
        id
    ]);

    const handleChange = (value) => setServiceState({
        ...serviceState,
        selected: value
    });

    const handleCloseSheet = () => setServiceState({
        ...serviceState,
        sheetActive: false
    });

    const handleOpenSheet = () => setServiceState({
        ...serviceState,
        sheetActive: true
    });

    const handleUpdate = () => setServiceState({
        ...serviceState,
        updateLoading: true
    });

    const renderItem = ({ name }) => {
        const media = (
            <Avatar customer size="medium" name={name} source="http://cdn.onlinewebfonts.com/svg/img_370112.png"/>
        );
        return (
          <ResourceList.Item
            id={id}
            media={media}
            accessibilityLabel={`View details for ${name}`}
          >
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
          </ResourceList.Item>
        );
    };

    const {
        loading,
        selected,
        services,
        sheetActive,
        fabricatorServices,
        errors
    } = serviceState;

    return (
        <>
            <ErrorMessages errors={errors}/>
            <Card
                sectioned
                title={<Heading>Services &nbsp;&nbsp;<Badge status="info">{fabricatorServices.length}</Badge></Heading>}
                secondaryFooterActions={[{
                    content: 'Manage Services',
                    onAction: handleOpenSheet
                }]}
            >
                <ResourceList
                    resourceName={{ singular: 'service', plural: 'services' }}
                    items={fabricatorServices}
                    renderItem={renderItem}
                    loading={loading}
                />
                {!loading && !fabricatorServices.length ? <p>No Services selected.</p> : null}
            </Card>
            <Sheet
                open={sheetActive}
                onClose={handleCloseSheet}
            >
                <SheetWrap>
                    <SheetHeader>
                        <Heading>Manage Services</Heading>
                        <Button
                            accessibilityLabel="Cancel"
                            icon={MobileCancelMajorMonotone}
                            onClick={handleCloseSheet}
                            plain
                        />
                    </SheetHeader>
                    <StyledScrollable>
                        <ChoiceList
                            allowMultiple
                            title={'Available Services'}
                            choices={services.map(({ id, name }) => ({
                                label: name,
                                value: id
                            }))}
                            selected={selected}
                            onChange={handleChange}
                        />
                    </StyledScrollable>
                    <SheetFooter>
                        <Button onClick={handleCloseSheet}>Cancel</Button>
                        <Button primary onClick={handleUpdate}>Update</Button>
                    </SheetFooter>
                </SheetWrap>
            </Sheet>
        </>
    )
}

export default Services;

