import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Card,
    Collapsible,
    Layout,
    Banner,
    Heading,
    Badge,
    Stack,
    Select,
    Frame,
    Sheet,
    Scrollable,
    Button,
    Spinner,
    Icon,
} from "@shopify/polaris"
import { MobileCancelMajorMonotone } from "@shopify/polaris-icons"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const SpinnerWrap = styled.div`
    display: ${({ displaySpinner }) => displaySpinner ? `flex` : `none`};
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.4);
`
const Image = styled.img`
    width: 100%;
`;

const approveImages = async ( {
    setImagesApproveState,
    imagesState,
    setErrors,
    completeUpdate,
    id
} ) => {
    try {
        await Axios({
            url: `/api/fabricators/${id}/images/approve`,
            method: 'PUT',
            data: { images: imagesState }
        });
        await setErrors([]);
        await setImagesApproveState(false);
        return completeUpdate();
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        await setImagesApproveState(false);
        return setErrors(errors);
    }
};

const deleteImages = async ( {
    id,
    setErrors,
    imagesState,
    selectedReason,
    completeUpdate,
    setImagesDeleteState,
    declineReasons
} ) => {
    try {
        const selected = declineReasons.find(({ name }) => name === selectedReason);
        await Axios({
            url: `/api/fabricators/${id}/images/decline/${selected.id}`,
            method: 'DELETE',
            data: { images: imagesState }
        });
        await setErrors([]);
        await setImagesDeleteState(false);
        return completeUpdate();
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        await setImagesDeleteState(false);
        return setErrors(errors);
    }
};

const numberOfEmpty = ({ number, columns }) => {
    let needed = 0;
    if (number > 3) {
        const remainder = number % columns;
        needed = remainder ? Math.ceil(number / columns - remainder) : 0;
    } else {
        needed = columns - number
    }
    return needed > 0 ? new Array(needed).fill({}).map((_, i) => (
        <Layout.Section  key={`image-section-empty-${i+1}`} oneThird></Layout.Section>
    )) : [];
};

const ImageUpdates = ({ id, images, completeUpdate, declineReasons }) => {

    const [imagesState] = useState(images || []);
    const [openState, setOpenState] = useState(false);
    const [imagesApprove, setImagesApproveState] = useState(false);
    const [imagesDelete, setImagesDeleteState] = useState(false);
    const [selectedReason, setSelectedReason] = useState('');
    const [errors, setErrors] = useState([]);
    const [openModel, setOpenModel] = useState(false);

    useEffect(() => {
         if(imagesApprove){
            (approveImages)({
                id,
                setErrors,
                imagesState,
                completeUpdate,
                setImagesApproveState
            });
         }
    }, [
        id,
        imagesState,
        imagesApprove,
        completeUpdate
    ]);

    useEffect(() => {
        if(imagesDelete) {
            (deleteImages)({
                id,
                setErrors,
                imagesState,
                completeUpdate,
                setImagesDeleteState,
                selectedReason,
                declineReasons
            });
        }
   }, [
        id,
        imagesState,
        imagesDelete,
        completeUpdate,
        selectedReason,
        declineReasons
    ]);

    const options = declineReasons
        .map(({ name }) => ({ label: name, value: name }))

    const items = imagesState.map(({ url }, i) => (
        <Layout.Section  key={`image-section-${i+1}`} oneThird>
            <Image src={url} alt=""/>
        </Layout.Section>
    ));

    const emptyLayout = numberOfEmpty({ number: items.length, columns: 3 });

    const imageLayouts = items.concat(emptyLayout);

    return (
        <Layout.Section>
            <ErrorMessages errors={errors}/>
            <Card
                title={<Heading>Gallery Updates <Badge>{imagesState.length}</Badge></Heading>}
                actions={[{
                    content: `${openState ? 'Hide' : 'View'} Gallery Updates`,
                    onClick: () => setOpenState(!openState)
                }]}
                secondaryFooterActions={openState ? [{
                    destructive: true,
                    content: 'Decline Images',
                    onClick: () => setOpenModel(true)
                }] : []}
                primaryFooterAction={openState ? {
                    content: 'Approve Images',
                    onClick: () => setImagesApproveState(true)
                } : null}
            >
                <Card.Section>
                    {!openState ? (
                        <Banner status="warning">
                            <p>Fabricator has pending Gallery updates</p>
                        </Banner>
                    ) : null}
                    <Collapsible open={openState} id="updateCollapsible">
                        <Layout>
                            {imageLayouts}
                        </Layout>
                    </Collapsible>
                </Card.Section>
            </Card>
            <Frame>
                <Sheet open={openModel} onClose={() => setOpenModel(false)}>
                    <SheetWrap>
                        <SpinnerWrap displaySpinner={imagesDelete}>
                            <Spinner size="large" color="teal" />
                        </SpinnerWrap>
                        <SheetHeader>
                            <Stack distribution="equalSpacing">
                                <Icon source="alert" color="red" backdrop={true}/>
                                <Heading>Decline Submission</Heading>
                                <Button
                                    accessibilityLabel="Cancel"
                                    icon={MobileCancelMajorMonotone}
                                    onClick={() => setOpenModel(false)}
                                    plain
                                />
                            </Stack>
                        </SheetHeader>
                        <StyledScrollable>
                            <Layout>
                                <Layout.Section>
                                    <Banner
                                        status="warning"
                                    >
                                        <p>Are you sure you want to delete this submission? This cannot be undone.</p>
                                    </Banner>
                                    <br/>
                                    <Select
                                        placeholder="Select A Reason"
                                        label="Decline Reasons"
                                        options={options}
                                        onChange={value => setSelectedReason(value)}
                                        value={selectedReason}
                                    />
                                </Layout.Section>
                            </Layout>
                        </StyledScrollable>
                        <SheetFooter>
                            <Button onClick={() => setOpenModel(false)}>Cancel</Button>
                            <Button
                                destructive
                                onClick={() => setImagesDeleteState(true)}
                                disabled={selectedReason === ''}
                            >
                                Delete
                            </Button>
                        </SheetFooter>
                    </SheetWrap>
                </Sheet>
            </Frame>
        </Layout.Section>
    )
}

export default ImageUpdates;

