import React from "react"
import styled from "styled-components"

const Svg = styled.svg`
    width: 20%;
    height: 24px;
`;

const StarWrap = styled.div`
    width: 110px;
    vertical-align: middle;
    float: right;
`;

const star = (on, i) => (
    <Svg xmlns="http://www.w3.org/2000/svg" key={`start-${i + 1}`}>
        <path
            d="M 56.888312,4.99999 L 69.297481,43.198877 L 109.46143,43.196598 L 76.966771,66.802524 L 89.380284,105.00001 L 56.88831,81.390382 L 24.396334,105.00001 L 36.809848,66.80253 L 4.3151906,43.196598 L 44.479138,43.198877 L 56.888312,4.99999 z "
            style={{"transform":"translate(0, 0) scale(0.2)","fill":`${on ? '#ffe34a' : '#bababa'}`,"fillOpacity":"1","fillRule":"evenodd","stroke":"none","strokeWidth":"10","strokeLinecap":"round","strokeLinejoin":"round","strokeMiterlimit":"4","strokeDasharray":"none","strokeOpacity":"1"}}
            id="path1874" />
    </Svg>
);

const Stars = ({ rating }) => {
    const stars = new Array(5)
        .fill({})
        .map((_, i) => star(i + 1 <= rating, i));

    return (
        <>
            <StarWrap>{stars}</StarWrap>
        </>
    );
};

export default Stars;

