import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Card,
    ChoiceList,
    Scrollable,
    Button,
    Sheet,
    Heading,
    ResourceList,
    TextStyle,
    Avatar,
    Badge
} from "@shopify/polaris"
import {
    MobileCancelMajorMonotone,
} from "@shopify/polaris-icons"
import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const getEquipment = async ({ setEquipmentState, equipmentState, id }) => {
    try {
        const { data: equipmentData } = await Axios({
            url: `/api/equipment`,
            method: 'GET'
        });
        const { data: fabricatorEquipmentData } = await Axios({
            url: `/api/fabricators/${id}/equipment`,
            method: 'GET'
        });
        if (equipmentData && fabricatorEquipmentData) {
            return setEquipmentState({
                ...equipmentState,
                selected: fabricatorEquipmentData.map(({ id }) => id),
                equipment: equipmentData.filter(({ status }) => status === true),
                fabricatorEquipment: fabricatorEquipmentData.filter(({ status }) => status === true),
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setEquipmentState({
            ...equipmentState,
            loading: false,
            errors
        });
    }
};

const updateEquipment = async ({ setEquipmentState, equipmentState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/equipment/update`,
            method: 'PUT',
            data: { selected: equipmentState.selected }
        });
        if (data) {
            return setEquipmentState({
                ...equipmentState,
                sheetActive: false,
                fabricatorEquipment: data,
                updateLoading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setEquipmentState({
            ...equipmentState,
            sheetActive: false,
            updateLoading: false,
            errors
        });
    }
};

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    align-items: center;
    border-bottom: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const Equipment = ({ id }) => {

    const [equipmentState, setEquipmentState] = useState({
        selected: [],
        equipment: [],
        fabricatorEquipment: [],
        updateLoading: false,
        sheetActive: false,
        loading: true,
        errors: []
    });

    useEffect(() => {
        if (equipmentState.updateLoading) {
            (updateEquipment)({ equipmentState, setEquipmentState, id });
        }
        if(equipmentState.loading){
            (getEquipment)({ equipmentState, setEquipmentState, id });
        }
    }, [
        equipmentState.sheetActive,
        equipmentState.loading,
        equipmentState.updateLoading,
        equipmentState,
        id
    ]);

    const handleChange = (value) => setEquipmentState({
        ...equipmentState,
        selected: value
    });

    const handleCloseSheet = () => setEquipmentState({
        ...equipmentState,
        sheetActive: false
    });

    const handleOpenSheet = () => setEquipmentState({
        ...equipmentState,
        sheetActive: true
    });

    const handleUpdate = () => setEquipmentState({
        ...equipmentState,
        updateLoading: true
    });

    const renderItem = ({ name }) => {
        const media = (
            <Avatar
                customer
                size="medium"
                name={name}
                source="/images/auto-service.svg"
            />
        );

        return (
          <ResourceList.Item
            id={id}
            media={media}
            accessibilityLabel={`View details for ${name}`}
          >
            <h3>
              <TextStyle variation="strong">{name}</TextStyle>
            </h3>
          </ResourceList.Item>
        );
    };

    const {
        loading,
        selected,
        equipment,
        sheetActive,
        fabricatorEquipment,
        errors
    } = equipmentState;

    return (
        <>
            <ErrorMessages errors={errors}/>
            <Card
                sectioned
                title={<Heading>Equipment &nbsp;&nbsp;<Badge status="info">{fabricatorEquipment.length}</Badge></Heading>}
                secondaryFooterActions={[{
                    content: 'Manage Equipment',
                    onAction: handleOpenSheet
                }]}
            >
                <ResourceList
                    resourceName={{
                        singular: 'equipment',
                        plural: 'equipment'
                    }}
                    items={fabricatorEquipment}
                    renderItem={renderItem}
                    loading={loading}
                />
                {!loading && !fabricatorEquipment.length ? <p>No Equipment selected.</p> : null}
            </Card>
            <Sheet
                open={sheetActive}
                onClose={handleCloseSheet}
            >
                <SheetWrap>
                    <SheetHeader>
                        <Heading>Manage Equipment</Heading>
                        <Button
                            accessibilityLabel="Cancel"
                            icon={MobileCancelMajorMonotone}
                            onClick={handleCloseSheet}
                            plain
                        />
                    </SheetHeader>
                    <StyledScrollable>
                        <ChoiceList
                            allowMultiple
                            title={'Available Equipment'}
                            choices={equipment.map(({ id, name }) => ({
                                label: name,
                                value: id
                            }))}
                            selected={selected}
                            onChange={handleChange}
                        />
                    </StyledScrollable>
                    <SheetFooter>
                        <Button onClick={handleCloseSheet}>Cancel</Button>
                        <Button primary onClick={handleUpdate}>Update</Button>
                    </SheetFooter>
                </SheetWrap>
            </Sheet>
        </>
    )
}

export default Equipment;

