import React, { useState, useEffect } from "react"
import { Redirect } from "@shopify/app-bridge/actions"
import createSlug from "slug"
import Axios from "axios"
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Toast,
    DisplayText,
    Button,
    ButtonGroup,
    Modal,
    ResourceList,
    Avatar,
    TextStyle,
    Frame,
    Select,
    Spinner,
} from "@shopify/polaris"
import styled from "styled-components"

import YearSheet from "../../components/makes/YearSheet"
import ErrorMessages from "../../components/ErrorMessages"

const PageBanner = styled.h3`
    display: inline-block;
    vertical-align: middle;
`;

const getModel = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/models/${id}`,
            method: 'GET'
        });
        const { data: allYearData } = await Axios({
            url: `/api/years`,
            method: 'GET'
        });
        if (data && allYearData) {
            const displayingIds = new Set(data.Years.map(({ id }) => id));
            return setPageState({
                ...pageState,
                model: data,
                slugSubmit: data.slug,
                modelDisplay: data,
                allYearData: allYearData.years.filter(({ id }) => !displayingIds.has(id)),
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateModel = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/models/${id}`,
            method: 'PUT',
            data: pageState.model
        });
        if (data) {
            return setPageState({
                ...pageState,
                model: data,
                slugSubmit: data.slug,
                modelDisplay: data,
                hasUpdate: false,
                updateLoading: false,
                showToast: true,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            updateLoading: false,
            errors
        });
    }
};

const addModelYears = async ({ pageState, setPageState, id }) => {
    try {
        const { selected } = pageState;
        const { data } = await Axios({
            url: `/api/models/${id}/years`,
            method: 'POST',
            data: { selected }
        });
        if (data) {
            return setPageState({
                ...pageState,
                selected: [],
                updateYearLoading: false,
                loading: true,
                openYearSheet: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            updateYearLoading: false,
            openYearSheet: false,
            errors
        });
    }
};

const deleteModels = async ({ pageState, setPageState, id, app }) => {
    try {
        const { data } = await Axios({
            url: `/api/models/${id}`,
            method: 'DELETE'
        });

        if (data) {
            const redirect = Redirect.create(app);
            return redirect.dispatch(Redirect.Action.APP, '/app/settings/models');
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            deleteLoading: false,
            updateLoading: false,
            errors
        });
    }
};

const removeModelYear = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/models/${id}/years/${pageState.selectedYear.id}`,
            method: 'DELETE',
        });
        if (data) {
            return setPageState({
                ...pageState,
                selected: [],
                deleteYearLoading: false,
                confirmYearDelete: false,
                loading: true,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            confirmYearDelete: false,
            deleteYearLoading: false,
            errors
        });
    }
};

const ModelDetail = (props) => {

    const [pageState, setPageState] = useState({
        model: null,
        slugSubmit: null,
        selected: [],
        selectedYear: null,
        modelDisplay: null,
        allYearData: [],
        loading: true,
        showToast: false,
        updateLoading: false,
        updateYearLoading: false,
        deleteLoading: false,
        deleteYearLoading: false,
        showToastDelete: false,
        hasUpdate: false,
        confirmDelete: false,
        confirmYearDelete: false,
        openYearSheet: false,
        yearAction: null,
        resourceTitle: "Models",
        resourceSlug: "models",
        errors: []
    });

    useEffect(() => {
        const { match: { params }, app } = props;
        if (pageState.loading && params && params.modelId) {
            (getModel)({ pageState, setPageState, id: params.modelId });
        }
        if (pageState.updateLoading && params && params.modelId) {
            (updateModel)({ pageState, setPageState, id: params.modelId });
        }
        if (pageState.deleteLoading && params && params.modelId) {
            (deleteModels)({ pageState, setPageState, id: params.modelId, app });
        }
        if (pageState.updateYearLoading && params && params.modelId) {
            (addModelYears)({ pageState, setPageState, id: params.modelId, app });
        }
        if (pageState.deleteYearLoading && params && params.modelId) {
            (removeModelYear)({ pageState, setPageState, id: params.modelId, app });
        }
    }, [
        pageState.loading,
        pageState.updateLoading,
        pageState.deleteLoading,
        pageState.openYearSheet,
        pageState.updateYearLoading,
        pageState.deleteYearLoading,
        pageState.confirmYearDelete,
        pageState.confirmDelete,
        pageState,
        props
    ]);

    const submitForm = () => setPageState({
        ...pageState,
        updateLoading: true
    });

    const handleDelete = () => setPageState({
        ...pageState,
        confirmDelete: true
    });

    const handleCloseSheet = (loading=false) =>{
        return  setPageState({
            ...pageState,
            openYearSheet: false,
            selectedYear: null,
            yearAction: null,
            loading
        })
    };

    const handleOpenSheet = () => setPageState({
        ...pageState,
        openYearSheet: true,
        yearAction: 'create'
    });

    const handleOpenSheetUpdate = (selectedId) => setPageState({
        ...pageState,
        openYearSheet: true,
        selectedYear: pageState.model.Years.find(({ id }) => id === selectedId),
        yearAction: 'update'
    })

    const handleYearModalOpen = (selectedId) => setPageState({
        ...pageState,
        selectedYear: pageState.model.Years.find(({ id }) => id === selectedId),
        confirmYearDelete: true
    });

    const handleYearDeleteCancel = () => setPageState({
        ...pageState,
        selectedYear: null,
        confirmYearDelete: false
    });

    const handleYearDeleteClose = () => setPageState({
        ...pageState,
        selectedYear: null,
        confirmYearDelete: false
    });

    const deleteYear = () => setPageState({
        ...pageState,
        deleteYearLoading: true,
        confirmYearDelete: false
    });

    const handleDeleteClose = () => setPageState({
        ...pageState,
        confirmDelete: false
    });

    const handleDeleteCancel = () => setPageState({
        ...pageState,
        confirmDelete: false
    });

    const deleteYears = () => setPageState({
        ...pageState,
        deleteLoading: true,
        confirmDelete: false
    });

    const handleNameChange = (value) => setPageState({
        ...pageState,
        hasUpdate: true,
        model: {
            ...pageState.model,
            name: value
        }
    });

    const handleActiveChange = (value) => setPageState({
        ...pageState,
        hasUpdate: true,
        model: {
            ...pageState.model,
            active: value === 'active'
        }
    });

    const handleSlugChange = slug => setPageState({
        ...pageState,
        hasUpdate: true,
        slugSubmit: createSlug(slug),
        model: { ...pageState.model, slug }
    });

    const errorHandler = (field) => {
        const found = pageState.errors.find(({ path }) => path === field);
        return found && found.message ? found.message : false;
    };

    const renderItem = ({ id, year }) => (
        <ResourceList.Item
            id={id}
            media={<Avatar customer name={year}/>}
            shortcutActions={[
                {
                    content: 'manage',
                    url: `/app/settings/years/${id}`
                },
                {
                    content: 'edit',
                    onClick: () => handleOpenSheetUpdate(id)
                },
                {
                    content: 'delete',
                    destructive: true,
                    onClick: () => handleYearModalOpen(id)
                }
            ]}
        >
            <PageBanner>
                <TextStyle variation="strong">{year}</TextStyle>
            </PageBanner>
        </ResourceList.Item>
    );

    const options = [
        {
            label: 'Active',
            value: 'active'
        },
        {
            label: 'Inactive',
            value: 'inactive'
        }
    ];

    const {
        model,
        slugSubmit,
        showToast,
        yearAction,
        updateLoading,
        showToastDelete,
        hasUpdate,
        loading,
        resourceTitle,
        resourceSlug,
        openYearSheet,
        selectedYear,
        confirmYearDelete,
        deleteYearLoading,
        modelDisplay,
        confirmDelete,
        errors
    } = pageState;

    const makeButton = model && model.Make ? (
        <Button plain url={`/app/settings/makes/${model.Make.id}`}>{model.Make.name}</Button>
    ) : null;

    return (
        <Page
            title={`Edit`}
            breadcrumbs={[{
                content: resourceTitle,
                url: `/app/settings/${resourceSlug}`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        {model ? modelDisplay.name : <Spinner size="small" color="teal" />}
                        <ButtonGroup>
                            <Button
                                size="slim"
                                url={`/app/settings/models/new`}
                                outline
                            >
                                Create
                            </Button>
                            <Button
                                size="slim"
                                onClick={handleDelete}
                                destructive
                            >
                                Delete
                            </Button>
                        </ButtonGroup>
                    </DisplayText>
                    <TextStyle variation="subdued">{makeButton}</TextStyle>
                    <br/>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Save',
                            onClick: submitForm,
                            disabled: !hasUpdate,
                            loading: updateLoading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    <TextField
                                        type="text"
                                        label="Name"
                                        onChange={handleNameChange}
                                        value={model ? model.name : null}
                                        error={errorHandler('name')}
                                    />
                                    <TextField
                                        type="text"
                                        label="Slug"
                                        onChange={handleSlugChange}
                                        value={model ? model.slug : null}
                                        helpText={slugSubmit}
                                        error={errorHandler('slug')}
                                    />
                                    <Select
                                        label="Status"
                                        options={options}
                                        onChange={handleActiveChange}
                                        value={model ? model.active ? 'active' : 'inactive' : ''}
                                        error={errorHandler('active')}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                    </Card>
                    <Card
                        title={`Years`}
                        actions={[{
                            content: 'Create',
                            onClick: handleOpenSheet
                        }]}
                    >
                        <Card.Section>
                            <ResourceList
                                resourceName={{
                                    singular: 'year',
                                    plural: 'years'
                                }}
                                items={model ? model.Years : []}
                                renderItem={renderItem}
                                loading={loading}
                                showHeader={true}
                            />
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            <Frame>
                {!loading && !deleteYearLoading ? (
                    <YearSheet
                        id={model.id}
                        open={openYearSheet}
                        selectedYear={selectedYear}
                        handleCloseSheet={handleCloseSheet}
                        yearAction={yearAction}
                    />
                ): null}
                {!loading ?
                    <>
                        <Modal
                            open={confirmDelete || confirmYearDelete}
                            onClose={confirmDelete ? handleDeleteClose : handleYearDeleteClose}
                            title={confirmDelete ? `Delete Model` : `Remove Year`}
                            message={confirmDelete ? `Are you sure you want to DELETE this vehicle model listing?` :`Are you sure you want to REMOVE this year from ${model.name}?` }
                            primaryAction={{
                                content: confirmDelete ? 'Delete' : 'Remove',
                                onAction: confirmDelete ? deleteYears : deleteYear,
                                destructive: true
                            }}
                            secondaryActions={[{
                                content: 'Cancel',
                                onAction: confirmDelete ? handleDeleteCancel : handleYearDeleteCancel,
                            }]}
                        >
                        </Modal>
                    </>
                : null}
                {!loading && showToast ?
                    <Toast
                        content={`${resourceTitle} Updated`}
                        onDismiss={() => setPageState({ ...pageState, showToast: false })}
                    />
                : null}
                {!loading && showToastDelete ?
                    <Toast
                        content={`${resourceTitle} deleted`}
                        onDismiss={() => setPageState({ ...pageState, showToastDelete: false })}
                    />
                : null}
            </Frame>
        </Page>
    )
};

export default ModelDetail;

