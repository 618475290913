import React, { useState, useEffect } from "react"
import { Redirect } from "@shopify/app-bridge/actions"
import Axios from "axios"
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Stack,
    Toast,
    Checkbox,
    DisplayText,
    Button,
    ButtonGroup,
    Modal,
    Frame,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

const getDetails = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/equipment/${id}`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                name: data.name,
                description: data.description,
                status: data.status,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateDetails = async ({ pageState, setPageState, id }) => {
    try {
        const { name, description, status } = pageState;
        const { data } = await Axios({
            url: `/api/equipment/${id}`,
            method: 'PUT',
            data: {
                name,
                description,
                status
            }
        });
        if (data) {
            return setPageState({
                ...pageState,
                name: data.name,
                description: data.description,
                status: data.status,
                updateLoading: false,
                showToast: true,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            updateLoading: false,
            errors
        });
    }
};

const deleteDetails = async ({ pageState, setPageState, id, app }) => {
    try {
        const { data } = await Axios({
            url: `/api/equipment/${id}`,
            method: 'DELETE'
        });
        if (data) {
            const redirect = Redirect.create(app);
            return redirect.dispatch(Redirect.Action.APP, '/app/settings/equipment');
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            updateLoading: false,
            deleteLoading: false,
            errors
        });
    }
};

const EquipmentDetail = (props) => {

    const [pageState, setPageState] = useState({
        name: null,
        description: null,
        status: true,
        loading: true,
        showToast: false,
        updateLoading: false,
        deleteLoading: false,
        showToastDelete: false,
        hasUpdate: false,
        confirmDelete: false,
        resourceTitle: "Equipment",
        resourceSlug: "equipment",
        errors: []
    });

    useEffect(() => {
        const { match: { params }, app } = props;
        if (pageState.loading && params && params.equipmentId) {
            (getDetails)({ pageState, setPageState, id: params.equipmentId });
        }
        if (pageState.updateLoading && params && params.equipmentId) {
            (updateDetails)({ pageState, setPageState, id: params.equipmentId });
        }
        if (pageState.deleteLoading && params && params.equipmentId) {
            (deleteDetails)({ pageState, setPageState, id: params.equipmentId, app });
        }
    }, [
        pageState.loading,
        pageState.updateLoading,
        pageState.deleteLoading,
        pageState,
        props
    ]);

    const {
        name,
        description,
        status,
        showToast,
        updateLoading,
        showToastDelete,
        hasUpdate,
        confirmDelete,
        loading,
        resourceTitle,
        resourceSlug,
        errors
    } = pageState;

    const handleCheckBoxChange = () => setPageState({
        ...pageState,
        hasUpdate: true,
        status: !status
    });

    const handleNameChange = (value) => setPageState({
        ...pageState,
        hasUpdate: true,
        name: value
    });

    const handleDescriptionChange = (value) => setPageState({
        ...pageState,
        hasUpdate: true,
        description: value
    });

    const submitForm = () => setPageState({
        ...pageState,
        updateLoading: true
    });

    const handleDelete = () => setPageState({
        ...pageState,
        confirmDelete: true
    });

    const handleDeleteCancel = () => setPageState({
        ...pageState,
        confirmDelete: false
    });

    const deleteEquipment = () => setPageState({
        ...pageState,
        confirmDelete: false,
        deleteLoading: true
    });

    return (
        <Page
            title={`Edit`}
            breadcrumbs={[{
                content: resourceTitle,
                url: `/app/settings/${resourceSlug}`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Edit {resourceTitle}
                        <ButtonGroup>
                            <Button
                                size="slim"
                                url={`/app/settings/${resourceSlug}/new`}
                                outline
                            >
                                Create
                            </Button>
                            <Button
                                size="slim"
                                onClick={handleDelete}
                                destructive
                            >
                                Delete
                            </Button>
                        </ButtonGroup>
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Save',
                            onClick: submitForm,
                            disabled: !hasUpdate,
                            loading: updateLoading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <Stack vertical>
                                    <Checkbox
                                        checked={status}
                                        label="Enabled"
                                        onChange={handleCheckBoxChange}
                                    />
                                </Stack>
                                <TextField
                                    type="text"
                                    label="Name"
                                    onChange={handleNameChange}
                                    value={name}
                                />
                                <TextField
                                    type="text"
                                    label="Description"
                                    onChange={handleDescriptionChange}
                                    value={description}
                                    multiline={true}
                                />
                            </FormLayout>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            <Frame>
                {!loading ? (
                    <Modal
                        open={confirmDelete}
                        onClose={handleDeleteCancel}
                        title={`Delete ${resourceTitle}`}
                        message={`Are you sure you want to DELETE this ${resourceTitle} listing?`}
                        primaryAction={{
                            content: 'Delete',
                            onAction: deleteEquipment,
                            destructive: true
                        }}
                        secondaryActions={[{
                            content: 'Cancel',
                            onAction: handleDeleteCancel,
                        }]}
                    >
                    </Modal>
                ): null }
                {showToast ?
                    <Toast
                        content={`${resourceTitle} Updated`}
                        onDismiss={() => setPageState({ ...pageState, showToast: false })}
                    />
                : null}
                {showToastDelete ?
                    <Toast
                        content={`${resourceTitle} deleted`}
                        onDismiss={() => setPageState({ ...pageState, showToastDelete: false })}
                    />
                : null}
            </Frame>
        </Page>
    )
};

export default EquipmentDetail;

