import React, { useState, useEffect } from "react";
import { Layout, Banner, Toast, Frame } from "@shopify/polaris";

const ErrorMessages = ({ errors }) => {
  const [toast, setToastState] = useState(true);

  useEffect(() => {}, [toast]);
  console.log(errors);
  return errors && errors.length ? (
    <Layout>
      <Layout.Section>
        <br />
        <Banner title="Error" status="critical">
          {errors.map(({ message }, i) => (
            <p key={`error-message-${i + 1}`}>{message}</p>
          ))}
        </Banner>
        {toast ? (
          <Frame>
            <Toast
              content={`Error ${
                errors.length && errors[0].status ? errors[0].status : "500"
              }`}
              error
              onDismiss={() => setToastState(!toast)}
            />
          </Frame>
        ) : null}
      </Layout.Section>
    </Layout>
  ) : null;
};

export default ErrorMessages;
