import React, { useState, useEffect } from "react"
import Axios from "axios"
import createSlug from "slug"
import {
    FormLayout,
    Button,
    Sheet,
    TextField,
    Heading,
    Scrollable,
    Select,
    Toast,
} from "@shopify/polaris"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const createModelApi = async ({ setPageState, pageState, handleCloseSheet, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes/${id}/models`,
            method: 'POST',
            data: {
                name: pageState.name,
                slug: pageState.slugSubmit,
                active: pageState.active
            }
        });
        if (data) {
            await setPageState({
                ...pageState,
                name: null,
                slug: null,
                slugSubmit: null,
                active: true,
                loading: false,
                success: true,
                showToast: true,
                errors: []
            });
            return handleCloseSheet();
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateModelApi = async ({ setPageState, pageState, handleCloseSheet, selectedModel }) => {
    try {
        const { data } = await Axios({
            url: `/api/models/${selectedModel.id}`,
            method: 'PUT',
            data: {
                name: pageState.name,
                slug: pageState.slugSubmit,
                active: pageState.active
            }
        });
        if (data) {
            await setPageState({
                ...pageState,
                loadingUpdate: false,
                success: true,
                showToast: true,
                errors: []
            });
            return handleCloseSheet();
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingUpdate: false,
            errors
        });
    }
};

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    align-items: center;
    border-bottom: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const ModelSheet = (props) => {

    const [pageState, setPageState] = useState({
        name: null,
        active: true,
        slug: null,
        slugSubmit: null,
        loading: false,
        success: false,
        showToast:false,
        selectedModel: null,
        loadingUpdate: false,
        errors: []
    });

    useEffect(() => {
        const {
            id,
            handleCloseSheet,
            selectedModel,
            modelAction
        } = props;
        if (selectedModel
            && modelAction === 'update'
            && !pageState.loading
            && !pageState.name) {
            setPageState({
                ...pageState,
                name: selectedModel.name,
                slug: selectedModel.slug,
                slugSubmit: selectedModel.slug,
                active: selectedModel.active,
            });
        }
        if (pageState.loading) {
            (createModelApi)({
                pageState,
                setPageState,
                handleCloseSheet,
                id
            });
        }
        if (pageState.loadingUpdate) {
            (updateModelApi)({
                pageState,
                setPageState,
                handleCloseSheet,
                selectedModel,
                id
            });
        }
    }, [
        pageState.name,
        pageState.active,
        pageState.loading,
        pageState.showToast,
        pageState,
        props
    ]);


    const handleNameChange = name => setPageState({
        ...pageState, name
    });

    const handleSlugChange = slug => setPageState({
        ...pageState, slug, slugSubmit: createSlug(slug)
    });

    const handleActiveChange = value => setPageState({
        ...pageState,
        active: value === 'active'
    });

    const handleUpdate = () => setPageState({
        ...pageState,
        loadingUpdate: true
    });

    const handleCreate = () => setPageState({
        ...pageState,
        loading: true
    });

    const errorHandler = (field) => {
        const found = pageState.errors.find(({ path }) => path === field);
        return found && found.message ? found.message : false;
    };

    const options = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' }
    ];

    const {
        open,
        handleCloseSheet,
        modelAction
    } = props;

    const {
        name,
        slug,
        slugSubmit,
        active,
        loading,
        showToast,
        errors
    } = pageState;

    const typeDisplayText = modelAction === 'create' ? `Create` : `Update`;
    const actionHandler = modelAction === 'create' ? handleCreate : handleUpdate;

    return (
        <>
            <Sheet
                open={open}
                onClose={handleCloseSheet}
            >
                <SheetWrap>
                    <SheetHeader>
                        <Heading>{typeDisplayText} Model</Heading>
                        <Button
                            accessibilityLabel="Cancel"
                            onClick={handleCloseSheet}
                            plain
                        />
                    </SheetHeader>
                    <StyledScrollable>
                        <FormLayout>
                            <ErrorMessages errors={errors}/>
                            <FormLayout.Group condensed>
                                <TextField
                                    type="text"
                                    label="Name"
                                    onChange={handleNameChange}
                                    value={name}
                                    error={errorHandler('name')}
                                />
                                <TextField
                                    type="text"
                                    label="Slug"
                                    onChange={handleSlugChange}
                                    value={slug}
                                    helpText={slugSubmit}
                                    error={errorHandler('slug')}
                                />
                                <Select
                                    label="Status"
                                    options={options}
                                    onChange={handleActiveChange}
                                    value={active ? 'active' : 'inactive'}
                                    error={errorHandler('active')}
                                />
                            </FormLayout.Group>
                        </FormLayout>
                    </StyledScrollable>
                    <SheetFooter>
                        <Button onClick={handleCloseSheet}>Cancel</Button>
                        <Button primary onClick={actionHandler}>{typeDisplayText}</Button>
                    </SheetFooter>
                </SheetWrap>
            </Sheet>
            {!loading && showToast ?
                <Toast
                    content={`Model ${typeDisplayText}`}
                    onDismiss={() => setPageState({ ...pageState, showToast: false })}
                />
            : null}
        </>
    )
};

export default ModelSheet;

