import React, { useState, useEffect } from "react"
import {
    Page,
    Layout,
    Badge,
    FooterHelp,
    DisplayText,
    ResourcePicker,
    CalloutCard,
} from "@shopify/polaris"

import ProductFields from "../../components/products/ProductFields"
import CompanionFields from "../../components/products/CompanionFields"
import ProtoAccessoryFields from "../../components/products/ProtoAccessoryFields"

const productTypeBadge = (selected) => {

    console.log('Graham');
    let result = null;
    ;
    if ( selected.tags.indexOf('product-type--bumper') !== -1 ) {
        result = <Badge status="success">Bumper Product</Badge>;
    } else if ( selected.tags.indexOf('product-type--companion') !== -1 ) {
        result = <Badge status="info">Companion Product</Badge>;
    } else if ( selected.tags.indexOf('product--mmy') !== -1 ) {
        result = <Badge status="warning">Accessory / Proto</Badge>;
    } else if ( selected.tags.indexOf('product-type--accessory') !== -1 ) {
        result = <Badge status="warning">Accessory Product</Badge>;
    } else {
        result = <Badge status="attention">Unassigned Product</Badge>;
    }
    return (result);
};


const isParentProduct = (selected) => {
    let result = false;
    if ( selected.tags.indexOf('product-type--bumper') !== -1 ) {
        result = true;
    }
    return result;
};

const isCompanionProduct = (selected) => {
    let result = false;
    if ( selected.tags.indexOf('product-type--companion') !== -1 ) {
        result = true;
    }
    return result;
};

const isProtoAccessoryProduct = (selected) => {
    let result = false;
    if ( selected.tags.indexOf('product--mmy') !== -1 ) {
        result = true;
    }
    return result;
};


const ProductConfigurator = (props) => {

    const [ pageState, setPageState ] = useState({
        open: false,
        selection: null,
        errors: []
    });

    useEffect(() => {
    }, [ pageState.open ]);

    const setProduct = ({ selection }) => setPageState({
        ...pageState,
        open: false,
        selection: selection[0]
    });

    const openPicker = () => setPageState({
        ...pageState,
        open: true,
        selection: null
    });

    const {
        open,
        selection,
    } = pageState;

    const isParent = selection ? isParentProduct(selection) : false;
    const isCompanion = selection ? isCompanionProduct(selection) : false;
    const isProtoAccessory = selection ? isProtoAccessoryProduct(selection) : false;

    console.log(`Is selection ${ selection } - Is companion ${ isCompanion } - Is accessory ${ isProtoAccessory } - Is parent ${ isParent }`);

    return (
        <Page
            title="Configure Product"
            breadcrumbs={ [ {
                content: 'Settings',
                url: `/app/settings`
            } ] }
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Configure Product
                    </DisplayText>
                </Layout.Section>
                <Layout.Section>
                    <CalloutCard
                        title={ selection ? selection.title : 'Select a Product' }
                        illustration={ selection && selection.images.length ? selection.images[0].originalSrc : "/images/shopify.png" }
                        primaryAction={ {
                            content: selection ? 'Change product' : 'Select product',
                            onClick: openPicker
                        } }
                    >
                        { selection ? productTypeBadge(selection) : null }
                    </CalloutCard>
                </Layout.Section>
                { selection && isParent ? (
                    <Layout.Section>
                        <ProductFields
                            selectedProduct={ selection }
                        />
                    </Layout.Section>
                ) : null }
                { selection && isCompanion ? (
                    <Layout.Section>
                        <CompanionFields
                            selectedProduct={ selection }
                        />
                    </Layout.Section>
                ) : null }
                { selection && isProtoAccessory ? (
                    <Layout.Section>
                        <ProtoAccessoryFields
                            selectedProduct={ selection }
                        />
                    </Layout.Section>
                ) : null }
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            <ResourcePicker
                resourceType="Product"
                open={ open }
                onSelection={ setProduct }
                allowMultiple={ false }
                showVariants={ false }
            />
        </Page>
    )
};

export default ProductConfigurator;
