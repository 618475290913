import React, { useState, useEffect } from "react"
import Axios from "axios"
import {
    Card,
    Sheet,
    Scrollable,
    Button,
    TextContainer,
    Heading,
    FormLayout,
    TextField,
    Banner,
} from "@shopify/polaris"
import {
    MobileCancelMajorMonotone,
} from "@shopify/polaris-icons"
import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const getAddress = async ({ setAddressState, addressState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/addresses/default`,
            method: 'GET'
        });
        if (data) {
            return setAddressState({
                ...addressState,
                addressDisplay: data,
                address: data,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setAddressState({
            ...addressState,
            loading: false,
            errors
        });
    }
};

const updateAddress = async ({ setAddressState, addressState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/addresses/${addressState.address.id}`,
            method: 'PUT',
            data: addressState.address
        });
        if (data) {
            return setAddressState({
                ...addressState,
                address: data,
                addressDisplay: data,
                sheetActive: false,
                updateLoading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setAddressState({
            ...addressState,
            sheetActive: false,
            updateLoading: false,
            errors
        });
    }
};

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    align-items: center;
    border-bottom: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const Addresses = ({ id }) => {

    const [addressState, setAddressState] = useState({
        selected: [],
        address: null,
        addressDisplay: null,
        fabricatorEquipment: [],
        updateLoading: false,
        sheetActive: false,
        loading: true,
        errors: []
    });

    useEffect(() => {
        if(addressState.loading){
            (getAddress)({ addressState, setAddressState, id });
        }
        if(addressState.updateLoading){
            (updateAddress)({ addressState, setAddressState, id });
        }
    }, [
        id,
        addressState.sheetActive,
        addressState.loading,
        addressState.updateLoading,
        addressState
    ]);

    const handleUpdate = () => setAddressState({
        ...addressState,
        updateLoading: true
    });

    const handleCloseSheet = () => setAddressState({
        ...addressState,
        sheetActive: false
    });

    const handleOpenSheet = () => setAddressState({
        ...addressState,
        sheetActive: true
    });

    const handleAddress1 = (street_address1) => setAddressState({
        ...addressState,
        address: {
            ...addressState.address,
            street_address1
        }
    });

    const handleAddress2 = (street_address2) => setAddressState({
        ...addressState,
        address: {
            ...addressState.address,
            street_address2
        }
    });

    const handleCity = (city) => setAddressState({
        ...addressState,
        address: {
            ...addressState.address,
            city
        }
    });

    const handleState = (state) => setAddressState({
        ...addressState,
        address: {
            ...addressState.address,
            state
        }
    });

    const handleCountry = (country) => setAddressState({
        ...addressState,
        address: {
            ...addressState.address,
            country
        }
    });

    const handleZip = (zip) => setAddressState({
        ...addressState,
        address: {
            ...addressState.address,
            zip
        }
    });


    const handlePhone = (phone) => setAddressState({
        ...addressState,
        address: {
            ...addressState.address,
            phone
        }
    });

    const {
        loading,
        address,
        addressDisplay,
        sheetActive,
        errors
    } = addressState;

    return (
        <>
            <ErrorMessages errors={errors}/>
            <Card
                sectioned
                title="Address"
                actions={[{
                    content: 'Edit',
                    onAction: handleOpenSheet
                }]}
            >
                {!loading && address ?
                    <>
                        {!addressDisplay.lat || !addressDisplay.long ? (
                            <>
                                <Banner
                                    title="Invalid Address"
                                    status="warning"
                                >
                                    <p>Invalid latitude and longitude</p>
                                </Banner>
                                <br/>
                            </>
                        ) : null}
                        <TextContainer spacing="tight">
                            <p>{addressDisplay.street_address1}</p>
                            {addressDisplay.street_address2 ? <p>{addressDisplay.street_address2}</p> : null}
                            <p>{addressDisplay.city}, {addressDisplay.state}</p>
                            <p>{addressDisplay.zip}</p>
                            <p>{addressDisplay.country}</p>
                            {addressDisplay.phone ? <p>{addressDisplay.phone}</p> : null}
                        </TextContainer>
                    </>
                : null}
            </Card>
            {!loading && address ?
                <Sheet
                    open={sheetActive}
                    onClose={handleCloseSheet}
                >
                    <SheetWrap>
                        <SheetHeader>
                            <Heading>Manage Address</Heading>
                            <Button
                                accessibilityLabel="Cancel"
                                icon={MobileCancelMajorMonotone}
                                onClick={handleCloseSheet}
                                plain
                            />
                        </SheetHeader>
                        <StyledScrollable>
                            <FormLayout>
                                <TextField
                                    label="Street address 1"
                                    value={address.street_address1}
                                    onChange={handleAddress1}
                                />
                                <TextField
                                    label="Street address 2"
                                    value={address.street_address2}
                                    onChange={handleAddress2}
                                />
                                <TextField
                                    label="City"
                                    value={address.city}
                                    onChange={handleCity}
                                />
                                <TextField
                                    label="State"
                                    value={address.state}
                                    onChange={handleState}
                                />
                                <TextField
                                    label="Country"
                                    value={address.country}
                                    onChange={handleCountry}
                                />
                                <TextField
                                    label="Zip"
                                    value={address.zip}
                                    onChange={handleZip}
                                />
                                <TextField
                                    label="Phone"
                                    value={address.phone}
                                    onChange={handlePhone}
                                />
                            </FormLayout>
                        </StyledScrollable>
                        <SheetFooter>
                            <Button onClick={handleCloseSheet}>Cancel</Button>
                            <Button primary onClick={handleUpdate}>Update</Button>
                        </SheetFooter>
                    </SheetWrap>
                </Sheet>
            : null}
        </>
    )
}

export default Addresses;

