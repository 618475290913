import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

class RouterLink extends Component {
    static contextTypes = {
        polaris: PropTypes.object
    };

    render() {
        const {children, url, ...rest} = this.props;
        return (
            <Link to={url} {...rest}>
                {children}
            </Link>
        );
    }
}

export default RouterLink;