import React, { useState, useEffect } from "react"
import { Redirect } from "@shopify/app-bridge/actions"
import Axios from "axios"
import createSlug from "slug"
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Toast,
    DisplayText,
    Button,
    ButtonGroup,
    Modal,
    ResourceList,
    Avatar,
    TextStyle,
    Frame,
    Select,
    Spinner,
} from "@shopify/polaris"

import ModelSheet from "../../components/makes/ModelSheet"
import ModelYearItems from "../../components/makes/ModelYearItems"

const getMake = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes/${id}`,
            method: 'GET'
        });
        const { data: allModelData } = await Axios({
            url: `/api/models?only_available=true&limit=200`,
            method: 'GET'
        });
        if (data && allModelData) {
            const displayingIds = new Set(data.Models.map(({ id }) => id));
            return setPageState({
                ...pageState,
                make: data,
                slugSubmit: data.slug,
                makeDisplay: data,
                allModelData: allModelData.models.filter(({ id }) => !displayingIds.has(id)),
                loading: false
            });
        }
    } catch (error) {
        return setPageState({
            ...pageState,
            loading: false,
            errors: [error]
        });
    }
};

const updateMake = async ({ pageState, setPageState, id }) => {
    try {
        console.log({
            ...pageState.make,
            slug: pageState.slugSubmit
        })
        const { data } = await Axios({
            url: `/api/makes/${id}`,
            method: 'PUT',
            data: {
                ...pageState.make,
                slug: pageState.slugSubmit
            }
        });
        if (data) {
            return setPageState({
                ...pageState,
                make: data,
                makeDisplay: data,
                hasUpdate: false,
                updateLoading: false,
                showToast: true
            });
        }
    } catch (error) {
        return setPageState({
            ...pageState,
            updateLoading: false,
            errors: [error]
        });
    }
};

const addMakeModels = async ({ pageState, setPageState, id }) => {
    try {
        const { selected } = pageState;
        const { data } = await Axios({
            url: `/api/makes/${id}/models`,
            method: 'PUT',
            data: { selected }
        });
        if (data) {
            return setPageState({
                ...pageState,
                selected: [],
                updateModelLoading: false,
                loading: true,
                sheetActive: false
            });
        }
    } catch (error) {
        return setPageState({
            ...pageState,
            updateModelLoading: false,
            sheetActive: false,
            errors: []
        });
    }
};

const deleteMake = async ({ pageState, setPageState, id, app }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes/${id}`,
            method: 'DELETE'
        });
        if (data) {
            const redirect = Redirect.create(app);
            return redirect.dispatch(Redirect.Action.APP, '/app/settings/makes');
        }
    } catch (error) {
        return setPageState({
            ...pageState,
            updateLoading: false,
            errors: []
        });
    }
};

const deleteMakeModel = async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/models/${pageState.selectedModel.id}`,
            method: 'DELETE',
        });
        if (data) {
            return setPageState({
                ...pageState,
                selected: [],
                deleteModelLoading: false,
                confirmModelDelete: false,
                loading: true
            });
        }
    } catch (error) {
        console.log(error);
        return setPageState({
            ...pageState,
            confirmModelDelete: false,
            deleteModelLoading: false,
            errors: []
        });
    }
};

const MakeDetail = (props) => {

    const [pageState, setPageState] = useState({
        make: null,
        slugSubmit: null,
        selected: [],
        selectedModel: null,
        makeDisplay: null,
        allModelData: [],
        loading: true,
        showToast: false,
        updateLoading: false,
        updateModelLoading: false,
        deleteLoading: false,
        deleteModelLoading: false,
        showToastDelete: false,
        hasUpdate: false,
        confirmDelete: false,
        confirmModelDelete: false,
        sheetActive: false,
        openModelSheet: false,
        openYearSheet: true,
        modelAction: null,
        resourceTitle: "Makes",
        resourceSlug: "makes",
        errors: []
    });

    useEffect(() => {
        const { match: { params }, app } = props;
        if (pageState.loading && params && params.makeId) {
            (getMake)({ pageState, setPageState, id: params.makeId });
        }
        if (pageState.updateLoading && params && params.makeId) {
            (updateMake)({ pageState, setPageState, id: params.makeId });
        }
        if (pageState.deleteLoading && params && params.makeId) {
            (deleteMake)({ pageState, setPageState, id: params.makeId, app });
        }
        if (pageState.updateModelLoading && params && params.makeId) {
            (addMakeModels)({ pageState, setPageState, id: params.makeId, app });
        }
        if (pageState.deleteModelLoading && params && params.makeId) {
            (deleteMakeModel)({ pageState, setPageState, id: params.makeId, app });
        }
    }, [
        pageState.loading,
        pageState.updateLoading,
        pageState.deleteLoading,
        pageState.sheetActive,
        pageState.updateModelLoading,
        pageState.deleteModelLoading,
        pageState.confirmModelDelete,
        pageState.confirmDelete,
        pageState,
        props
    ]);

    const submitForm = () => setPageState({
        ...pageState,
        updateLoading: true
    });

    const handleDelete = () => setPageState({
        ...pageState,
        confirmDelete: true
    });

    const handleModelModalOpen = selectedId => setPageState({
        ...pageState,
        selectedModel: pageState.make.Models.find(({ id }) => id === selectedId),
        confirmModelDelete: true
    });

    const handleModelDeleteCancel = () => setPageState({
        ...pageState,
        selectedModel: null,
        confirmModelDelete: false
    });

    const handleModelDeleteClose = () => setPageState({
        ...pageState,
        selectedModel: null,
        confirmModelDelete: false
    });

    const deleteModel = () => setPageState({
        ...pageState,
        deleteModelLoading: true,
        confirmModelDelete: false
    });

    const handleDeleteClose = () => setPageState({
        ...pageState,
        confirmDelete: false
    });

    const handleDeleteCancel = () => setPageState({
        ...pageState,
        confirmDelete: false
    });

    const deleteMakes = () => setPageState({
        ...pageState,
        deleteLoading: true,
        confirmDelete: false
    });

    const handleOpenModelSheetCreate = () => setPageState({
        ...pageState,
        openModelSheet: true,
        modelAction: 'create'
    });

    const handleOpenModelSheetUpdate = selectedId => setPageState({
        ...pageState,
        selectedModel: pageState.make.Models.find(({ id }) => id === selectedId),
        openModelSheet: true,
        modelAction: 'update'
    });

    const handleCloseModelSheet = () => setPageState({
        ...pageState,
        loading: true,
        openModelSheet: false,
        modelAction: null
    });

    const handleCloseYearSheet = () => setPageState({
        ...pageState,
        loading: true,
        selectedModel: null,
        openYearSheet: false
    });

    const handleNameChange = name => setPageState({
        ...pageState,
        hasUpdate: true,
        make: { ...pageState.make, name }
    });

    const handleSlugChange = slug => setPageState({
        ...pageState,
        hasUpdate: true,
        slugSubmit: createSlug(slug),
        make: { ...pageState.make, slug }
    });

    const handleActiveChange = value => setPageState({
        ...pageState,
        hasUpdate: true,
        make: {
            ...pageState.make,
            active: value === 'active'
        }
    });

    const errorHandler = (field) => {
        const found = pageState.errors.find(({ path }) => path === field);
        return found ? found.message || 'Invalid value' : false;
    };

    const renderItem = ({ id, name, Years }, i) => (
        <ResourceList.Item
            id={id}
            key={`model-item-${i + 1}`}
            media={<Avatar customer name={name}/>}
            shortcutActions={[
                {
                    content: 'manage',
                    url: `/app/settings/models/${id}`
                },
                {
                    content: 'update',
                    onClick:() =>  handleOpenModelSheetUpdate(id)
                },
                {
                    content: 'delete',
                    destructive: true,
                    onClick: () => handleModelModalOpen(id)
                }
            ]}
        >
            <h3 style={{"display":"inline-block","verticalAlign":"middle"}}>
                <TextStyle variation="strong">{name}</TextStyle>
                <br/>
            </h3>
            <ModelYearItems
                modelId={id}
                handleCloseYearSheet={handleCloseYearSheet}
            />
        </ResourceList.Item>
    );

    const options = [
        {
            label: 'Active',
            value: 'active'
        },
        {
            label: 'Inactive',
            value: 'inactive'
        }
    ];

    const {
        make,
        slugSubmit,
        showToast,
        updateLoading,
        showToastDelete,
        hasUpdate,
        loading,
        resourceTitle,
        resourceSlug,
        confirmModelDelete,
        deleteModelLoading,
        makeDisplay,
        confirmDelete,
        openModelSheet,
        modelAction,
        selectedModel
    } = pageState;

    return (
        <Page
            title={`Edit`}
            breadcrumbs={[{
                content: resourceTitle,
                url: `/app/settings/${resourceSlug}`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        {make ? makeDisplay.name : <Spinner size="small" color="teal" />}
                        <ButtonGroup>
                            <Button
                                size="slim"
                                url={`/app/settings/makes/new`}
                                outline
                            >
                                Create
                            </Button>
                            <Button
                                size="slim"
                                onClick={handleDelete}
                                destructive
                            >
                                Delete
                            </Button>
                        </ButtonGroup>
                    </DisplayText>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Save',
                            onClick: submitForm,
                            disabled: !hasUpdate,
                            loading: updateLoading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField
                                        type="text"
                                        label="Name"
                                        onChange={handleNameChange}
                                        value={make ? make.name : null}
                                        error={errorHandler('name')}
                                    />
                                    <TextField
                                        type="text"
                                        label="Slug"
                                        onChange={handleSlugChange}
                                        value={make ? make.slug : null}
                                        helpText={slugSubmit}
                                        error={errorHandler('slug')}
                                    />
                                    <Select
                                        label="Status"
                                        options={options}
                                        onChange={handleActiveChange}
                                        value={make ? make.active ? 'active' : 'inactive' : ''}
                                        error={errorHandler('active')}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                    </Card>
                    <Card
                        title={`Models`}
                        actions={[{
                            content: 'Create Model',
                            onClick: handleOpenModelSheetCreate
                        }]}
                    >
                        <Card.Section>
                            <ResourceList
                                resourceName={{
                                    singular: 'model',
                                    plural: 'models'
                                }}
                                items={make ? make.Models : []}
                                renderItem={renderItem}
                                loading={loading}
                                showHeader={true}
                            />
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            <Frame>
                {!loading && !deleteModelLoading && modelAction ? (
                    <ModelSheet
                        id={make.id}
                        open={openModelSheet}
                        handleCloseSheet={handleCloseModelSheet}
                        modelAction={modelAction}
                        selectedModel={selectedModel}
                    />
                ): null}
                {!loading ?
                    <>
                        <Modal
                            open={confirmDelete || confirmModelDelete}
                            onClose={confirmDelete ? handleDeleteClose : handleModelDeleteClose}
                            title={confirmDelete ? `Delete Make` : `Remove Model`}
                            message={confirmDelete ? `Are you sure you want to DELETE this vehicle make listing?` :`Are you sure you want to REMOVE this vehicle model from ${make.name}?` }
                            primaryAction={{
                                content: confirmDelete ? 'Delete' : 'Remove',
                                onAction: confirmDelete ? deleteMakes : deleteModel,
                                destructive: true
                            }}
                            secondaryActions={[{
                                content: 'Cancel',
                                onAction: confirmDelete ? handleDeleteCancel : handleModelDeleteCancel,
                            }]}
                        >
                        </Modal>
                    </>
                : null}
                {!loading && showToast ?
                    <Toast
                        content={`Make Updated`}
                        onDismiss={() => setPageState({ ...pageState, showToast: false })}
                    />
                : null}
                {!loading && showToastDelete ?
                    <Toast
                        content={`Make deleted`}
                        onDismiss={() => setPageState({ ...pageState, showToastDelete: false })}
                    />
                : null}
            </Frame>
        </Page>
    )
};

export default MakeDetail;

