import React, { useState, useEffect } from "react"
import Axios from "axios"
import createSlug from "slug"
import {
    Page,
    Layout,
    Card,
    FooterHelp,
    FormLayout,
    TextField,
    Select,
    Toast,
    DisplayText,
    Frame,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

const createMakeApi = async ({ setPageState, pageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes`,
            method: 'POST',
            data: {
                name: pageState.name,
                slug: pageState.slugSubmit,
                active: pageState.active
            }
        });
        if (data) {
            return setPageState({
                ...pageState,
                name: null,
                active: true,
                slug: null,
                slugSubmit: null,
                loading: false,
                success: true,
                errors: []
            });
        }
        return setPageState({
            ...pageState,
            loading: false,
            success: false,
            errors: []
        });
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];

        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const MakeCreate = (props) => {

    const [pageState, setPageState] = useState({
        name: null,
        slug: null,
        slugSubmit: null,
        active: true,
        loading: false,
        success: false,
        errors: []
    });

    useEffect(() => {
        if (pageState.loading) {
            (createMakeApi)({
                pageState,
                setPageState
            });
        }
    }, [
        pageState.name,
        pageState.active,
        pageState.loading,
        pageState
    ]);

    const handleSlugChange = slug =>  {
        return setPageState({
            ...pageState, slug, slugSubmit: createSlug(slug)
        });
    };

    const handleNameChange = name => setPageState({
        ...pageState, name
    });

    const handleActiveChange = value => setPageState({
        ...pageState,
        active: value === 'active'
    });

    const submitForm = () => setPageState({
        ...pageState,
        loading: true
    });

    const toggleToast = () => setPageState({
        ...pageState,
        success: false
    });

    const errorHandler = (field) => {
        const found = pageState.errors.find(({ path }) => path === field);
        return found && found.message ? found.message : false;
    };

    const options = [
        {label: 'Active',value: 'active'},
        {label: 'Inactive',value: 'inactive'}
    ];

    const {
        name,
        slug,
        slugSubmit,
        active,
        loading,
        success,
        errors
    } = pageState;

    return (
        <Page
            title="Create"
            breadcrumbs={[{
                content: 'Makes',
                url: '/app/settings/makes'
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Create Make
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card
                        primaryFooterAction={{
                            content: 'Create',
                            onClick: submitForm,
                            disabled: !name,
                            loading
                        }}
                    >
                        <Card.Section>
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    <TextField
                                        type="text"
                                        label="Name"
                                        onChange={handleNameChange}
                                        value={name}
                                        error={errorHandler('name')}
                                    />
                                    <TextField
                                        type="text"
                                        label="Slug"
                                        onChange={handleSlugChange}
                                        value={slug}
                                        helpText={slugSubmit}
                                        error={errorHandler('slug')}
                                    />
                                    <Select
                                        label="Status"
                                        options={options}
                                        onChange={handleActiveChange}
                                        value={active ? 'active' : 'inactive'}
                                        error={errorHandler('active')}
                                    />
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
            {success ? (<Frame><Toast content="Make Created" onDismiss={toggleToast} /></Frame>) : null}
        </Page>
    )
};

export default MakeCreate;

