import React, { useState, useEffect} from "react"
import Axios from "axios"
import {
    Card,
    Toast,
    Layout,
    Button,
    Frame,
} from "@shopify/polaris"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const UpdateFlex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`;

const createFile = async ({ setLoading, setErrors, setIsToasting }) => {
    try {
        await Axios({
            url: `/api/shopify-files`,
            method: 'POST'
        });
        await setErrors([]);
        await setLoading(false);
        await setIsToasting(true);
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        await setIsToasting(false);
        await setLoading(false);
        await setErrors(errors);
    }
};

const CreateFile = () => {

    const [loading, setLoading] = useState(false);
    const [isToasting, setIsToasting] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (loading) {
            (createFile)({ setLoading, setErrors, setIsToasting })
        }
    }, [loading]);

    const updateFile = () => setLoading(true);

    const toastMarkup = isToasting ? (
        <Toast content="File updated" onDismiss={() => setIsToasting(false)} />
    ) : null;

    return (
        <Layout>
            <ErrorMessages errors={errors}/>
            <Layout.AnnotatedSection
                title="Store JSON file"
                description="Update the file in the active Shopify theme for Make / Model / Years"
            >
                <Card
                    title="Update JSON"
                >
                    <Card.Section>
                        <UpdateFlex>
                            <Button
                                onClick={updateFile}
                                loading={loading}
                            >
                                Update
                            </Button>
                        </UpdateFlex>
                    </Card.Section>
            </Card>
            <br/>
            <br/>
            <Frame>
                {toastMarkup}
            </Frame>
            </Layout.AnnotatedSection>
        </Layout>
    )
};

export default CreateFile;

