import React, { useState, useEffect} from 'react'
import Axios from 'axios';
import {
    Page,
    ResourceList,
    Layout,
    TextStyle,
    Card,
    FooterHelp,
    EmptyState,
    DisplayText,
    Avatar,
    Pagination,
    ButtonGroup,
    Button,
} from '@shopify/polaris'

import ErrorMessages from "../../components/ErrorMessages";

const getYears = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/years?page=${pageState.page}`,
            method: 'GET'
        });
        if (data) {
            const {
                years,
                hasNext,
                hasPrevious
            } = data;
            return setPageState({
                ...pageState,
                years: years,
                hasPrevious,
                hasNext,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const YearsList = (props) => {

    const [pageState, setPageState] = useState({
        years: [],
        page: 1,
        loading: true,
        hasPrevious: false,
        hasNext: false,
        errors: []
    });

    useEffect(() => {
        if (window) { window.scrollTo(0, 0); }
        if (pageState.loading) {
            (getYears)({ pageState, setPageState });
        }
    }, [pageState.loading, pageState, props]);

    const nextPage = () => setPageState({
        ...pageState,
        loading: true,
        page: pageState.page + 1
    });

    const prevPage = () => setPageState({
        ...pageState,
        loading: true,
        page: pageState.page - 1
    });

    const modelName = Model => Model ? ` / ${Model.name}` : null;

    const makeName = Model => Model && Model.Make ? Model.Make.name : null;

    const renderItem = ({ id, year, Model }) => (
        <ResourceList.Item
            id={id}
            url={`/app/settings/years/${id}`}
            media={<Avatar name={year} source="/images/calendar.png"/>}
        >
            <h3 style={{"display":"inline-block","verticalAlign":"middle"}}>
                <TextStyle variation="strong">{year}</TextStyle>
            </h3>
            <br/>
            <TextStyle variation="subdued">{makeName(Model)}{modelName(Model)}</TextStyle>
        </ResourceList.Item>
    );

    const {
        years,
        loading,
        hasPrevious,
        hasNext,
        errors
    } = pageState;

    return (
        <Page
            title="Years"
            breadcrumbs={[{
                content: 'Settings',
                url: `/app/settings`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Years
                        <ButtonGroup>
                            <Button
                                size="slim"
                                url={`/app/settings/years/new`}
                                outline
                            >
                                Create
                            </Button>
                        </ButtonGroup>
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card>
                        <ResourceList
                            resourceName={{
                                singular: 'year',
                                plural: 'years'
                            }}
                            items={years}
                            renderItem={renderItem}
                            loading={loading}
                            showHeader={true}
                        />
                    </Card>
                    <div style={{ textAlign: 'center' }}>
                        <br/>
                        <Pagination
                            hasPrevious={hasPrevious}
                            onPrevious={prevPage}
                            hasNext={hasNext}
                            onNext={nextPage}
                        />
                    </div>
                    {!years.length && !loading ?
                        <EmptyState
                            heading="No Years"
                            image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                        >
                            <p>Mo vehicle years created.</p>
                        </EmptyState>
                    : null}
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    )
};

export default YearsList;

