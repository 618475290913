import React, { useState, useEffect} from "react"
import Axios from "axios";
import {
    Page,
    ResourceList,
    Layout,
    TextStyle,
    Card,
    FooterHelp,
    EmptyState,
    Avatar,
    Badge,
    DisplayText,
    Button,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

const getCenters = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/centers`,
            method: 'GET'
        });

        if (data) {
            return setPageState({
                ...pageState,
                centers: data,
                loading: false,
                errors: []
            });
        }
    } catch (error) {
        console.log(error);
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const CentersList = (props) => {

    const [pageState, setPageState] = useState({
        centers: [],
        loading: true,
        errors: []
    });

    const {
        centers,
        loading,
        errors
    } = pageState;

    useEffect(() => {
        if(pageState.loading){
            (getCenters)({ pageState, setPageState });
        }
    }, [pageState]);

    const renderItem = ({ id, center_code, series, center_type }) => (
        <ResourceList.Item
            id={id}
            url={`/app/settings/centers/${id}`}
            media={<Avatar customer name={center_code} source="http://cdn.onlinewebfonts.com/svg/img_370112.png"/>}
        >
            <h3>
                <TextStyle variation="strong">{center_code}</TextStyle>
            </h3>
            {series ? <Badge status="success">{series}</Badge> : null}
            {center_type ? <Badge status="success">{center_type}</Badge> : null}
        </ResourceList.Item>
    );

    return (
        <Page
            title="Centers"
            breadcrumbs={[{
                content: 'Settings',
                url: '/app/settings'
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Centers
                        <Button
                            primary
                            size="slim"
                            url={`/app/settings/centers/new`}
                        >
                            Create
                        </Button>
                    </DisplayText>
                    <ErrorMessages errors={errors}/>
                    <br/>
                    <Card>
                        <ResourceList
                            resourceName={{
                                singular: 'center',
                                plural: 'centers'
                            }}
                            items={centers}
                            renderItem={renderItem}
                            loading={loading}
                            showHeader={true}
                        />
                        {!centers.length && !loading ?
                            <EmptyState
                                heading="Configure Center"
                                action={{
                                    content: 'Create Center',
                                    url: `/app/settings/centers/new`
                                }}
                                image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                            >
                                <p>Set up Centers.</p>
                            </EmptyState>
                        : null}
                    </Card>
                </Layout.Section>
            </Layout>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    )
};

export default CentersList;

