import React, { useState, useEffect } from "react"
import { Redirect } from "@shopify/app-bridge/actions"
import Axios from "axios"
import {
    Layout,
    Banner,
    Heading,
    Stack,
    Select,
    Frame,
    Sheet,
    Scrollable,
    Button,
    Spinner,
    Icon,
    CalloutCard,
} from "@shopify/polaris"
import { MobileCancelMajorMonotone } from "@shopify/polaris-icons"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const SpinnerWrap = styled.div`
    display: ${({ displaySpinner }) => displaySpinner ? `flex` : `none`};
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background: rgba(255, 255, 255, 0.4);
`;

const approveFabricator = async ( {
    id,
    setErrors,
    setLoading,
    setOpenSheet,
    selectedReason,
    declineReasons,
    reLoad,
    app
} ) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}`,
            method: 'PUT',
            data: {
                state: 'approved'
            }
        });
        if(data){
            const selected = declineReasons.find(({ name }) => name === selectedReason);
            await Axios({
                url: `/api/fabricators/${id}/decline-reasons/${selected.id}/send`,
                method: 'POST'
            });
        }
        await setOpenSheet(false);
        await setLoading(false);
        await setErrors([]);

        await reLoad(true);
        // return Redirect.create(app).dispatch(Redirect.Action.APP, `/app/fabricators/${id}`);
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        await setLoading(false);
        return setErrors(errors);
    }
};


const deleteFabricator = async ( {
    id,
    app,
    setErrors,
    setOpenSheet,
    setLoadingDelete,
    selectedReason,
    declineReasons
} ) => {
    try {
        const selected = declineReasons.find(({ name }) => name === selectedReason);
        const { data: emailResponse } = await Axios({
            url: `/api/fabricators/${id}/decline-reasons/${selected.id}/send`,
            method: 'POST'
        });

        if (emailResponse) {
            await Axios({
                url: `/api/fabricators/${id}`,
                method: 'DELETE'
            });
            await setErrors([]);
            await setOpenSheet(false);
            await setLoadingDelete(false);

            return Redirect.create(app).dispatch(Redirect.Action.APP, '/app/fabricators');
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        await setLoadingDelete(false);
        return setErrors(errors);
    }
};

const Moderation = ({ id, state, declineReasons, app, reLoad }) => {

    const [fabricatorStateState] = useState(state);
    const [loading, setLoading] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [openSheet, setOpenSheet] = useState(false);
    const [isApproving, setIsApproving] = useState(false);
    const [errors, setErrors] = useState([]);
    const [selectedReason, setSelectedReason] = useState(declineReasons.length ? declineReasons[0].name : '');

    useEffect(() => {
        if (loading) {
            (approveFabricator)({
                id,
                app,
                reLoad,
                setErrors,
                setLoading,
                setOpenSheet,
                selectedReason,
                declineReasons,
                fabricatorStateState
            })
        }
    }, [
        id,
        app,
        reLoad,
        loading,
        declineReasons,
        selectedReason,
        fabricatorStateState
    ]);

    useEffect(() => {
        if (loadingDelete) {
            (deleteFabricator)({
                id,
                app,
                setErrors,
                setOpenSheet,
                setLoadingDelete,
                selectedReason,
                declineReasons
            })
        }
    }, [
        id,
        app,
        loadingDelete,
        declineReasons,
        selectedReason,
    ]);

    if (fabricatorStateState !== 'pending') { return null; }

    const options = declineReasons
        .map(({ name }) => ({ label: name, value: name }));

    return (
        <Layout.Section>
            <ErrorMessages errors={errors}/>
            <CalloutCard
                title="New Fabricator"
                illustration="/images/welder.jpg"
                primaryAction={{
                    content: 'Approve Fabricator',
                    primary: true,
                    onClick: () => [setIsApproving(true), setOpenSheet(true)]
                }}
                secondaryAction={{
                    content: 'Decline Fabricator',
                    destructive: true,
                    onClick: () => [setIsApproving(false), setOpenSheet(true)]
                }}
            >
                <Banner status="warning">
                    <p>View the submission details below and choose to approve or decline the submission.</p>
                </Banner>
            </CalloutCard>
            <Frame>
                <Sheet
                    open={openSheet}
                    onClose={() => [setOpenSheet(false), setSelectedReason('')]}
                >
                    <SheetWrap>
                        <SpinnerWrap displaySpinner={loading || loadingDelete}>
                            <Spinner size="large" color="teal" />
                        </SpinnerWrap>
                        <SheetHeader>
                            <Stack distribution="equalSpacing">
                                {isApproving ? (
                                    <Icon source="checkmark" color="red" backdrop={true}/>
                                ) : (
                                    <Icon source="alert" color="red" backdrop={true}/>
                                )}
                                {isApproving ? (
                                    <Heading>Approve Fabricator</Heading>
                                ) : (
                                    <Heading>Decline Fabricator</Heading>
                                )}
                                <Button
                                    accessibilityLabel="Cancel"
                                    icon={MobileCancelMajorMonotone}
                                    onClick={() => setOpenSheet(false)}
                                    plain
                                />
                            </Stack>
                        </SheetHeader>
                        <StyledScrollable>
                            <Layout>
                                <Layout.Section>
                                    {isApproving ? (
                                        <Banner status="info">
                                            <p>Are you sure you want to APPROVE this Fabricator submission?</p>
                                        </Banner>
                                    ) : (
                                        <Banner status="warning">
                                            <p>Are you sure you want to DECLINE this Fabricator submission?</p>
                                        </Banner>
                                    )}
                                    <br/>
                                    <Select
                                        // placeholder="Select Email Response"
                                        label="Responses"
                                        options={options}
                                        onChange={value => setSelectedReason(value)}
                                        value={selectedReason}
                                    />
                                </Layout.Section>
                            </Layout>
                        </StyledScrollable>
                        <SheetFooter>
                            <Button onClick={() => [setOpenSheet(false), setSelectedReason('')]}>Cancel</Button>
                            <Button
                                destructive={!isApproving}
                                primary={isApproving}
                                onClick={() => isApproving ?  setLoading(true) : setLoadingDelete(true)}
                            >
                                {isApproving ? `Approve` : `Decline`}
                            </Button>
                        </SheetFooter>
                    </SheetWrap>
                </Sheet>
            </Frame>
        </Layout.Section>
    )
}

export default Moderation;

