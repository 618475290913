import React, { useState, useEffect} from "react"
import Axios from "axios"
import {
    Page,
    ResourceList,
    Layout,
    TextStyle,
    Card,
    FooterHelp,
    EmptyState,
    Badge,
    DisplayText,
    Avatar,
    Pagination,
    Frame,
    Sheet,
    Scrollable,
    FormLayout,
    TextContainer,
    Button,
    Heading,
    Modal,
    DescriptionList,
} from "@shopify/polaris"
import {
    MobileCancelMajorMonotone,
} from "@shopify/polaris-icons"
import styled from "styled-components"


const SheetWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SheetHeader = styled.div`
    align-items: center;
    border-bottom: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const SheetFooter = styled.div`
    border-top: 1px solid #DFE3E8;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem;
    width: 100%;
`;

const StyledScrollable = styled(Scrollable)`
    padding: 1.6rem;
    height: 100%;
`;

const getServiceRequests= async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/service-requests?page=${pageState.page}`,
            method: 'GET'
        });
        if (data) {
            const {
                serviceRequests,
                hasNext,
                hasPrevious
            } = data;
            return setPageState({
                ...pageState,
                serviceRequests,
                hasNext,
                hasPrevious,
                loading: false
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const deleteServiceRequest= async ({ pageState, setPageState, id }) => {
    try {
        const { data } = await Axios({
            url: `/api/fabricators/${id}/service-requests/${pageState.selectedRequest.id}`,
            method: 'DELETE'
        });
        if (data) {
            const { data: reviewData } = await Axios({
                url: `/api/fabricators/${id}/service-requests?page=${pageState.page}`,
                method: 'GET'
            });
            const {
                serviceRequests,
                hasNext,
                hasPrevious
            } = reviewData;
            return setPageState({
                ...pageState,
                serviceRequests: serviceRequests,
                hasPrevious,
                hasNext,
                loading: false,
                displayModal: false,
                loadingDelete: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingDelete: false,
            loading: false,
            displayModal: false,
            errors
        });
    }
};

const ServiceRequestList = (props) => {

    const [pageState, setPageState] = useState({
        serviceRequests: [],
        page: 1,
        hasNext: false,
        hasPrevious: false,
        loading: true,
        loadingDelete: false,
        sheetActive: false,
        selectedRequest: null,
        displayModal: false,
        errors: []
    });

    useEffect(() => {
        const { match: { params } } = props;
        if (pageState.loadingDelete) {
            (deleteServiceRequest)({ pageState, setPageState, id: params.fabricatorId });
        }
        if (pageState.loading) {
            (getServiceRequests)({ pageState, setPageState, id: params.fabricatorId });
        }
    }, [pageState.loading, pageState.loadingDelete, pageState, props]);

    const nextPage = () => setPageState({
        ...pageState,
        loading: true,
        page: pageState.page + 1
    });

    const prevPage = () => setPageState({
        ...pageState,
        loading: true,
        page: pageState.page - 1
    });

    const handleCloseSheet = () => setPageState({
        ...pageState,
        selectedRequest: null,
        sheetActive: false
    });

    const handleOpenModal = (selectedId) => setPageState({
        ...pageState,
        selectedRequest: pageState.serviceRequests.find(({ id }) => selectedId === id),
        displayModal: true
    });

    const handleOpenSheet = (selectedId) => setPageState({
        ...pageState,
        selectedRequest: pageState.serviceRequests.find(({ id }) => selectedId === id),
        sheetActive: true
    });

    const handleDelete = (selectedId) => setPageState({
        ...pageState,
        selectedRequest: pageState.serviceRequests.find(({ id }) => selectedId === id),
        loadingDelete: true
    });

    const handleDeleteCancel = () => setPageState({
        ...pageState,
        displayModal: false
    });

    const renderItem = ({ id, status, name }) => (
        <ResourceList.Item
            id={id}
            media={<Avatar customer name={name}/>}
            shortcutActions={[
                {
                    content: 'view',
                    onClick: () => handleOpenSheet(id)
                },
                {
                    content: 'delete',
                    destructive: true,
                    onClick: () => handleOpenModal(id)
                }
            ]}
        >
            <h3 style={{"display":"inline-block","verticalAlign":"middle"}}>
                <TextStyle variation="strong">{name}</TextStyle>
                &nbsp;<Badge status={"info"}>{status}</Badge>
            </h3>
        </ResourceList.Item>
    );

    const {
        serviceRequests,
        loading,
        hasPrevious,
        hasNext,
        sheetActive,
        displayModal,
        selectedRequest
    } = pageState;

    return (
        <Page
            title="Service Requests"
            breadcrumbs={[{
                content: 'Fabricator',
                url: `/app/fabricators/${props.match.params.fabricatorId}`
            }]}
        >
            <Layout>
                <Layout.Section>
                    <DisplayText
                        size="large"
                        element="h2"
                    >
                        Service Requests
                    </DisplayText>
                    <br/>
                    <Card>
                        <ResourceList
                            resourceName={{
                                singular: 'service request',
                                plural: 'service requests'
                            }}
                            items={serviceRequests}
                            renderItem={renderItem}
                            loading={loading}
                            showHeader={true}
                        />
                    </Card>
                    <div style={{ textAlign: 'center' }}>
                        <br/>
                        <Pagination
                            hasPrevious={hasPrevious}
                            onPrevious={prevPage}
                            hasNext={hasNext}
                            onNext={nextPage}
                        />
                    </div>
                    {!serviceRequests.length && !loading ?
                        <EmptyState
                            heading="No Service Requests"
                            image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
                        >
                            <p>This fabricator does not have any Service Requests</p>
                        </EmptyState>
                    : null}
                </Layout.Section>
            </Layout>
            <Frame>
                <Sheet
                    open={sheetActive}
                    onClose={handleCloseSheet}
                >
                    <SheetWrap>
                        <SheetHeader>
                            <Heading>Service Request</Heading>
                            <Button
                                accessibilityLabel="Cancel"
                                icon={MobileCancelMajorMonotone}
                                onClick={handleCloseSheet}
                                plain
                            />
                        </SheetHeader>
                        <StyledScrollable>
                            <FormLayout>
                                {selectedRequest ?
                                    <>
                                        <TextContainer>
                                            <div style={{ display: 'inline-block', verticalAlign: 'middle'}}>
                                                <Avatar customer name={selectedRequest.name} />
                                            </div>
                                            <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '20px' }}>
                                                <Heading>{selectedRequest.name}</Heading>
                                                <TextStyle variation="subdued">{selectedRequest.email}</TextStyle>
                                                <br/>
                                                <TextStyle variation="subdued">{new Date(selectedRequest.createdAt).toDateString()}</TextStyle>
                                                <br/>
                                            </div>
                                            <div>
                                                <Badge status={`info`}>{selectedRequest.status}</Badge>
                                            </div>
                                        </TextContainer>
                                        <br/>
                                        <TextContainer>
                                            <Heading>Comment</Heading>
                                            <p>{selectedRequest.comment || 'No comment'}</p>
                                        </TextContainer>
                                        <br/>
                                        <TextContainer>
                                            <Heading>Reply</Heading>
                                            <p>{selectedRequest.fabricator_reply || 'No reply'}</p>
                                        </TextContainer>
                                        <br/>
                                        <TextContainer>
                                            <Heading>How did you hear about us?</Heading>
                                            <p>{selectedRequest.hear_about || 'No content'}</p>
                                        </TextContainer>
                                        <br/>
                                        <Heading>Details</Heading>
                                        <DescriptionList
                                            items={[{
                                                term: 'Service Date',
                                                description: new Date(selectedRequest.service_date).toDateString()
                                            },{
                                                term: 'Make',
                                                description: selectedRequest.make || 'No Make'
                                            },{
                                                term: 'Model',
                                                description: selectedRequest.model || 'No Model'
                                            },{
                                                term: 'Year',
                                                description: selectedRequest.year || 'No Year'
                                            }]}
                                        />
                                    </>
                                : null}
                            </FormLayout>
                        </StyledScrollable>
                        <SheetFooter>
                            <Button onClick={handleCloseSheet}>Cancel</Button>
                        </SheetFooter>
                    </SheetWrap>
                </Sheet>
                {!loading && selectedRequest ? (
                    <Modal
                        open={displayModal}
                        onClose={() => {}}
                        title={`Delete Service Request`}
                        message={`Are you sure you want to DELETE this Service Request? This action cannot be undone.`}
                        primaryAction={{
                            content: 'Delete',
                            onAction: () => handleDelete(selectedRequest.id),
                            destructive: true
                        }}
                        secondaryActions={[{
                            content: 'Cancel',
                            onAction: handleDeleteCancel,
                        }]}
                    >
                    </Modal>
                ): null }
            </Frame>
            <FooterHelp>
                Move Bumpers &copy;
            </FooterHelp>
        </Page>
    )
};

export default ServiceRequestList;

