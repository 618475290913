import React, { useState, useEffect} from "react"
import Axios from "axios"
import {
    Card,
    Tag,
    FormLayout,
    Select,
    Button,
    Toast,
    Banner,
    Frame,
} from "@shopify/polaris"

import styled from "styled-components"

import ErrorMessages from "../ErrorMessages"

const TagWrap = styled.span`
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
`;

const getMetafields = async ({ selectedProductId, pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${selectedProductId}/metafields`,
            method: 'GET'
        });
        if (data) {
            await setPageState({
                ...pageState,
                exclusionField: data.find(({ key }) => key === 'exclusions'),
                loading: false,
                loadingMakes: true,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loading: false,
            errors
        });
    }
};

const updateExclusionMetafield = async ({ selectedProductId, pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/products/${selectedProductId}/metafields/${pageState.exclusionField.id}`,
            method: 'PUT',
            data: {
                value: pageState.exclusionField.value
            }
        });
        if (data) {
            return setPageState({
                ...pageState,
                loadingUpdateExclusions: false,
                showToast: true,
                exclusionFieldChange: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingUpdateExclusions: false,
            errors
        });
    }
};

const getMakes = async ({ pageState, setPageState }) => {
    try {
        const { data } = await Axios({
            url: `/api/makes`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                makes: data.makes,
                loadingMakes: false,
                loading: false,
                selectedModel: '',
                selectedYear: '',
                models: [],
                years: [],
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingMakes: false,
            errors
        });
    }
};

const getMakeModels = async ({ pageState, setPageState }) => {
    try {
        const makeId = pageState.makes
            .find(({ name }) => name === pageState.selectedMake).id;
        const { data } = await Axios({
            url: `/api/makes/${makeId}/models`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                models: data,
                loadingModels: false,
                selectedYear: '',
                years: [],
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingModels: false,
            errors
        });
    }
};

const getModelYears = async ({ pageState, setPageState }) => {
    try {
        const modelId = pageState.models
            .find(({ name }) => name === pageState.selectedModel).id;
        const { data } = await Axios({
            url: `/api/models/${modelId}/years`,
            method: 'GET'
        });
        if (data) {
            return setPageState({
                ...pageState,
                years: data,
                loadingYears: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            loadingYears: false,
            errors
        });
    }
};

const fieldBuilder = ({ exclusionField, selectedMake, selectedModel, selectedYear }) => {
    return `${exclusionField.value},${selectedMake}--${selectedModel}--${selectedYear}`
};

const warningMessage = message => (<Banner status="warning">{message}</Banner>);

const getProductId = ({ selectedProduct }) => {
    try {
        const productSplit = selectedProduct.id.split('/');
        return productSplit[productSplit.length -1];
    } catch (error) {
        //TODO: do something with errors
        console.log(error);
        return null;
    }
};

const ProtoAccessoryFields = (props) => {

    const [pageState, setPageState] = useState({
        loading: true,
        exclusionField: null,
        exclusionFieldChange: false,
        loadingMakes: false,
        loadingModels: false,
        loadingYears: false,
        loadingUpdateExclusions: false,
        showToast: false,
        selectedMake: '',
        selectedModel: '',
        selectedYear: '',
        makes: [],
        models: [],
        years: [],
        open: false
    });

    const { selectedProduct } = props;

    useEffect(() => {
        const selectedProductId = getProductId({ selectedProduct });
        if (pageState.loading) {
            (getMetafields)({ selectedProductId, pageState, setPageState });
        }
        if (selectedProduct && pageState.loadingMakes) {
            (getMakes)({ pageState, setPageState });
        }
        if (pageState.selectedMake && pageState.loadingModels) {
            (getMakeModels)({ pageState, setPageState });
        }
        if (pageState.selectedMake && pageState.selectedModel && pageState.loadingYears) {
            (getModelYears)({ pageState, setPageState });
        }
        if (pageState.loadingUpdateExclusions) {
            (updateExclusionMetafield)({ selectedProductId, pageState, setPageState });
        }
    }, [selectedProduct, pageState])

    const removeItem = item => setPageState({
        ...pageState,
        exclusionField: {
            ...pageState.exclusionField,
            value: pageState.exclusionField.value.replace(item, '')
        },
        exclusionFieldChange: true
    });

    const addItem = () => setPageState({
        ...pageState,
        exclusionField: {
            ...pageState.exclusionField,
            value: fieldBuilder(pageState)
        },
        exclusionFieldChange: true,
        selectedMake: '',
        selectedModel: '',
        selectedYear: '',
        models: [],
        years: []
    });

    const handleMakeChange = (selectedMake) => setPageState({
        ...pageState,
        loadingModels: true,
        selectedMake
    });

    const handleModelChange = (selectedModel) => setPageState({
        ...pageState,
        loadingYears: true,
        selectedModel
    });

    const handleYearChange = (selectedYear) => setPageState({
        ...pageState,
        selectedYear
    });

    const saveExclusions = () => setPageState({
        ...pageState,
        loadingUpdateExclusions: true
    });

    const configureExclusionTags = ({ value }) => {
        const values = value.split(',').filter(item => item !== '' && item !== 'empty');
        if (!values.length) {
            return warningMessage(`There are no exclusions associated with this product.`)
        }
        return values.map((item, i) => (
            <TagWrap key={`tag-${i+1}`}>
                <Tag onRemove={() => removeItem(item)}>
                    {item.replace(/--/g, ' / ')}
                </Tag>
            </TagWrap>
        ))
    };

    const {
        makes,
        years,
        errors,
        models,
        showToast,
        selectedYear,
        selectedMake,
        selectedModel,
        exclusionField,
        exclusionFieldChange,
        loadingUpdateExclusions,
    } = pageState;

    return (
        <>
            <ErrorMessages errors={errors}/>
            {exclusionField ? (
                <Card
                    title="Make/Model/Year Exclusions"
                    primaryFooterAction={{
                        content: 'Save',
                        disabled: !exclusionFieldChange,
                        onClick: saveExclusions
                    }}
                >
                    <Card.Section>
                        {configureExclusionTags(exclusionField)}
                    </Card.Section>
                    <Card.Section>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Select
                                    placeholder="Make"
                                    options={makes.map(({ id, name }) => ({ label: name, value: name }))}
                                    onChange={handleMakeChange}
                                    disabled={!makes.length}
                                    value={selectedMake}
                                />
                                <Select
                                    placeholder="Model"
                                    options={models.map(({ id, name }) => ({ label: name, value: name }))}
                                    onChange={handleModelChange}
                                    disabled={!models.length}
                                    value={selectedModel}
                                />
                                <Select
                                    placeholder="Year"
                                    options={years.map(({ id, year }) => ({ label: year, value: year }))}
                                    onChange={handleYearChange}
                                    disabled={!years.length}
                                    value={selectedYear}
                                />
                                <Button
                                    fullWidth
                                    disabled={!selectedMake || !selectedModel || !selectedYear || loadingUpdateExclusions}
                                    onClick={addItem}
                                >
                                    Exclude
                                </Button>
                            </FormLayout.Group>
                        </FormLayout>
                    </Card.Section>
                </Card>
            ) : null}
            <Frame>
                {showToast ?
                    <Toast
                        content={`Saved`}
                        onDismiss={() => setPageState({ ...pageState, showToast: false })}
                    />
                : null}
            </Frame>
        </>
    )
};

export default ProtoAccessoryFields;

