import React, { useState, useEffect } from "react"
import Axios from "axios";
import {
    Page,
    Layout,
    Card,
    FormLayout,
    Avatar,
    TextStyle,
    Toast,
    Heading,
    DisplayText,
    Select,
    Frame,
    TextField,
    Button,
} from "@shopify/polaris"

import ErrorMessages from "../../components/ErrorMessages"

import Moderation from "../../components/fabricators/Moderation"
import Status from "../../components/fabricators/Status"
import ImageUpdates from "../../components/fabricators/ImageUpdates"
import Updates from "../../components/fabricators/Updates"
import Services from "../../components/fabricators/Services"
import Equipment from "../../components/fabricators/Equipment"
import Addresses from "../../components/fabricators/Addresses"
import Gallery from "../../components/fabricators/Gallery"
import ImageUpload from "../../components/fabricators/ImageUpload"

//  Get number of months from createdAt
const howManyMonths = (createdAt) => {
    const date = new Date(createdAt);
    let numberOfMonths;

    numberOfMonths = (new Date().getFullYear() - date.getFullYear()) * 12;
    numberOfMonths -= date.getMonth() + 1;
    numberOfMonths += date.getMonth();

    return numberOfMonths <= 0 ? 0 : numberOfMonths;
};

const getDetails = async ({ pageState, setPageState, setLoading, id }) => {
    try {
        const { data: fabricator } = await Axios({
            url: `/api/fabricators/${id}`,
            method: 'GET'
        });
        const { data: declineReasons } = await Axios({
            url: `/api/decline-reasons`,
            method: 'GET'
        });
        if (fabricator && declineReasons) {
            setLoading(false);
            return setPageState({
                ...pageState,
                declineReasons,
                fabricatorDisplay: fabricator,
                fabricator,
                errors: []
            });
        }
        setLoading(false);
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];

        setLoading(false);
        return setPageState({
            ...pageState,
            errors
        });
    }
};

const updateDetails = async ({ pageState, setPageState, id }) => {
    try {
        const { fabricator } = pageState;
        const { data } = await Axios({
            url: `/api/fabricators/${id}`,
            method: 'PUT',
            data: {
                first_name: fabricator.first_name || null,
                last_name: fabricator.last_name || null,
                company: fabricator.company || null,
                status: fabricator.status || null,
                type: fabricator.type || null,
                about: fabricator.about || null,
                price: fabricator.price || null,
                state: fabricator.state || null,
                note: fabricator.Note ? fabricator.Note.text : null,
            }
        });
        if (data) {
            return setPageState({
                ...pageState,
                fabricator: data,
                fabricatorDisplay: data,
                updateLoading: false,
                showToast: true,
                formChange: false,
                errors: []
            });
        }
    } catch (error) {
        const errors = error.response && error.response.data
            ?  error.response.data
            : [];
        return setPageState({
            ...pageState,
            updateLoading: false,
            errors
        });
    }
};

const isUpdating = ({ loading, fabricator }) => {
    return !loading
        && fabricator
        && fabricator.state === "updating"
        && fabricator.FabricatorUpdate;
};

const isUpdatingImages = ({ loading, fabricator }) => {
    return !loading
        && fabricator
        && fabricator.state === "updating"
        && fabricator.Images
        && fabricator.Images.length
};

const isNewSubmission = ({ loading, fabricator }) => {
    return !loading
        && fabricator
        && fabricator.state === 'pending'
}

const FabricatorDetail = (props) => {

    const [pageState, setPageState] = useState({
        fabricator: null,
        fabricatorDisplay: null,
        declineReasons: [],
        // loading: true,
        showToast: false,
        updateLoading: false,
        resourceTitle: "Fabricator",
        resourceSlug: "fabricators",
        formChange: false,
        errors: []
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const { match: { params } } = props;
        if (params && params.fabricatorId && !pageState.updateLoading && loading) {
            (getDetails)({ pageState, setPageState, setLoading, id: params.fabricatorId });
        }
        if (pageState.updateLoading && params && params.fabricatorId) {
            (updateDetails)({ pageState, setPageState, setLoading, id: params.fabricatorId });
        }
    }, [
        pageState,
        pageState.updateLoading,
        loading,
        props
    ]);

    const handleTypeChange = (type) => setPageState({
        ...pageState,
        fabricator: {
            ...pageState.fabricator,
            type
        },
        formChange: true
    });

    const handleCompanyChange = (company) => setPageState({
        ...pageState,
        fabricator: {
            ...pageState.fabricator,
            company
        },
        formChange: true
    });

    const handleFirstNameChange = (first_name) => setPageState({
        ...pageState,
        fabricator: {
            ...pageState.fabricator,
            first_name
        },
        formChange: true
    });

    const handleLastNameChange = (last_name) => setPageState({
        ...pageState,
        fabricator: {
            ...pageState.fabricator,
            last_name
        },
        formChange: true
    });

    const handleAboutChange = (about) => setPageState({
        ...pageState,
        fabricator: {
            ...pageState.fabricator,
            about
        },
        formChange: true
    });

    const handlePriceChange = (price) => setPageState({
        ...pageState,
        fabricator: {
            ...pageState.fabricator,
            price
        },
        formChange: true
    });

    const handleNoteChange = (note) => setPageState({
        ...pageState,
        fabricator: {
            ...pageState.fabricator,
            Note: { text: note || null }
        },
        formChange: true
    });

    const handleUpdate = (price) => setPageState({
        ...pageState,
        updateLoading: true
    });

    const dismissToast = () => setPageState({
        ...pageState,
        showToast: false
    });

    const componentError = (errors) => setPageState({
        ...pageState,
        errors,
    });

    const updateComplete = () => setLoading(true);

    const typeOptions = [
        { label: 'Company', value: 'company' },
        { label: 'Individual', value: 'individual' }
    ];

    const {
        fabricator,
        fabricatorDisplay,
        showToast,
        // loading,
        resourceTitle,
        formChange,
        declineReasons,
        errors
    } = pageState;

    const { app } = props;

    const hasUpdate = isUpdating({
        ...pageState,
        loading,
    });
    const hasImageUpdates = isUpdatingImages({
        ...pageState,
        loading,
    });
    const newSubmission = isNewSubmission({
        ...pageState,
        loading,
    });

    return (

        <Frame>
            <Page
                title={`Edit`}
                breadcrumbs={[{
                    content: 'Fabricators',
                    url: `/app/fabricators`,
                    target: 'APP'
                }]}
            >
                <Layout>
                    <Layout.Section>
                        <DisplayText
                            size="large"
                            element="h2"
                        >
                            {!loading && fabricator ? fabricatorDisplay.company : null}
                            <Button
                                primary
                                size="slim"
                                onClick={handleUpdate}
                                disabled={!formChange}
                            >
                                Save
                            </Button>
                        </DisplayText>
                        <ErrorMessages errors={errors}/>
                        <br/>
                    </Layout.Section>
                    {newSubmission ? (
                        <Moderation
                            app={app}
                            id={fabricator.id}
                            state={fabricator.state}
                            declineReasons={declineReasons}
                            reLoad={setLoading}
                        />
                    ) : null}
                    {hasUpdate ? (
                        <Updates
                            id={fabricator.id}
                            update={fabricator.FabricatorUpdate}
                            completeUpdate={updateComplete}
                            declineReasons={declineReasons}
                        />
                    ) : null}
                    {hasImageUpdates ? (
                        <ImageUpdates
                            id={fabricator.id}
                            images={fabricator.Images}
                            completeUpdate={updateComplete}
                            declineReasons={declineReasons}
                        />
                    ) : null}
                    <Layout.Section>
                        <Card sectioned>
                            {!loading && fabricator ?
                                <>
                                    <Card.Section>
                                        <div style={{ display: 'inline-block', verticalAlign: 'middle'}}>
                                            <Avatar customer name={fabricator.company} />
                                        </div>
                                        <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '20px' }}>
                                            <Heading>{`${fabricatorDisplay.first_name} ${fabricatorDisplay.last_name}`}</Heading>
                                            <TextStyle variation="subdued">{fabricator.email}</TextStyle>
                                            <br/>
                                            <TextStyle variation="subdued">{`Fabricator for ${howManyMonths(fabricator.createdAt)} months.`}</TextStyle>
                                        </div>
                                    </Card.Section>
                                    <Card.Section>
                                        <FormLayout>
                                            <TextField
                                                label="Company Name"
                                                value={fabricator.company}
                                                onChange={handleCompanyChange}
                                            />
                                            <FormLayout.Group>
                                                <TextField
                                                    label="First Name"
                                                    value={fabricator.first_name}
                                                    onChange={handleFirstNameChange}
                                                />
                                                <TextField
                                                    label="Last Name"
                                                    value={fabricator.last_name}
                                                    onChange={handleLastNameChange}
                                                />
                                            </FormLayout.Group>
                                            <Select
                                                label="I'm a"
                                                options={typeOptions}
                                                onChange={handleTypeChange}
                                                value={fabricator.type}
                                            />
                                            <TextField
                                                label="About"
                                                value={fabricator.about}
                                                onChange={handleAboutChange}
                                            />
                                            <TextField
                                                label="Price"
                                                value={fabricator.price}
                                                onChange={handlePriceChange}
                                            />
                                            <TextField
                                              label="Notes"
                                              value={fabricator.Note ? fabricator.Note.text : null}
                                              onChange={handleNoteChange}
                                              multiline={2}
                                            />
                                        </FormLayout>
                                    </Card.Section>
                                </>
                            : null}
                        </Card>
                        <Frame>
                            <br/>
                            {!loading && fabricator ? (<Gallery id={fabricator.id} />) : null}
                            <br/>
                            {!loading && fabricator ? (<Services id={fabricator.id} />) : null}
                            <br/>
                            {!loading && fabricator ? (<Equipment id={fabricator.id} />) : null}
                        </Frame>
                    </Layout.Section>
                    <Layout.Section secondary>
                        <Card
                            title="Status"
                        >
                            <Card.Section>
                                {!loading && fabricator ?
                                    <Status
                                        id={fabricator.id}
                                        status={fabricator.status}
                                        declineReasons={declineReasons}
                                        componentError={componentError}
                                    ></Status>
                                : null}
                            </Card.Section>
                        </Card>
                        <Card>
                            <Card.Section>
                                {!loading && fabricator ?
                                    <ImageUpload
                                        id={fabricator.id}
                                        reset={setLoading}
                                    />
                                : null}
                            </Card.Section>
                        </Card>
                        <br/>
                        <Frame>
                            {!loading && fabricator ?
                                <>
                                    <Addresses id={fabricator.id} />
                                    <Card
                                        title="Reviews"
                                        secondaryFooterAction={{
                                            url: `/app/fabricators/${fabricator.id}/reviews`,
                                            content: 'Manage Reviews'
                                        }}
                                    >
                                        <Card.Section>
                                            <p>This fabricator has {fabricator.reviewCount} reviews</p>
                                        </Card.Section>
                                    </Card>
                                    <Card
                                        title="Service Requests"
                                        secondaryFooterAction={{
                                            url: `/app/fabricators/${fabricator.id}/service-requests`,
                                            content: 'Manage Service Requests'
                                        }}
                                    >
                                        <Card.Section>
                                            <p>This fabricator has {fabricator.requestCount} service requests</p>
                                        </Card.Section>
                                    </Card>
                                </>
                            : null}
                        </Frame>
                    </Layout.Section>
                </Layout>
                <br/>
                {showToast ?
                        <Toast
                            content={`${resourceTitle} Updated`}
                            onDismiss={dismissToast}
                        />
                : null}
            </Page>
        </Frame>
    )
};

export default FabricatorDetail;

